import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { OutputRow, TableIcon, BoldLabel } from "../../../../utils/budget-tool-specific";
import SubtotalCols from "./subtotal-cols";
import { displayValue } from "../../helper";
import CostRow from "./exp-cost-row";
import { exchangeRate, calculateCostActivity } from "./cost-helper";

const ActivityRow = ({guideData, expData}) => {

    return (
        <React.Fragment>
            <CostRow title="Activities" 
                icon='sticky-note'
                single
                currency={guideData.currency.value.slice(3)} 
                costPer={exchangeRate(expData, expData.costPerActivity.value)}
                height
                perLabel="per activity"
                num={expData.numActivities.value}
                type="activities"
                subtotal={calculateCostActivity(guideData, expData)}
                attendees={guideData.attendees.value}/>
           
        </React.Fragment>
    )
}

export default ActivityRow;