import React from 'react';
import PageTemplate from '../page-template';
import { HeaderTitle, Spacer } from '../../../utils/budget-tool-specific';
import BudgetForm from './budget-form';


const BudgetToolComponent = (props) => {

    return (
        <React.Fragment>
            <Spacer height="60px" id="height-spacer"/>
            <HeaderTitle>
                Budget Demo
            </HeaderTitle>
            <BudgetForm buttonAction="calculateRetreatCost" buttonLabel="Calculate Retreat Cost" id="budget-form"/>
        </React.Fragment>
    )
}
const BudgetTool = (props) => {

    if (props.collapsed) {
        return <BudgetToolComponent/>
    } else {
        return <PageTemplate component={<BudgetToolComponent/>} client={props.client}/>
    }
}

export default BudgetTool;