import React from "react";
import styled from "styled-components";
import { Container, Row, NavLink, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavItem = (props) => {

    return ( <React.Fragment>
        {!props.collapsed && <StyledContainer color={props.color}>
            <NavRow id="navrow-1" style={{width: "100%"}}>
                <HighlightCol style={{whiteSpace: "nowrap"}}>
                    <Row style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", whiteSpace: "nowrap"}}>
                        <ItemHeightDiv/>
                        <FitCol lg="3" md="3" sm="3" style={{display: "flex", alignItems: "center"}}>
                            <FAIconButton fontColor={props.fontColor} icon={['fas', props.item.icon]}/>
                        </FitCol>
                        <FitCol lg="9" md="9" sm="9" >
                            <StyledNavLink href={("/budget-app/" + props.item.urlify)} fontColor={props.fontColor}>{props.item.title}</StyledNavLink>
                        </FitCol>
                    </Row>
                </HighlightCol>
            </NavRow>
        </StyledContainer>}

        {props.collapsed && 
            <StyledNavLink href={("/budget-app/" + props.item.urlify)} style={{padding: "0px 10px"}}fontColor={props.fontColor}>{props.item.title}</StyledNavLink>
        }
        </React.Fragment>
    );
}

export default NavItem;

const FitCol = styled(Col)`
    padding: 5px;
`

const HighlightCol = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color: #1a1a1a;
    }
    margin: 0px;
    border-radius: 10px;
    white-space: nowrap;
`

const StyledContainer = styled(Container)`
    border-radius: 10px;
    background-color: ${props => props.color};
    margin: 0px 0px;
    padding: 0px;
    
    z-index: 1009;
`

const NavRow = styled(Row)`
    padding: 8px;
    margin: 0px;
    white-space: nowrap;
`
const StyledNavLink = styled(NavLink)`
    color: ${props => props.fontColor ? props.fontColor : "white"};
    font-family: Roboto;
    text-decoration: none;
    margin: 0px;
    white-space: nowrap;
    padding: 0px 0px;
    &:hover { 
        color: ${props => props.fontColor};
    }
    z-index: 1009;
`

const ItemHeightDiv = styled.div`;
    height: 40px;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 0px;
    margin-left: 0px;
    width: 25px !important;
    height: auto;
    color: ${props => props.fontColor ? props.fontColor : "black"};
`;