import React from 'react';
import { FormGroup } from 'reactstrap';
import { GreyLabel, transportationOptions, Colors } from '../../../../utils/budget-tool-specific';
import Select from "react-select";

const FormInputExperienceMulti = (props) => {

    const handleMultiChange = (selected) => {

        console.log(selected);

        let newData = {...props.data};
        let array =[];
        Object.entries(selected).map((element) => {
            array.push(element[1].label);
            return 1;
        });
        newData[props.id].value = array;
        props.setData(newData);
    };

    const customStyles = {
        option: (provided) => ({
          ...provided
        }),
        control: (provided) => ({
          // none of react-select's styles are passed to <Control />

          ...provided
        }),
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';
      
          return { ...provided, opacity, transition };
        }
    }

    const defaultTransportationOptions = Object.entries(props.transportation.value).map((element, i) => {
        return ({value: i, label: element[1]});
    })

    return ( 
        <React.Fragment>
            <FormGroup>
                <GreyLabel for={props.id}>{props.title}</GreyLabel>
                <Select
                    placeholder="Select..."
                    isMulti
                    name="colors"
                    options={props.options}
                    defaultValue={defaultTransportationOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={e => handleMultiChange(e)}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: Colors.primaryAccent,
                            danger: Colors.primaryDark,
                            dangerLight: Colors.primaryDark,
                            primary: Colors.primaryAccent,
                            neutral10: Colors.primaryAccent,
                            neutral0: Colors.primaryLight,
                            neutral5: Colors.primaryDark,
                            neutral20: Colors.primaryAccent,
                            neutral30: Colors.primaryDark,
                            neutral40: Colors.primaryDark,
                            neutral50: Colors.primaryDark,
                            neutral60: Colors.primaryDark,
                            neutral70: Colors.primaryDark,
                            neutral80: Colors.darkGrey,
                            neutral90: Colors.primaryAccent,
                        },
                        
                    })}
                    styles={customStyles}
                />
            </FormGroup>

        </React.Fragment>
    )

}

export default FormInputExperienceMulti;