import React, { useState } from "react";
import styled from "styled-components";
import { Col, Collapse, Card, CardBody } from "reactstrap";
import { Colors, CollapseRow } from "../../../../utils/budget-tool-specific";
import CostRow from "./cost-row";
import BreakdownRow from "./breakdown-row";
import { calculateNumNights, calculatePerAttendee } from "../../helper";

const MiscellaneousRow = ({misc, contingency, onsite, subtotals, internal, currency, data, total, costStructure, attendees}) => {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <React.Fragment>
            <CostRow title="Miscellaneous" 
                    icon='plus' 
                    height 
                    setIsOpen={(x) => setIsOpen(x)} 
                    isOpen={isOpen} 
                    currency={currency} 
                    percent={Math.round(((parseFloat(misc.subtotal) + parseFloat(contingency) + parseFloat(onsite.subtotal) + parseFloat(internal)) / total) * 100)}
                    subtotal={parseFloat(misc.subtotal) + parseFloat(contingency) + parseFloat(onsite.subtotal) + parseFloat(internal)}/>
            <Collapse isOpen={isOpen}>
                <CollapseRow>
                    <Col>
                        <PinkCard>
                        <PaddedCardBody>
                            {misc !== undefined && Object.entries(misc.misc).map((element, i) => {
                                
                                let perday = false;
                                if (element[1].title === "AV" || element[1].title === "Enhanced Decor & Entertainment" || element[1].title === "Videographer/Photographer") {
                                    perday = true;

                                    if (element[1].title === "Enhanced Decor & Entertainment" || element[1].title === "AV") {
                                        return ( 
                                            <BreakdownRow perday={perday}
                                                key={i}
                                                currency={currency}
                                                title={element[1].title}
                                                costPer={element[1].costPer / (calculateNumNights(data) +1)}/>
                                        )
                                    } else {
                                        return ( 
                                            <BreakdownRow perday={perday}
                                                
                                                key={i}
                                                currency={currency}
                                                title={element[1].title}
                                                fixedCost={element[1].costPer * data.attendees.value /(calculateNumNights(data) +1) }
                                                costPer={element[1].costPer / (calculateNumNights(data) +1)}/>
                                        )
                                    }

                                } else {
                                    if (element[1].title === "Microsite") {
                                        return (
                                        <BreakdownRow perday={perday}
                                                        key={i}
                                                        fixedCost={element[1].costPer * data.attendees.value}
                                                        currency={currency}
                                                        title={element[1].title}
                                                        costPer={element[1].costPer}/>
                                    )} else {
                                        return (
                                            <BreakdownRow perday={perday}
                                                            key={i}
                                                            currency={currency}
                                                            title={element[1].title}
                                                            costPer={element[1].costPer}/>)
                                    }
                                }
                                
                            })}
                            <BreakdownRow currency={currency}
                                            perday={true}
                                            title="Onsite Coordinator"
                                            coordinators
                                            days={(calculateNumNights(data) +1)}
                                            num={onsite.numCoordinators}
                                            subtotal={onsite.costPer} 
                                            costPer={onsite.subtotal / (calculateNumNights(data) +1)}/> 
                            <BreakdownRow currency={currency}
                                            title="Contingency"
                                            percent
                                            num={10}
                                            costPer={contingency}/> 
                            <BreakdownRow currency={currency}
                                            title="Moniker Management Fee"
                                            percent
                                            noflights
                                            total
                                            notperperson
                                            num={costStructure.miscRow.costPer["Management fee"]}
                                            costPer={internal * attendees}/> 
                        </PaddedCardBody>
                        </PinkCard>
                    </Col>
                </CollapseRow>
            </Collapse>
        </React.Fragment>
    )
}

export default MiscellaneousRow;

const PinkCard = styled(Card)`
    background-color: ${Colors.primaryAccent};
    border: none;
`

const PaddedCardBody = styled(CardBody)`
    padding: 15px 0px;
`