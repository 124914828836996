import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import BudgetForm from '../budget-tool/budget-form';
import Header from '../header';
import PageTemplate from '../page-template';
import { lengthOptions, experienceOptions, goalOptions, alcoholOptions, transportOptions, addsOptions } from '../helper';
import { HeaderTitle, Spacer } from '../../../utils/budget-tool-specific';

const BudgetViewer = (props) => {
    
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [collapsed, setCollapsed] = useState(false);

    let client = false;
    if (window.location.href.indexOf("/budget-app/experiences/") > -1) {
        client = true;
        if (window.location.href.indexOf("edit") > -1) {
            client = false;
        }
    }
    if (window.location.href.indexOf("/budget-app/flights/") > -1) {
        client = true;
        if (window.location.href.indexOf("edit") > -1) {
            client = false;
        }
    }
  
    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
  
    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }

    let { attendees } = useParams();
    let { length } = useParams();
    let { experience } = useParams();
    let { flight } = useParams();
    let { goal } = useParams();
    let { alcohol } = useParams();
    let { ground } = useParams();
    let { adds } = useParams();
    let { occupancy } = useParams();
    let { inclusive } = useParams();

    const [data, setData] = useState(
        {   attendees: {value: 0, invalid: false, required: true},
            tripLength: {value: "", invalid: false, required: true}, 
            experienceType: {value: "", invalid: false, required: true},
            flightCost: {value: 0, invalid: false, required: true},
            workBalance: {value: "", invalid: false, required: true},
            alcohol: {value: "", invalid: false, required: true},
            transportation: {value: [], invalid: false, required: false},
            services: {value: [], invalid: false, required: false},
            occupancy: {value: "Single Occupancy", invalid: false, required: false},
            inclusive: {value: "Not Inclusive", invalid: false, required: false}
        }
    );

    let newData = { 
        attendees: {value: 0, invalid: false, required: true},
        tripLength: {value: "", invalid: false, required: true}, 
        experienceType: {value: "", invalid: false, required: true},
        flightCost: {value: 0, invalid: false, required: true},
        workBalance: {value: "", invalid: false, required: true},
        alcohol: {value: "", invalid: false, required: true},
        transportation: {value: [], invalid: false, required: false},
        services: {value: [], invalid: false, required: false},
        occupancy: {value: "Single Occupancy", invalid: false, required: false},
        inclusive: {value: "Not Inclusive", invalid: false, required: false}
    };

    useEffect(() => { 
        if (attendees !== undefined){
            newData.attendees.value = attendees;
        }
        if (length !== undefined){
            newData.tripLength.value = lengthOptions[length];
        }
        if (experience !== undefined){
            newData.experienceType.value = experienceOptions[experience];
        }
        if (flight !== undefined){
            newData.flightCost.value = flight;
        }
        if (goal !== undefined){
            newData.workBalance.value = goalOptions[goal];
        }
        if (alcohol !== undefined){
            newData.alcohol.value = alcoholOptions[alcohol];
        }
        if (ground !== undefined){
            let transChars = ground.split('');
            let opts = [];
            for (let i = 0; i < transChars.length; i++){
                if (transChars[i] === '1'){
                    opts.push(transportOptions[i])
                }
            }
            newData.transportation.value = opts;
        }
        if (adds !== undefined){
            let addsChars = adds.split('');
            let opts = [];
            for (let i = 0; i < addsChars.length; i++){
                if (addsChars[i] === '1'){
                    opts.push(addsOptions[i])
                }
            }
            newData.services.value = opts;
        }
        if (occupancy !== undefined){
            if (occupancy === "0"){
                newData.occupancy.value = "Single Occupancy";
            }
            else{
                newData.occupancy.value = "Double Occupancy";
            }
        }
        if (inclusive !== undefined){
            if (inclusive === "0"){
                newData.inclusive.value = "Not Inclusive";
            }
            else{
                newData.inclusive.value = "All-Inclusive";
            }
        }
    }, []);

    return ( 
        <React.Fragment>
            <Header windowSize={windowSize} collapsed={collapsed} setCollapsed={(x) => setCollapsed(x)} client={true}/>
            {newData !== undefined && <PageTemplate 
                client={true}
                component={<React.Fragment>
                    <Spacer height="60px" id="height-spacer"/>
                    <HeaderTitle>
                        Budget Demo
                    </HeaderTitle>
                    <BudgetForm disabled={true} newData={newData}>
                    </BudgetForm>
                    </React.Fragment>}>
            </PageTemplate>}
        </React.Fragment>
    )

}
 
export default BudgetViewer;