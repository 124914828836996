import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import { v4 as uuidv4 } from 'uuid';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyB4pR2Mb8vGecBQaBAos60RFy2sdv4WKAM",
    authDomain: "moniker-budget-tool.firebaseapp.com",
    projectId: "moniker-budget-tool",
    storageBucket: "moniker-budget-tool.appspot.com",
    messagingSenderId: "791327356376",
    appId: "1:791327356376:web:3832bb3fae9cc9fc1beead",
    measurementId: "G-D35FX2QFM9"
}, "budget");

const budgetDB = firebaseApp.firestore();

export const generateGUID = uuidv4;
export const analytics = firebase.analytics();
export const auth = firebase.auth();

const devFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCdTpQ4jN7Rm123L7dIhE9gEkwPwCMnt18",
    authDomain: "moniker-budget-tool-dev.firebaseapp.com",
    projectId: "moniker-budget-tool-dev",
    storageBucket: "moniker-budget-tool-dev.appspot.com",
    messagingSenderId: "984309317273",
    appId: "1:984309317273:web:82f8425bfea656ea73c208",
    measurementId: "G-FVCNGD2CHG"
}, "dev");

const devBudgetDB = devFirebaseApp.firestore();

export const GetDB = () => {
    if (getEnvironment() === "dev") {
        return devBudgetDB;
    } else {
        return budgetDB;
    }
};

const getEnvironment = () => {
    var loc = window.location.href;
    if (loc.includes("localhost")){
      return "dev";
    }
    else if (loc.includes("test.deleptual")){
      return "dev";
    }
    else if (loc.includes("deleptual")){
      return "dev";
    }
    else if (loc.includes("moniker-virtual")){
      return "production";
    }
  };