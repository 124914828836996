import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Collapse } from 'reactstrap';
import FormInputFlight from './form-input-flight';
import { PinkButtonContainer, EditIcon, RotatingIcon, Colors } from '../../../utils/budget-tool-specific';
import { getFlightDetails, selectBestFlights, calculatePriceAverage, calculatePriceMin, calculatePriceMax, calculateDurationAverage, calculateDurationMin, calculateStopsAverage, calculateDurationMax, calculateStopsMin, calculateStopsMax } from './flight-helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateDocumentArray, updateDocumentProperty } from '../../../utils/firestore-functions';
import { GetDB, generateGUID } from '../../../utils/database/firebase-budget-tool';
import { DateTime } from 'luxon';

const FlightInputRow = (props) => {

    const budgetDB = GetDB();

    const [isFetching, setIsFetching] = useState(false);
    const [bestFlights, setBestFlights] = useState([]);

    const [flightData, setFlightData] = useState ({
        priceAverage: 0,
        priceMin: 0,
        priceMax: 0,
        durationAverage: 0,
        durationMin: 0,
        durationMax: 0,
        stopsAverage: 0,
        stopsMin: 0,
        stopsMax: 0,
        originAirport: "",
        numPeople: 0,
    })

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (props.data !== undefined && props.flight !== undefined && props.flight.numPeople !== 0){
            setFlightData(props.flight);
        }
        /*
        if (props.flight.priceAverage === undefined || props.flight.priceAverage === 0){
            getFlightDetails(props.data, props.i, setFlightDetails, setError);
        }
        else{
            setFlightData(props.flight);
        }
        */
    }, [props.data]);

    const setFlightArray = (flight, i) => {
        let newArray = [...props.flights];
        newArray.push(flight);
        props.setFlights((flights) => {
            let newArray = [...flights];
            newArray.push(flight);
            return newArray
        });
    }

    const setFlightDetails = (resp) => {
        setIsFetching(false);

        let bFlights = selectBestFlights(resp, 5, flightData.numPeople);
        //console.log(bFlights);
        let newFlights = {};
        newFlights.priceAverage = calculatePriceAverage(bFlights);
        newFlights.priceMin = calculatePriceMin(bFlights);
        newFlights.priceMax = calculatePriceMax(bFlights);
        newFlights.durationAverage = calculateDurationAverage(bFlights, resp.data.segments);
        newFlights.durationMin = calculateDurationMin(bFlights, resp.data.segments);
        newFlights.durationMax = calculateDurationMax(bFlights, resp.data.segments);
        newFlights.stopsAverage = calculateStopsAverage(bFlights);
        newFlights.stopsMin = calculateStopsMin(bFlights);
        newFlights.stopsMax = calculateStopsMax(bFlights);

        let tempFlights = {...flightData};
        tempFlights.priceAverage = newFlights.priceAverage;
        tempFlights.priceMin = newFlights.priceMin;
        tempFlights.priceMax = newFlights.priceMax;
        tempFlights.durationMin = newFlights.durationMin;
        tempFlights.durationMax = newFlights.durationMax;
        tempFlights.durationAverage = newFlights.durationAverage;
        tempFlights.stopsAverage = newFlights.stopsAverage;
        tempFlights.stopsMin = newFlights.stopsMin;
        tempFlights.stopsMax = newFlights.stopsMax;
        setFlightData(tempFlights);

        let x = props.data;
        x.flights.value[props.id] = tempFlights;
        props.setData(x);
    }

    const setError = (err) => {
        console.log(err);
        setIsFetching(false);
    }

    const displayMinutes = (value) => {
        let text = "";
        let minutes = value % 60;
        let hours = Math.floor(value / 60);
        if (hours !== 0){
            text += hours + " hr ";
        }
        text += minutes + " min";
        return text;
    };

    const displayDollars = (value) => {
        let text = value.toLocaleString("en-US");
        return text;
    }

    const normalStyle = {
        transition: '1s, transform 0.5s',
    };
      
    const transformedStyle = {
        transition: '1s, transform 0.5s',
        transform: 'rotate(180deg)',
    };

    const getBestFlights = (response) => {
        setFlightDetails(response);
    }

    const fetchFlight = () => {
        if (flightData.originAirport !== undefined && flightData.originAirport.length === 3){
            setIsFetching(true);
            getFlightDetails(props.data, props.i, getBestFlights, catchError);
        }
        else{
            console.log("Origin airport was not set correctly: " + flightData.originAirport);
        }
    }

    const catchError = (err) => {
        setError();
        let e = {};
        e.message = err.toString();
        e.timestamp = new Date().toLocaleString();
        e.origin = flightData.originAirport;
        e.destination = props.data.destination.value;
        e.currency = props.data.currency.value;
        e.departureDate = props.data.departureDate.value;
        e.arrivalDate = props.data.returnDate.value;
        e.passengers = flightData.numPeople;
        updateDocumentProperty(budgetDB, "logs", "errors", generateGUID(), e);
    }

    return (
        <>
        <Row>
            {!props.client && <Col lg="auto" style={{paddingTop: "30px", paddingLeft: "15px", paddingRight: "0px"}}>
                <PinkButtonContainer onClick={(x) => fetchFlight()}>
                    <RotatingIcon isOn={isFetching} icon={['fas', 'sync']} style={{height: "25px"}}/>
                </PinkButtonContainer>
            </Col>}
            <Col lg="auto" style={{paddingTop: "35px", paddingLeft: "15px", paddingRight: "0px"}}>
                <ButtonContainer onClick={(x) => setIsOpen(!isOpen)}>
                    <CollapseIcon icon={['fas', 'chevron-down']} style={isOpen ? transformedStyle : normalStyle}/>
                </ButtonContainer>
            </Col>
            <Col>
                <Row>
                    <Col lg="1">
                        <FormInputFlight title="Origin" 
                                    i={props.i}
                                    id="originAirport" data={props.data} 
                                    setData={(x) => props.setData(x)} 
                                    type="text"
                                    client={props.client}/>
                    </Col>
                    <Col lg="1">
                        <FormInputFlight title="Pass." 
                                    i={props.i}
                                    id="numPeople" data={props.data} 
                                    setData={(x) => props.setData(x)} 
                                    type="number"
                                    client={props.client}/>
                    </Col>
                    <Col lg="2">
                        <FormInputFlight
                            unchanged
                            title="Price Min"
                            currency={props.data.currency.value.slice(3)}
                            value={displayDollars(flightData.priceMin)}/>
                    </Col>
                    <Col lg="2">
                        <FormInputFlight
                            unchanged
                            title="Price Average"
                            id="priceAverage"
                            info
                            setInfoModalOpen={(x) => props.setInfoModalOpen(x)}
                            setInfoMessage={(x) => props.setInfoMessage(x)}
                            currency={props.data.currency.value.slice(3)}
                            value={displayDollars(flightData.priceAverage)}/>
                    </Col>
                    <Col lg="2">
                            <FormInputFlight
                                unchanged
                                title="Average Duration"
                                value={displayMinutes(flightData.durationAverage)}/>
                    </Col>
                    <Col lg="2">
                            <FormInputFlight
                                unchanged
                                title="Average Connections"
                                value={(flightData.stopsAverage)}/>
                    </Col>
                    <Col lg="2">
                        <FormInputFlight
                            unchanged
                            title="Price Avg. Total"
                            currency={props.data.currency.value.slice(3)}
                            value={displayDollars(flightData.priceAverage *  props.data.flights.value[props.id].numPeople)}/>
                    </Col>
                </Row>
                <Collapse isOpen={isOpen}>
                    <Row>
                        {/* <Col lg="2">
                            <FormInputFlight
                                unchanged
                                title="Duration Average"
                                value={displayMinutes(flightData.durationAverage)}/>
                        </Col> */}
                        
                        <Col lg="2">
                            <FormInputFlight
                                unchanged
                                title="Duration Max"
                                value={displayMinutes(flightData.durationMax)}/>
                        </Col>
                        <Col lg="2">
                            <FormInputFlight
                                unchanged
                                title="Duration Min"
                                value={displayMinutes(flightData.durationMin)}/>
                        </Col>
                        {/* <Col lg="2">
                            <FormInputFlight
                                unchanged
                                title="Stops Average"
                                value={flightData.stopsAverage}/>
                        </Col> */}
                        <Col lg="2">
                        <FormInputFlight
                            unchanged
                            title="Price Max"
                            currency={props.data.currency.value.slice(3)}
                            value={displayDollars(flightData.priceMax)}/>
                        </Col>
                        <Col lg="2">
                            <FormInputFlight
                                unchanged
                                title="Stops Min"
                                id="stopsMin"
                                info
                                setInfoModalOpen={(x) => props.setInfoModalOpen(x)}
                                setInfoMessage={(x) => props.setInfoMessage(x)}
                                value={flightData.stopsMin}/>
                        </Col>
                        <Col lg="2">
                            <FormInputFlight
                                unchanged
                                title="Stops Max"
                                id="stopsMax"
                                info
                                setInfoModalOpen={(x) => props.setInfoModalOpen(x)}
                                setInfoMessage={(x) => props.setInfoMessage(x)}
                                value={flightData.stopsMax}/>
                        </Col>
                    </Row>
                </Collapse>
            </Col>
            {!props.client && <Col lg="1" style={{paddingTop: "28px", paddingLeft: "0px"}}>
                <PinkButtonContainer onClick={(x) => props.deleteFlight(props.i)}>
                    <EditIcon icon={['fas', 'trash']} style={{height: "25px"}}/>
                </PinkButtonContainer>
            </Col>}
        </Row>
        
        </>
    )
}

export default FlightInputRow;

export const ButtonContainer = styled.div`
    margin: 0px 0px ;
    width: auto;
    height: 25px;
    border-radius: 25px;
    padding: 15px 5px 5px 5px;
    display: inline;
    &:hover {
        background-color: ${Colors.primaryLight};
    }
`

export const CollapseIcon = styled(FontAwesomeIcon)`
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 4px;
    width: 30px !important;
    height: 15px;
    color: ${Colors.primaryDark};
`;

