
const validateValue = (id, data, setData, valid) => {
    let newValid = valid;
    if (data[id].required === true) {
        if (data[id].value === "" || data[id].value < 1) {
            let newData = {...data};
            newData[id].invalid = true;
            setData(newData);
            newValid = false;
        } else {
            let newData = {...data};
            newData[id].invalid = false;
            setData(newData);
        }
    }
    return newValid;
} 

export const validateBudgetForm = (data, setData) => {
    let valid = true;
    Object.keys(data).map((el, i) => {     
        valid = validateValue(el, data, setData, valid);
    })
    return valid;
}

export const calculateContingency = (subtotals, costStructure) => {
    let contingency = calculatePerAttendee(subtotals) * 0.1;
    return contingency;
}

export const calculateInternal = (subtotals, costStructure) => {
    if (costStructure !== undefined){
        return(calculatePerAttendee(subtotals) - subtotals[1].flightCost.subtotal) * (costStructure.miscRow.costPer["Management fee"] / 100);
    }
    else{
        return(calculatePerAttendee(subtotals) - subtotals[1].flightCost.subtotal) * 0.2;
    }
}

export const calculatePerAttendee = (subtotals) => {
    let sum = subtotals.reduce((sum, item) => sum + parseInt(Object.values(item)[0].subtotal), 0);
    return sum;
}

export const calculatePerTrip = (data, subtotals) => {
    let perAttendee = calculatePerAttendee(subtotals) + calculateContingency(subtotals) + calculateInternal(subtotals);
    let attendees = data.attendees.value;

    return (perAttendee*attendees);
}

export const calculateNumNights = (data) => {
    let str = data.tripLength.value;
    let matches = str.match(/(\d+)/);
    let match2 = "";
    if (matches !== null) {
        match2 = str.substring(matches.index + matches[0].length).match(/(\d+)/);
    }
    if (match2 !== null) {
        return parseInt(match2[0]);
    } else {
        return -1;
    }
}

export const calculateCPAccommodation = (data, costStructure) => {
    let costPer = 0;
    if (costStructure !== undefined)
    {
        if (data.inclusive.value === "All-Inclusive" && data.occupancy.value === "Single Occupancy"){
            costPer = costStructure["miscRow"]["costPer"]["Inclusive"]["experienceType"][data.experienceType.value];
        }
        else if (data.inclusive.value === "All-Inclusive" && data.occupancy.value === "Double Occupancy"){
            costPer = (costStructure["miscRow"]["costPer"]["Inclusive"]["experienceType"][data.experienceType.value] * 1.5);
        }
        else{
            costPer = costStructure["accommodationRow"]["costPerNight"]["experienceType"][data.experienceType.value];
        }
    }
    else
    {
        console.log("Should never be in here, accoms")
    }
    return costPer;
}  

export const calculateAccommodation = (data, costStructure) => {
    if (data.occupancy.value === "Single Occupancy"){
        return (calculateCPAccommodation(data, costStructure) * calculateNumNights(data, costStructure));
    }
    else {
        return (Math.ceil(calculateCPAccommodation(data, costStructure) / 2) * calculateNumNights(data, costStructure));
    }
}

export const calculateNumActivities = (data, costStructure) => {
    let numActivities = 0;
    if (data.workBalance.value === "All play") {
        numActivities = calculateNumNights(data) + 1;
    } else if (data.workBalance.value === "All work") {
        numActivities = 0;
    } else if (data.workBalance.value === "Mostly work") {
        numActivities = 1;
    } else { // Mix
        numActivities = calculateNumNights(data) - 1;
    }
    return numActivities;
}

export const calculateCPActivity = (data, costStructure) => {
    let costPer = 0;
    if (costStructure !== undefined)
    {
        costPer = costStructure["activityRow"]["costPerActivity"]["experienceType"][data.experienceType.value];
    }
    else
    {
        console.log("NEver be here, activity")
        if (data.experienceType.value === "3 stars") { 
            costPer = 80;
        } else if (data.experienceType.value === "4 stars") { 
            costPer = 150;
        } else if (data.experienceType.value === "5 stars") { 
            costPer = 250;
        }
    }
    return costPer;
}

export const calculateActivity = (data, costStructure) => {
    return (calculateCPActivity(data, costStructure) * calculateNumActivities(data));
}

export const calculateNumTransportation = (data) => {
    return (data.transportation.value.length);
}

export const calculateCPTransportation = (data, costStructure) => {
    let costPer = 0;
    if (costStructure !== undefined)
    {
        costPer = costStructure["transportationRow"]["costPerTransportation"]["experienceType"][data.experienceType.value];
    }
    else
    {
        console.log("Should never be in here.")
        if (data.experienceType.value === "3 stars") { 
            costPer = 30;
        } else if (data.experienceType.value === "4 stars") { 
            costPer = 30;
        } else if (data.experienceType.value === "5 stars") { 
            costPer = 75;
        }
    }
    return costPer;
}

export const calculateTransportation = (data, costStructure) => {
    return (calculateNumTransportation(data) * calculateCPTransportation(data, costStructure));
}

export const calculateCPBreakfast = (data, costStructure) => {
    let costPer = 0;
    if (data.inclusive.value === "All-Inclusive") {
        return 0;
    }
    if (costStructure !== undefined)
    {
        costPer = costStructure["mealsRow"]["costPerBreakfast"]["experienceType"][data.experienceType.value];
    }
    else
    {
        console.log("Should never be here, breakfast")
        if (data.experienceType.value === "3 stars"){
            costPer = 50;
        } else if (data.experienceType.value === "4 stars") {
            costPer = 65;
        } else if (data.experienceType.value === "5 stars") {
            costPer = 75;
        }
    }
    return costPer; 
} 

export const calculateCPLunch = (data, costStructure) => {
    let costPer = 0;
    if (data.inclusive.value === "All-Inclusive") {
        return 0;
    }
    if (costStructure !== undefined)
    {
        costPer = costStructure["mealsRow"]["costPerLunch"]["experienceType"][data.experienceType.value];
    }
    else
    {
        console.log("Should never be here, lunch")
        if (data.experienceType.value === "3 stars"){
            costPer = 75;
        } else if (data.experienceType.value === "4 stars") {
            costPer = 85;
        } else if (data.experienceType.value === "5 stars") {
            costPer = 95;
        }
    }
    return costPer; 
}

export const calculateCPDinner = (data, costStructure) => {
    let costPer = 0;
    if (data.inclusive.value === "All-Inclusive") {
        return 0;
    }
    if (costStructure !== undefined)
    {
        costPer = costStructure["mealsRow"]["costPerDinner"]["experienceType"][data.experienceType.value];
    }
    else
    {
        console.log("Should never be here, dinner")
        if (data.experienceType.value === "3 stars"){
            costPer = 100;
        } else if (data.experienceType.value === "4 stars") {
            costPer = 125;
        } else if (data.experienceType.value === "5 stars") {
            costPer = 150;
        }
    }
    return costPer; 
} 

export const calculateCPCoffee = (data, costStructure) => {
    let costPer = 0;
    if (data.inclusive.value === "All-Inclusive") {
        return 0;
    }
    if (costStructure !== undefined)
    {
        costPer = costStructure["mealsRow"]["costPerCoffee"]["experienceType"][data.experienceType.value];
    }
    else
    {
        console.log("Should never be here, coffee")
        if (data.experienceType.value === "3 stars"){
            costPer = 15;
        } else if (data.experienceType.value === "4 stars") {
            costPer = 20;
        } else if (data.experienceType.value === "5 stars") {
            costPer = 25;
        }
    }
    return costPer; 
}

export const calculateCPAlcohol = (data, costStructure) => {
    let costPer = 0;
    if (data.inclusive.value === "All-Inclusive") {
        return 0;
    }
    if (costStructure !== undefined)
    {
        costPer = costStructure["mealsRow"]["costPerAlcohol"]["experienceType"][data.experienceType.value];
    }
    else
    {
        console.log("Should never be here, alcohol")
        if (data.experienceType.value === "3 stars"){
            costPer = 45;
        } else if (data.experienceType.value === "4 stars") {
            costPer = 60;
        } else if (data.experienceType.value === "5 stars") {
            costPer = 75;
        }
    }
    return costPer; 
}

export const calculateNumAlcohol = (data) => {
    let numAlcohol = 0;
    if  (data.alcohol.value === "All nights") {
        numAlcohol = calculateNumNights(data);
    } else if (data.alcohol.value === "Most nights") {
        numAlcohol = Math.trunc(calculateNumNights(data) * 0.85);
    } else if (data.alcohol.value === "Some nights") {
        numAlcohol = Math.trunc(calculateNumNights(data) * 0.5);
    } 
    return numAlcohol;
}
export const calculateBreakfast = (data, costStructure) => {
    return (calculateCPBreakfast(data, costStructure) * calculateNumNights(data));
}
export const calculateLunch = (data, costStructure) => {
    return (calculateCPLunch(data, costStructure) * (calculateNumNights(data)-1));
}
export const calculateDinner = (data, costStructure) => {
    return (calculateCPDinner(data, costStructure) * calculateNumNights(data));
}
export const calculateCoffee = (data, costStructure) => {
    return (calculateCPCoffee(data, costStructure) * calculateNumNights(data));
}
export const calculateAlcohol = (data, costStructure) => {
    return (calculateCPAlcohol(data, costStructure) * calculateNumAlcohol(data));
}
export const calculateMeals = (data, costStructure) => {
    return (calculateBreakfast(data, costStructure) + calculateLunch(data, costStructure) + calculateDinner(data, costStructure) + calculateCoffee(data, costStructure) + calculateAlcohol(data, costStructure));
}

export const calculateCPMisc = (item, data, costStructure) => {
    let costPer = 0;
    if  (item === "COVID test"){
        costPer = costStructure["miscRow"]["costPer"]["Covid test"];
    } else if  (item === "Swag"){
        costPer = costStructure["miscRow"]["costPer"]["Swag"]["experienceType"][data.experienceType.value]
    } else if  (item === "Videographer/Photographer"){
        costPer = costStructure["miscRow"]["costPer"]["Photographer"]["experienceType"][data.experienceType.value] * (calculateNumNights(data) + 1) / data.attendees.value;
    } else if  (item === "Onsite Coordinator"){
        costPer = costStructure["miscRow"]["costPer"]["Onsite Coordinator"] * (calculateNumNights(data) + 1) / data.attendees.value;
    } else if  (item === "Facilitator"){
        costPer = costStructure["miscRow"]["costPer"]["Facilitator"] / data.attendees.value;
    } else if  (item === "Travel insurance"){
        costPer = costStructure["miscRow"]["costPer"]["Travel insurance"];
    } else if (item ==="Microsite") {
        costPer = costStructure["miscRow"]["costPer"]["Microsite"] / data.attendees.value;
    } else if (item ==="Enhanced Decor & Entertainment") {
        costPer = costStructure["miscRow"]["costPer"]["Enhanced Decor & Entertainment"][data.workBalance.value];
    } else if (item ==="AV") {
        costPer = costStructure["miscRow"]["costPer"]["AV"][data.workBalance.value];
    } 
    return costPer;
}

export const getMisc = (data, costStructure) => {
    let misc = {};
    for (let i = 0; i < data.services.value.length; i++) {
        misc[i] = {title: data.services.value[i], costPer: calculateCPMisc(data.services.value[i], data, costStructure)};
    }
    return misc;
}
export const calculateMisc = (data, costStructure) => {
    let sum = 0; 
    let serviceArray = getMisc(data, costStructure);
    for (let i = 0; i < Object.keys(serviceArray).length; i++) {
        sum += serviceArray[i].costPer;
    }
    return Math.trunc(sum);
}

export const calculateNumCoordinators = (data) => {
    let num = Math.ceil(data.attendees.value / 50);
    if (num > 7) {
        num = 7;
    } 
    return num;
}

export const calculateCPOnsite = (data, costStructure) => {
    return costStructure["miscRow"]["costPer"]["Onsite Coordinator"]
}

export const calculateOnsite = (data, costStructure) => {
    return (calculateCPOnsite(data, costStructure) * calculateNumCoordinators(data) / data.attendees.value * (calculateNumNights(data, costStructure) + 1));
}

export const displayValue = (num) => {
    let display = num.toLocaleString(undefined, {minimumFractionDigits: 0,maximumFractionDigits: 0});

    if (display.endsWith(".00")) {
        display = display.substring(0, display.length - 3);
    } 
    return display;
}

export const displayValue2 = (num) => {
    if (num !== undefined){
        let display = num.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
        return display;
    }
}

export const lengthOptions = ["3 days / 2 nights", "4 days / 3 nights", "5 days / 4 nights", "6 days / 5 nights", "7 days / 6 nights", "8 days / 7 nights",];
export const experienceOptions = ["3 stars", "4 stars", "5 stars"];
export const goalOptions = ["All work", "Mostly work", "Mix", "Mostly play", "All play"];
export const alcoholOptions = ["All nights", "Most nights", "Some nights", "No"];
export const transportOptions = ["Home to Airport", "Hotel to Airport", "Airport to Hotel", "Airport to Home"];
export const addsOptions = ["Videographer/Photographer", "Swag", "Travel insurance", "Microsite", "Enhanced Decor & Entertainment", "AV"];