import React from 'react';
import { Col } from 'reactstrap';
import { displayValue } from '../../helper';

const SubtotalCols = (props) => {

    return(
        <React.Fragment>
            <Col lg="1">
                x {props.attendees}
            </Col>
            <Col lg="2">
                <p>
                    {props.currency}{displayValue(props.costper * props.attendees)}
                </p>
            </Col> 
        </React.Fragment>
    )
}

export default SubtotalCols;