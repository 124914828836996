import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { PrimaryButton, WhiteForm } from '../../../utils/budget-tool-specific';
import { listenToDocument, setDocument } from '../../../utils/firestore-functions';
import { GetDB } from '../../../utils/database/firebase-budget-tool';
import SettingsTransportation from './settings-transportation';
import SettingsAccommodations from './settings-accommodations';
import SettingsActivity from './settings-activity';
import SettingsMeals from './settings-meals';
import SettingsMisc from './settings-misc';
import SettingsBudget from './settings-budget';

const SettingsList = (props) => {
    const [data, setData] = useState();
    
    const budgetDB = GetDB();

    const saveSettings = () => {
        setDocument(budgetDB, "admin", "calculations", data);
        //TODO: Probably should a success modal
    }

    const setArea = (area, newData) => {
        let tempData = {...data};
        tempData[area] = newData;
        setData(tempData);
    } 

    useEffect(() => {
        var unsubscribe = listenToDocument(budgetDB, "admin", "calculations", setData);
        return unsubscribe;
    },[props]);

    return ( 
        <React.Fragment>
            <br />
            <PrimaryButton onClick={() => saveSettings()}>Save Settings</PrimaryButton>
            {data !== undefined && <div>
                <WhiteForm>
                    <Row>
                        <Col lg='4'>
                            <SettingsTransportation data={data.transportationRow} setArea={setArea}></SettingsTransportation>
                        </Col>
                    </Row>
                </WhiteForm>
                <WhiteForm>
                    <Row>
                        <Col lg='4'>
                            <SettingsAccommodations data={data.accommodationRow} setArea={setArea}></SettingsAccommodations>
                        </Col>
                    </Row>
                </WhiteForm>
                <WhiteForm>
                    <Row>
                    <Col lg='4'>
                            <SettingsActivity data={data.activityRow} setArea={setArea}></SettingsActivity>
                        </Col>
                    </Row>
                </WhiteForm>
                <WhiteForm>
                    <Row>
                        <Col lg='4'>
                            <SettingsMeals data={data.mealsRow} setArea={setArea}></SettingsMeals>
                        </Col>
                        
                    </Row>
                </WhiteForm>
                <WhiteForm>
                    <Row>
                        <Col lg='4'>
                            <SettingsMisc data={data.miscRow} setArea={setArea}></SettingsMisc>
                        </Col>
                    </Row>
                </WhiteForm>
            </div>}
        </React.Fragment>
    )
}
 
export default SettingsList;