import React from 'react';
import PageTemplate from '../page-template';
import { Spacer, HeaderTitle } from '../../../utils/budget-tool-specific';
import SettingsList from './settings-list';

const SettingsComponent = (props) => {
    return (
        <React.Fragment>
            <Spacer height="60px" id="height-spacer"/>
            <HeaderTitle>
                Settings
            </HeaderTitle>
            <SettingsList />
        </React.Fragment>
    )
}
const Settings = (props) => {

    if (props.collapsed) {
        return <SettingsComponent />;
    } else {
        return <PageTemplate component={<SettingsComponent/>} client={props.client}/>
    }
}

 
export default Settings;