import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "reactstrap";
import { HeaderTitle, 
    Spacer,
    PrimaryButton,} from "../../../utils/budget-tool-specific";
import { useParams } from "react-router-dom";
import PageTemplate from "../page-template";
import FlightGuideInput from "./flight-guide-input";

const FlightsEditComponent = (props) => {

    const backToGuideList = () => {
        window.location = "/budget-app/flights";
    }

    return(
        <React.Fragment>
            <Spacer height="60px" id="height-spacer"/>
            <Row>
                <Col>
                    <HeaderTitle>
                        
                    </HeaderTitle>
                </Col>
                {!props.client &&
                <Col style={{textAlign: "right"}}>
                    <PrimaryButton style={{marginTop: "30px", marginRight: "30px"}}
                                onClick={(x) => backToGuideList()}> Back to Guide List</PrimaryButton>
                </Col>
                }
            </Row>
            <FlightGuideInput id={props.experience} client={props.client}/> 
        </React.Fragment>
    )
}

const FlightsEdit = (props) => {

    let { experience } = useParams();
    if (experience === undefined) {
        window.history.replaceState(null, "", "/budget-app/flights");
    }

    if (props.collapsed) { 
        return <FlightsEditComponent experience={experience} client={props.client}/>
    } else {
        return <PageTemplate component={<FlightsEditComponent experience={experience} client={props.client}/>} client={props.client}/> 
    }
} 

export default FlightsEdit;

const IconRow = styled(Row)`
    display: contents;
`

const StyledCol = styled(Col)`
    text-align: center;
    margin-bottom: 50px;
`
const StyledContainer = styled(Container)`
    padding: 32px;
`