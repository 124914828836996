import React, { useState, useEffect } from 'react';
import { Spacer, NoMarginPaddedContainer, LeftLabel, HighlightedCost, TableTitle, WhiteTableContainer, OutputRow, BoldLabel } from '../../../../utils/budget-tool-specific';
import { Col } from 'reactstrap';
import { calculatePerAttendee, 
        calculatePerTrip, 
        calculateNumNights, 
        calculateAccommodation, 
        calculateCPAccommodation, 
        calculateNumActivities, 
        calculateCPActivity, 
        calculateActivity, 
        calculateCPTransportation, 
        calculateTransportation, 
        calculateMeals, 
        calculateBreakfast, 
        calculateCPBreakfast, 
        calculateLunch, 
        calculateCPLunch, 
        calculateDinner, 
        calculateCPDinner, 
        calculateCoffee, 
        calculateCPCoffee, 
        calculateAlcohol, 
        calculateCPAlcohol, 
        calculateNumAlcohol, 
        calculateMisc, 
        getMisc, 
        displayValue2, 
        calculateContingency,
        calculateOnsite, 
        calculateCPOnsite,
        calculateNumCoordinators,
        calculateInternal} from '../../helper';
import MealsRow from './meals-row';
import AccommodationRow from './accommodation-row';
import AirfareRow from './airfare-row';
import MiscellaneousRow from './miscellaneous-row';

const BudgetCostOutput = ({data, costStructure}) => {

    const subtotals = [
        {accommodation: {
            subtotal: calculateAccommodation(data, costStructure), 
            numNights: calculateNumNights(data, costStructure), 
            costPerNight: calculateCPAccommodation(data, costStructure)
        }},
        {flightCost: {
            subtotal: data.flightCost.value,
        }},
        {activity: {
            subtotal: calculateActivity(data, costStructure), 
            numActivities: calculateNumActivities(data, costStructure), 
            costPerActivity: calculateCPActivity(data, costStructure),
        }},
        {transportation: {
            subtotal: calculateTransportation(data, costStructure), 
            transportation: data.transportation.value, 
            costPerTransportation: calculateCPTransportation(data, costStructure),
        }},
        {meals: {
            subtotal: calculateMeals(data, costStructure),
            breakfast: {
                subtotal: calculateBreakfast(data, costStructure),
                costPer: calculateCPBreakfast(data, costStructure),
                numBreakfast: calculateNumNights(data, costStructure),
            },
            lunch: {
                subtotal: calculateLunch(data, costStructure),
                costPer: calculateCPLunch(data, costStructure),
                numLunch: calculateNumNights(data, costStructure) -1,
            },
            dinner: {
                subtotal: calculateDinner(data, costStructure),
                costPer: calculateCPDinner(data, costStructure),
                numDinner: calculateNumNights(data, costStructure),
            },
            coffee: {
                subtotal: calculateCoffee(data, costStructure),
                costPer: calculateCPCoffee(data, costStructure),
                numCoffee: calculateNumNights(data, costStructure),
            },
            alcohol: {
                subtotal: calculateAlcohol(data, costStructure),
                costPer: calculateCPAlcohol(data, costStructure),
                numAlcohol: calculateNumAlcohol(data, costStructure)
            }
            
        }},
        { misc: {
            subtotal: calculateMisc(data, costStructure), 
            misc: getMisc(data, costStructure),
        }}, 
        { onsite: { 
            subtotal: calculateOnsite(data, costStructure),
            costPer: calculateCPOnsite(data, costStructure),
            numCoordinators: calculateNumCoordinators(data, costStructure),
        }},
    ]

    const totals = {
        perAttendee: calculatePerAttendee(subtotals, costStructure) + calculateContingency(subtotals, costStructure) + calculateInternal(subtotals, costStructure),
        perTrip: calculatePerTrip(data, subtotals, costStructure),
    }

    useEffect(() => {
        //
    }, [data]);

    return (
        <React.Fragment>
            <Spacer/>
            <NoMarginPaddedContainer id="cost-output-container">
                <LeftLabel>Per Attendee Cost: </LeftLabel> <HighlightedCost>${displayValue2(totals.perAttendee)}</HighlightedCost> <br/>
                <LeftLabel>Total Cost: </LeftLabel> <HighlightedCost>${displayValue2(totals.perTrip)}</HighlightedCost> <br/> <br/>

                <TableTitle>
                    Per Attendee Breakdown
                </TableTitle>
                <WhiteTableContainer id="output-container"> 
                    <OutputRow>
                        <Col lg="1">
                        </Col>
                        <Col>
                            <BoldLabel>
                                Category
                            </BoldLabel>
                        </Col>
                        <Col lg="1">
                            <BoldLabel>
                                %
                            </BoldLabel>
                        </Col>
                        <Col>
                        </Col>
                        <Col lg="3">
                            <BoldLabel>
                                Subtotal
                            </BoldLabel>
                        </Col> 
                    </OutputRow>
                    <AccommodationRow data={data} accommodation={subtotals[0].accommodation} currency="$" total={totals.perAttendee}/>
                    <AirfareRow flightCost={subtotals[1].flightCost} transportation={subtotals[3].transportation} currency="$" total={totals.perAttendee}/>
                    <MealsRow meals={subtotals[4].meals} activity={subtotals[2].activity} currency="$" total={totals.perAttendee} data={data}/>
                    <MiscellaneousRow attendees={data.attendees.value} misc={subtotals[5].misc} contingency={calculateContingency(subtotals, costStructure)} onsite={subtotals[6].onsite} internal={calculateInternal(subtotals, costStructure)} currency="$" data={data} total={totals.perAttendee} costStructure={costStructure}/>
                </WhiteTableContainer>
            </NoMarginPaddedContainer>

        </React.Fragment>
    )
} 

export default BudgetCostOutput;