import React from "react";
import CostRow from "./cost-row";

const AccommodationRow = ({accommodation, currency, total, data}) => {

    return (
        <React.Fragment>
            <CostRow title="Accommodation" 
                    icon='bed'
                    single
                    accommodations
                    attendees = {data.attendees.value}
                    occupancy = {data.occupancy.value}
                    currency={currency} 
                    costPer={accommodation.costPerNight}
                    perLabel="per night"
                    num={accommodation.numNights}
                    type="nights"
                    percent={(Math.round((accommodation.subtotal / total) * 100))}
                    subtotal={accommodation.subtotal}/>
        </React.Fragment>
    )
}

export default AccommodationRow;