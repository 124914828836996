import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, Collapse, Card, CardBody } from "reactstrap";
import { Colors, CollapseRow } from "../../../../utils/budget-tool-specific";
import TransportationBreakdownRow from "../../../budget/budget-tool/cost-output/transportation-breakdown-row";
import { calculateCostMiscellaneous, exchangeRate } from "./cost-helper";
import NoCostRow from "./exp-no-cost-row";

const NotesRow = ({guideData, expData}) => {

    const [breakdownOpen, setBreakdownOpen] = useState(false);

    const toggleCollapse = () => {

        if (breakdownOpen === true) {
            setBreakdownOpen(false);
        } else {
            setBreakdownOpen(true);
        }
    }

    const normalStyle = {
        transition: '1s, transform 0.5s',
    };
      
      const transformedStyle = {
        transition: '1s, transform 0.5s',
        transform: 'rotate(180deg)',
    };

    return (
        <React.Fragment>
           <NoCostRow title="Notes" 
                icon='sticky-note'
                height
                detail
                isOpen={breakdownOpen}
                setIsOpen={setBreakdownOpen}
                currency={guideData.currency.value.slice(3)} 
                subtotal={calculateCostMiscellaneous(guideData,expData)}
                attendees={guideData.attendees.value}/>
            <Collapse isOpen={breakdownOpen} >
                <CollapseRow>
                        <Col>
                            <PinkCard>
                                <StyledP>{expData.notes}</StyledP>
                            </PinkCard>
                        </Col>
                </CollapseRow>
            </Collapse>
        </React.Fragment>
    )
}

export default NotesRow;

const PinkCard = styled(Card)`
    background-color: ${Colors.primaryAccent};
    border: none;
`

const StyledP = styled.p`
    margin-bottom: 0px;
    margin-top: 0px;
    padding: 10px;
`;
