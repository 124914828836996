import React from "react";
import { Row, Col } from "reactstrap";
import { OutputRow, TableIcon, BoldLabel } from "../../../../utils/budget-tool-specific";
import SubtotalColumns from "./subtotal-cols";
import { displayValue } from "../../helper";
import CostRow from "./exp-cost-row";
import { calculateCostFlight, exchangeRate } from "./cost-helper";

const FlightRow = ({guideData, expData}) => {

    return (
        <React.Fragment>
           <CostRow title="Flight Cost" 
                icon='plane'
                single
                detail
                currency={guideData.currency.value.slice(3)} 
                subtotal={calculateCostFlight(guideData, expData)}
                attendees={guideData.attendees.value}/>
        </React.Fragment>
    )
}

export default FlightRow;