import firebase from "firebase/compat/app";
import { db } from "../firebase";
import { collection, doc, onSnapshot, runTransaction, query, getDocs, deleteDoc, arrayUnion } from "firebase/firestore";
import { newTeam, newGameSettings } from "../utils/final-verdict-specific";

export const deleteGameV3 = async (gameName, database) => {
    const adminRef = doc(database, "adminSettings", "gameNames");
    const q = query(collection(database, gameName));
    const querySnapshot  = await getDocs(q);
    try {
        await runTransaction(database, async (transaction) => {
            let adminData = (await transaction.get(adminRef)).data();
            delete adminData.gameNames[gameName];
            querySnapshot.forEach((document) => {
                const docRef = doc(database, gameName, document.id);
                transaction.delete(docRef);
            })
            transaction.update(adminRef, adminData);
            console.log("Success.");
        });
    } catch (e) {
        console.log("Transaction Failed: ", e);
    }
};

export const deleteGameV2 = (gameName, database) => {
    console.log(database);
    database.collection(gameName).get().then((docQuery) => {
        return database.runTransaction((transaction) => {
            return transaction.get(database.collection("adminSettings").doc("gameNames")).then((document) => {
                var data = document.data();
                delete data.gameNames[gameName];
                docQuery.forEach(doc => {
                    transaction.delete(database.collection(gameName).doc(doc.id));
                })
                transaction.update(database.collection("adminSettings").doc("gameNames"), data);
            });
        });
    });
}

export const getUTCTimeV2 = (database, gameName, successCallback, minutesOffset) => {
    fetch("https://us-central1-escape-room-1980b.cloudfunctions.net/getUTCTime", {
        "method": "GET",
        "headers": {}
    })
    .then(response => response.json())
    .then(response => {successCallback(database, gameName, response.result, minutesOffset);})
    .catch(err => { console.log(err); });
}

export const startGameV2 = (database, collectionName, time, minutesOffset) => {
    if (minutesOffset === undefined){
        minutesOffset = 0;
    }
    else{
        minutesOffset = minutesOffset * 60 * 1000;
    }
    return database.runTransaction((transaction) => {
        return transaction.get(database.collection(collectionName).doc("gameSettings")).then((document) => {
            var obj = document.data();
            transaction.update(database.collection(collectionName).doc("gameSettings"), {
                ...obj,
                hasGameStarted: true,
                startTime: (time - minutesOffset).toString()
            });
        })
    }).then(() => {
        
    }).catch((error) => {
        
    })
};

export const startGameV3 = async (database, collectionName, time, minutesOffset) => {
    if (minutesOffset === undefined){
        minutesOffset = 0;
    }
    else{
        minutesOffset = minutesOffset * 60 * 1000;
    }
    const gameSettingsRef = doc(database, collectionName, "gameSettings");
    try {
        await runTransaction(database, async (transaction) => {
            const settings = await transaction.get(gameSettingsRef);
            var obj = settings.data();
            transaction.update(gameSettingsRef, {
                ...obj,
                hasGameStarted: true,
                startTime: (time - minutesOffset).toString()
            });
        });
        console.log("Success.");
    } catch (e) {
        console.log("Transaction Failed.", e);
    }
};

export const listenToCollection = (db, collection, successCallback, failureCallback) => {
    var unsubscribe = db.collection(collection).where("id","!=","").onSnapshot((docs) => {
        if (!docs === undefined || docs === null){
            throw "Collection not found! Collection: " + collection;
        }
        else{
            var values = [];
            docs.forEach((doc) => {
                values.push(doc.data());
            })
            successCallback(values);
        }
    })
    return unsubscribe;
}; 

export const listenToDocument = (db, collectionName, documentName, successCallback, failureCallback) => {
    var unsubscribe = db.collection(collectionName).doc(documentName).onSnapshot(document => {
        if (document !== undefined){
            console.log("Success!" + document.data())
            successCallback(document.data());
        }
        else{
            console.log("We are undefined baby!");
            if (failureCallback !== undefined){
                failureCallback();
            }
        }
    })
    return unsubscribe;
}

export const listenToDocumentV2 = (db, collectionName, documentName, successCallback, failureCallback) => {
    var unsubscribe = onSnapshot(doc(db, collectionName + "/" + documentName), (document) => {
        if (document !== undefined){
            successCallback(document.data());
        }
        else{
            console.log("We are undefined baby!");
            if (failureCallback !== undefined){
                failureCallback();
            }
        }
    })
    return unsubscribe;
}

export const listenToDocumentV3 = (db, collectionName, documentName, successCallback, failureCallback) => {
    var unsubscribe = onSnapshot(doc(db, collectionName, documentName), (document) => {
        if (document !== undefined){
            successCallback(document.data());
        }
        else{
            console.log("We are undefined baby!");
            if (failureCallback !== undefined){
                failureCallback();
            }
        }
    })
    return unsubscribe;
}

export const listenToDocumentProperty = (db, collection, document, property, successCallback, failureCallback) => {
    var unsubscribe = db.collection(collection).doc(document).onSnapshot((document) => {
        if (!document.exists){
            throw "Document not found! Collection: " + collection + ", Document: " + document;
        }
        else{
            const value = document.data()[property];
            if (successCallback) {
                successCallback(value);
            }
        }
    })
    return unsubscribe;
};

export const listenToNestedDocumentProperty = (db, collection, document, property, successCallback, failureCallback) => {
    var unsubscribe = db.collection(collection).doc(document).onSnapshot((document) => {
        if (!document.exists){
            throw "Document not found! Collection: " + collection + ", Document: " + document;
        }
        else{
            const value = document.data()[property];
            successCallback(value);
        }
    })
    return unsubscribe;
};

export const listenToDocumentPropertyV2 = (db, collection, document, property, successCallback, failureCallback) => {
    var unsubscribe = onSnapshot(doc(db, collection + "/" + document), (document) => {
        if (!document.exists){
            throw "Document not found! Collection: " + collection + ", Document: " + document;
        }
        else{
            const value = document.data()[property];
            successCallback(value);
        }
    })
    return unsubscribe;
};

export const updateDocumentProperty = (db, collection, document, property, value, successCallback, failureCallback) => {
    db.collection(collection).doc(document).update({
        [property]: value
    })
    .then(() => {
        if (successCallback !== undefined) {
            successCallback();
        }
    })
    .catch((error) => {
        if(failureCallback){
            console.log(error);
            failureCallback(error);
        }
    })
};

export const updateDocumentArray = (db, collection, document, array, value, successCallback, failureCallback) => {
    db.collection(collection).doc(document).update({
        [array]: arrayUnion(value)
    })
    .then(() => {
        if (successCallback !== undefined) {
            successCallback();
        }
    })
    .catch((error) => {
        if(failureCallback){
            console.log(error);
            failureCallback(error);
        }
    })
};

export const setDocument = (db, collection, document, data, successCallback, failureCallback) => {
    db.collection(collection).doc(document).set(
        data
    )
    .then(() => {
        if (successCallback !== undefined) {
            successCallback();
        }
    })
    .catch((error) => {
        if(failureCallback){
            console.log(error);
            failureCallback(error);
        }
    })
};

export const deleteDocument = (db, collection, document, successCallback, failureCallback) => {
    db.collection(collection).doc(document).delete().then(() => {
        console.log("Document successfully deleted!");
        if (successCallback !== undefined) {
            successCallback();
        }
    }).catch((error) => {
        console.error("Error removing document: ", error);
        if(failureCallback){
            console.error("Error removing document: ", error);
            failureCallback(error);
        }
    });
};

export const deleteDocumentProperty = (db, collection, document, property, successCallback, failureCallback) => {
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(collection).doc(document)).then((doc) => {
            var data = doc.data();
            delete data[property];
            transaction.update(db.collection(collection).doc(document), data);
        });
    });
};

export const deleteNestedDocumentProperty = (db, collection, document, propertyParent, propertyChild, successCallback, failureCallback) => {
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(collection).doc(document)).then((doc) => {
            var data = doc.data();
            delete data[propertyParent][propertyChild];
            transaction.update(db.collection(collection).doc(document), data);
        });
    });
};

export const deleteNestedDocumentProperty3 = (db, collection, document, propertyParent, propertyChild, propertyGrandchild, propertyGGChild, successCallback, failureCallback) => {
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(collection).doc(document)).then((doc) => {
            var data = doc.data();
            delete data[propertyParent][propertyChild][propertyGrandchild][propertyGGChild];
            transaction.update(db.collection(collection).doc(document), data);
        });
    });
};

export const getUTCTime = (gameName, minutesOffset) => {
    fetch("https://us-central1-escape-room-1980b.cloudfunctions.net/getUTCTime", {
        "method": "GET",
        "headers": {}
    })
    .then(response => response.json())
    .then(response => {startGame(gameName, response.result, minutesOffset);})
    .catch(err => { console.log(err); });
}

export const startGame = (gameNameSuffix, time, minutesOffset) => {
    const gameName = "FinalVerdict" + gameNameSuffix;
    if (minutesOffset === undefined){
        minutesOffset = 0;
    }
    else{
        minutesOffset = minutesOffset * 60 * 1000;
    }
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(gameName).doc("gameSettings")).then((document) => {
            var obj = document.data();
            transaction.update(db.collection(gameName).doc("gameSettings"), {
                ...obj,
                hasGameStarted: true,
                startTime: (time - minutesOffset).toString()
            });
        })
    }).then(() => {
        
    }).catch((error) => {
        
    })
};

export const setTeamBoolean = (information) => {
    var strings = information.split("*");
    var gameNameSuffix = strings[0];
    var teamName = strings[1]; //team name
    var boolean = strings[2]; //variable name
    var value = strings[3]; //"defendant or prosecutor"
    const gameName = "FinalVerdict" + gameNameSuffix;
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(gameName).doc(teamName)).then((document) => {
            var obj = document.data();
            obj[boolean] = (value === "true");
            transaction.update(db.collection(gameName).doc(teamName), {
                ...obj,
            });
        })
    }).then(() => {
        
    }).catch((error) => {
        
    })
};

export const setTeamBooleanArrayValue = (information) => {
    console.log("I'm in here setting TBAV")
    var strings = information.split("*");
    var gameNameSuffix = strings[0];
    var teamName = strings[1]; //team name
    var array = strings[2]; //array name
    var index = strings[3]; //index of value to change"
    var value = strings[4]; //true or false"
    const gameName = "FinalVerdict" + gameNameSuffix;
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(gameName).doc(teamName)).then((document) => {
            var obj = document.data();
            obj[array][index] = (value === "true");
            transaction.update(db.collection(gameName).doc(teamName), {
                ...obj,
            });
        })
    }).then(() => {
        
    }).catch((error) => {
        
    })
};

export const setTeamString = (information) => {
    var strings = information.split("*");
    var gameNameSuffix = strings[0];
    var teamName = strings[1]; //team name
    var propName = strings[2]; //variable name
    var value = strings[3]; //"defendant or prosecutor"
    const gameName = "FinalVerdict" + gameNameSuffix;
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(gameName).doc(teamName)).then((document) => {
            var obj = document.data();
            obj[propName] = (value);
            transaction.update(db.collection(gameName).doc(teamName), {
                ...obj,
            });
        })
    }).then(() => {
        
    }).catch((error) => {
        
    })
};

export const setPlayerString = (information) => {
    var strings = information.split("*");
    var gameNameSuffix = strings[0];
    var teamName = strings[1]; //team name
    var playerName = strings[2]; 
    var propertyName = strings[3]; //key
    var propertyValue = strings[4]; //value
    const gameName = "FinalVerdict" + gameNameSuffix;
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(gameName).doc(teamName)).then((document) => {
            var obj = document.data();
            console.log("Game Name: " + gameName);
            console.log("Team Name: " + teamName);
            console.log("playerName: " + playerName);
            console.log("BEFORE: obj[playerInformation][playerName][propertyName]:" + obj["playerInformation"][playerName][propertyName]);
            obj["playerInformation"][playerName][propertyName] = propertyValue;
            console.log("AFTER: obj[playerSettings][playerName][propertyName]:" + obj["playerInformation"][playerName][propertyName]);
            transaction.update(db.collection(gameName).doc(teamName), {
                ...obj,
            });
        })
    }).then(() => {
        
    }).catch((error) => {
        
    })
};

export const resetGame = (gameNameSuffix) => {
    const gameName = "FinalVerdict" + gameNameSuffix;
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(gameName).doc("gameSettings")).then((document) => {
            var obj = document.data();
            transaction.update(db.collection(gameName).doc("gameSettings"), {
                ...obj,
                hasGameStarted: false,
                defendantSchedule: newGameSettings.defendantSchedule,
                prosecutorSchedule: newGameSettings.prosecutorSchedule,
                startTime: ""
            });
        })
    }).then(() => {
        
    }).catch((error) => {
        
    })
}

export const resetTeam = (gameNameSuffix, teamName) => {
    const gameName = "FinalVerdict" + gameNameSuffix;
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(gameName).doc(teamName)).then((document) => {
            var obj = document.data();
            Object.entries(obj.playerInformation).map(([key,value]) => {
                obj["playerInformation"][key]["vote"] = "";
            });
            
            transaction.update(db.collection(gameName).doc(teamName), {
                ...obj,
                foundFingerprint: false,
                openedComputer: false,
                openedFolder: false,
                defendantVideos: [false, false, false, false, false],
                prosecutorVideos: [false, false, false, false, false],
                notes: [],
            });
        })
    }).then(() => {
        
    }).catch((error) => {
        
    })
}

export const getCollection = (collectionName, callback) => {
    var unsubscribe = db.collection(collectionName).get().then(collection => {
        if (collection !== undefined){
            console.log("Collection: " + collection);
            callback(collection);
        }
        else{
            console.log("We are undefined baby!");
        }
    })
    return unsubscribe;
}

export const createGame = (gameNameSuffix, setMessage) => {
    if (gameNameSuffix === ""){
        setMessage("Game name cannot be blank.")
        return;
    }
    const gameName = "FinalVerdict" + gameNameSuffix;
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(gameName).doc("gameSettings")).then((document) => {
            if (document.exists){
                throw "Document already exists!"
            }
            else
            {
                transaction.update(db.collection("FinalVerdictAdminSettings").doc("gameNames"), {
                    gameNames: firebase.firestore.FieldValue.arrayUnion(gameNameSuffix)
                });
                transaction.set(db.collection(gameName).doc("gameSettings"), {
                    ...newGameSettings
                });
                var playerPass = "";
                for (var i = 0; i < 4; i++){
                    playerPass += (Math.floor(Math.random() * 9) + 1).toString();
                }
                var pass = "";
                for (var i = 0; i < 4; i++){
                    pass += (Math.floor(Math.random() * 9) + 1).toString();
                }
                transaction.set(db.collection(gameName).doc("1"), {
                    passcode: pass,
                    ...newTeam
                });
            }
        })
    }).then(() => {
        setMessage("Game " + gameNameSuffix + " created successfully.");
    }).catch((error) => {
        setMessage("Transaction failed with." + error);
    })
};

export const getGameNames = (callback) => {
    const gamePrefix = "FinalVerdict";
    var unsubscribe = db.collection(gamePrefix + "AdminSettings").doc("gameNames").onSnapshot((document) => {
        if (!document.exists){
            throw "Document not found!";
        }
        else{
            const games = document.data().gameNames;
            callback(games);
        }
    })
    return unsubscribe;
};

export const getTeamNames = (gameSuffix, callback) => {
    const gamePrefix = "FinalVerdict";
    const gameName = gamePrefix + gameSuffix;
    var unsubscribe = db.collection(gameName).doc("gameSettings").onSnapshot((document) => {
        if (!document.exists){
            throw "Document not found!";
        }
        else{
            const teams = document.data().teamNames;
            callback(teams);
        }
    })
    return unsubscribe;
};

export const getGameSettings = (gameSuffix, callback) => {
    const gamePrefix = "FinalVerdict";
    const gameName = gamePrefix + gameSuffix;
    var unsubscribe = db.collection(gameName).doc("gameSettings").onSnapshot((document) => {
        console.log("Triggering Hook!")
        if (!document.exists){
            throw "Document not found!";
        }
        else{
            const gameSettings = document.data();
            callback(gameSettings);
        }
    })
    return unsubscribe;
};

export const getTeam = (gameSuffix, teamName, callback, callback2) => {
    const gamePrefix = "FinalVerdict";
    const gameName = gamePrefix + gameSuffix;
    var unsubscribe = db.collection(gameName).doc(teamName).onSnapshot((document) => {
        if (!document.exists){
            throw "Document not found!";
        }
        else{
            const team = document.data();
            callback(team);
            if (team.playerInformation){
                callback2(team.playerInformation)
            }
        }
    })
    return unsubscribe;
};

export const deleteGame = (gameNameSuffix) => {
    const gameName = "FinalVerdict" + gameNameSuffix;
    return db.runTransaction((transaction) => {
        transaction.update(db.collection("FinalVerdictAdminSettings").doc("gameNames"), {
            gameNames: firebase.firestore.FieldValue.arrayRemove(gameNameSuffix)
        })
        transaction.delete(db.collection(gameName).doc("gameSettings"));
        return Promise.resolve();
    }).then(() => {
        console.log("Transaction success!");
    }).catch((error) => {
        console.log("Transaction failed with." + error);
    })
};

export const addTeam = (gameSuffix, teamName, callbackMessage) => {
    if (teamName === ""){
        callbackMessage("Error: Team name cannot be blank.");
        return;
    }
    const gameName = "FinalVerdict" + gameSuffix;
    var teamPass = "";
    for (var i = 0; i < 4; i++){
        teamPass += (Math.floor(Math.random() * 9) + 1).toString();
    }
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(gameName).doc(teamName)).then(doc => {
            if (doc.data() !== undefined){
                return Promise.reject(new Error("Name \"" + teamName + "\" already exists."));
            }
            else{
                transaction.update(db.collection(gameName).doc("gameSettings"), {
                    ["teamNames"]: firebase.firestore.FieldValue.arrayUnion(teamName)
                });
                transaction.set(db.collection(gameName).doc(teamName), {
                    passcode: teamPass,
                    ...newTeam
                })
            }
        });
    }).then(() => {
        callbackMessage("Success: Team \"" + teamName + "\" created.");
    }).catch((error) => {
        callbackMessage(error.toString());
    })
}

export const renameTeam = (gameSuffix, teamName, newName, teamData, callbackMessage) => {
    if (newName === ""){
        callbackMessage("Error: Team name cannot be blank.");
        return;
    }
    const gameName = "FinalVerdict" + gameSuffix;
    var teamPass = "";
    for (var i = 0; i < 4; i++){
        teamPass += (Math.floor(Math.random() * 9) + 1).toString();
    }
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(gameName).doc(newName)).then(doc => {
            if (doc.data() !== undefined){
                return Promise.reject(new Error("Name \"" + newName + "\" already exists."));
            }
            else{
                transaction.update(db.collection(gameName).doc("gameSettings"), {
                    ["teamNames"]: firebase.firestore.FieldValue.arrayRemove(teamName)
                });
                transaction.update(db.collection(gameName).doc("gameSettings"), {
                    ["teamNames"]: firebase.firestore.FieldValue.arrayUnion(newName)
                });
                transaction.set(db.collection(gameName).doc(newName), teamData)
                transaction.delete(db.collection(gameName).doc(teamName));
            }
        })
    }).then(() => {
        window.location.reload();
    }).catch((error) => {
        callbackMessage(error.toString());
    })
}

export const changeTeamPasscode = (gameSuffix, teamName) => {
    const gameName = "FinalVerdict" + gameSuffix;
    var teamPass = "";
    for (var i = 0; i < 4; i++){
        teamPass += (Math.floor(Math.random() * 9) + 1).toString();
    }
    return db.runTransaction((transaction) => {
        transaction.update(db.collection(gameName).doc(teamName), {
            passcode: teamPass
        })
        return Promise.resolve();
    }).then(() => {
        
    }).catch((error) => {
        console.log("Transaction failed with." + error);
    })
};

export const deleteTeam = (gameSuffix, teamName) => {
    const gameName = "FinalVerdict" + gameSuffix;
    return db.runTransaction((transaction) => {
        transaction.update(db.collection(gameName).doc("gameSettings"), {
            teamNames: firebase.firestore.FieldValue.arrayRemove(teamName)
        })
        transaction.delete(db.collection(gameName).doc(teamName));
        return Promise.resolve();
    }).then(() => {
        console.log("Transaction success!");
    }).catch((error) => {
        console.log("Transaction failed with." + error);
    })
};

export const addPlayer = (gameSuffix, teamName, playerName, messageCallback) => {
    if (playerName === ""){
        messageCallback("Error: Name cannot be empty.");
        return;
    }
    const gameName = "FinalVerdict" + gameSuffix;
    var playerPass = "";
    for (var i = 0; i < 4; i++){
        playerPass += (Math.floor(Math.random() * 9) + 1).toString();
    }
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(gameName).doc(teamName)).then(doc => {
            console.log(doc.data().toString());
            if (doc.data()["playerInformation"] !== undefined && doc.data()["playerInformation"][playerName] !== undefined){
                return Promise.reject(new Error("Name \"" + playerName + "\" already exists."));
            }
            else if (doc.data()["playerInformation"] !== undefined){
                transaction.update(db.collection(gameName).doc(teamName), {
                    ["playerInformation." + playerName] : {
                        passcode: playerPass,
                        vote: ""
                    }
                })
            }
            else{
                transaction.update(db.collection(gameName).doc(teamName), {
                    ["playerInformation"] : {
                        [playerName]: {
                            passcode: playerPass,
                            vote: ""
                        }
                    }
                })
            }
        });
    }).then(() => {
        messageCallback("Success: Player \"" + playerName + "\" created.");
    }).catch((error) => {
        messageCallback(error.toString());
    })
}

export const deletePlayer = (gameName, teamName, playerName) => {
    return db.runTransaction((transaction) => {
        transaction.update(db.collection(gameName).doc(teamName), {
            ["playerInformation." + playerName]: firebase.firestore.FieldValue.delete()
        });
        return Promise.resolve();
    }).then(() => {
        console.log("Transaction success!");
    }).catch((error) => {
        console.log("Transaction failed with." + error);
    })
}

export const renamePlayer = (gameSuffix, teamName, playerName, playerPasscode, newName, messageCallback) => {
    if (newName === ""){
        messageCallback("Error: Name cannot be blank.");
        return;
    }
    const gameName = "FinalVerdict" + gameSuffix;
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(gameName).doc(teamName)).then(doc => {
            if (doc.data().playerInformation[newName] !== undefined){
                return Promise.reject(new Error("Name \"" + newName + "\" already exists."));
            }
            else{
                transaction.update(db.collection(gameName).doc(teamName), {
                    ["playerInformation." + playerName]: firebase.firestore.FieldValue.delete()
                });
                transaction.update(db.collection(gameName).doc(teamName), {
                    ["playerInformation." + newName]: {
                        passcode: playerPasscode,
                        vote: ""
                    }
                });
            }
        })
    }).then(() => {
        //As we render a new player object here, harder to display success message.
    }).catch((error) => {
        if (error.toString() !== ""){
            messageCallback(error.toString());
        } 
    })
}

export const changePlayerPasscode = (gameSuffix, teamName, playerName) => {
    const gameName = "FinalVerdict" + gameSuffix;
    var playerPass = "";
    for (var i = 0; i < 4; i++){
        playerPass += (Math.floor(Math.random() * 9) + 1).toString();
    }
    return db.runTransaction((transaction) => {
        transaction.update(db.collection(gameName).doc(teamName), {
            ["playerInformation." + playerName + "." + "passcode"]: playerPass
        })
        return Promise.resolve();
    }).then(() => {
        console.log("Transaction success!");
    }).catch((error) => {
        console.log("Transaction failed with." + error);
    })
};

export const UploadImageToStorage = (imgSource, path, child, successcallback, storage, ref, uploadBytesResumable, getDownloadURL, id="Null") => {
    console.log("Received image to upload");
    const metadata = {
        contentType: 'image/png'
      };

        const storageRef = ref(storage, path + child);
        const uploadTask = uploadBytesResumable(storageRef, imgSource, metadata);

      console.log(storageRef);
      console.log(storage);

// Listen for state changes, errors, and completion of the upload.
uploadTask.on('state_changed',
  (snapshot) => {
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log('Upload is ' + progress + '% done');
    switch (snapshot.state) {
      case 'paused':
        console.log('Upload is paused');
        break;
      case 'running':
        console.log('Upload is running');
        break;
    }
  }, 
  (error) => {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    switch (error.code) {
      case 'storage/unauthorized':
        console.log("NOT ALLOWED");
        console.log(error);
        break;
      case 'storage/canceled':
        // User canceled the upload
        break;

      // ...

      case 'storage/unknown':
        // Unknown error occurred, inspect error.serverResponse
        break;
    }
  }, 
  () => {
    // Upload completed successfully, now we can get the download URL
    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      console.log('File available at', downloadURL);
      successcallback(downloadURL, id);
    });
  }
);
};

export const UploadImageToStorageV2 = (imgSource, path, child, successcallback, storage, ref, uploadBytesResumable, getDownloadURL, id="Null") => {
    const metadata = {
        contentType: 'image/png'
      };

        const storageRef = ref(storage, path + child);
        const uploadTask = uploadBytesResumable(storageRef, imgSource, metadata);

      console.log(storageRef);
      console.log(storage);

// Listen for state changes, errors, and completion of the upload.
uploadTask.on('state_changed',
  (snapshot) => {
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log('Upload is ' + progress + '% done');
    switch (snapshot.state) {
      case 'paused':
        console.log('Upload is paused');
        break;
      case 'running':
        console.log('Upload is running');
        break;
    }
  }, 
  (error) => {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    switch (error.code) {
      case 'storage/unauthorized':
        console.log("NOT ALLOWED");
        console.log(error);
        break;
      case 'storage/canceled':
        // User canceled the upload
        console.log("Cancelled upload");
        console.log(error);
        break;

      // ...

      case 'storage/unknown':
        // Unknown error occurred, inspect error.serverResponse
        console.log("Strange error");
        console.log(error);
        break;
    }
  }, 
  () => {
    // Upload completed successfully, now we can get the download URL
    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      console.log('File available at', downloadURL);
      successcallback(downloadURL, id);
    });
  }
);
};