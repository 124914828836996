import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  position: relative;
  width: 300px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #007bff;
  border-radius: 5px;
`;

const Dropdown = styled.div`
  position: absolute;
  width: 100%;
  border: 1px solid #007bff;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  z-index: 1000;
`;

const Option = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const InputDropdown = ({ options, onSelect }) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeOptionIndex, setActiveOptionIndex] = useState(-1);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const activeOptionRef = useRef(null);

  const filterOptions = (inputText) => {
    const filtered = options.filter(option =>
      option.toLowerCase().includes(inputText.toLowerCase())
    );
    setFilteredOptions(filtered);
    setInputValue(inputText);
    setShowDropdown(true);
    setActiveOptionIndex(-1);
  };

  const handleInputChange = (event) => {
    filterOptions(event.target.value);
    if (event.target.value === '') {
      onSelect && onSelect(''); // Clear the filter in the parent component
    }
  };

  const handleSelectOption = (option) => {
    setInputValue(option);
    setShowDropdown(false);
    setActiveOptionIndex(-1);
    onSelect && onSelect(option);
    // Optionally, focus the input after selection for better UX
    inputRef.current.focus();
  };

  const handleKeyDown = (event) => {
    // Arrow Down
    if (event.keyCode === 40 && activeOptionIndex < filteredOptions.length - 1) {
      setActiveOptionIndex(activeOptionIndex + 1);
    }
    // Arrow Up
    else if (event.keyCode === 38 && activeOptionIndex > 0) {
      setActiveOptionIndex(activeOptionIndex - 1);
    }
    // Enter or Space
    else if ((event.keyCode === 13 || event.keyCode === 32) && activeOptionIndex !== -1) {
      event.preventDefault();
      handleSelectOption(filteredOptions[activeOptionIndex]);
    }
  };

  const handleBlur = () => {
    // Delay hiding dropdown to allow click event to fire on options
    setTimeout(() => {
      setShowDropdown(false);
    }, 100);
  };

  useEffect(() => {
    if (activeOptionIndex !== -1 && activeOptionRef.current && dropdownRef.current) {
      const dropdown = dropdownRef.current;
      const activeOption = activeOptionRef.current;
      
      const dropdownTop = dropdown.scrollTop;
      const dropdownBottom = dropdownTop + dropdown.offsetHeight;
      const optionTop = activeOption.offsetTop;
      const optionBottom = optionTop + activeOption.offsetHeight;
      
      if (optionBottom > dropdownBottom) {
        dropdown.scrollTop = optionBottom - dropdown.offsetHeight;
      } else if (optionTop < dropdownTop) {
        dropdown.scrollTop = optionTop;
      }
    }
  }, [activeOptionIndex, filteredOptions.length]);

  return (
    <Container>
      <Input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onFocus={() => filteredOptions.length && setShowDropdown(true)}
      />
      {showDropdown && (
        <Dropdown ref={dropdownRef}>
          {filteredOptions.map((option, index) => (
            <Option
              key={option}
              ref={index === activeOptionIndex ? activeOptionRef : null}
              onClick={() => handleSelectOption(option)}
              onMouseEnter={() => setActiveOptionIndex(index)}
              style={{
                backgroundColor: index === activeOptionIndex ? '#f0f0f0' : '',
              }}
            >
              {option}
            </Option>
          ))}
        </Dropdown>
      )}
    </Container>
  );
};

export default InputDropdown;
