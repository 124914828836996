import React, {useState} from 'react';
import { Row, Col, Collapse } from 'reactstrap';
import SettingsInput from '../budget-tool/input-components/settings-input';
import { CollapseButton, ButtonContainer } from '../../../utils/budget-tool-specific';

const SettingsTransportation = (props) => {

    const [opened, setOpened] = useState(false);
    const normalStyle = {
        transition: '1s, transform 0.5s',
    };
      
    const transformedStyle = {
        transition: '1s, transform 0.5s',
        transform: 'rotate(180deg)',
    };

    return ( 
        <React.Fragment>
            <div style={{display: "flex", textAlign: "center", alignItems: "center", verticalAlign: "middle"}}>
                <ButtonContainer onClick={() => {setOpened(!opened)}}>
                    <CollapseButton icon={['fas', 'chevron-down']} style={opened ? transformedStyle : normalStyle}/> 
                </ButtonContainer>
                <h4>Transportation Settings</h4>
            </div>
            <Collapse isOpen={opened}>
                {props.data !== undefined && Object.entries(props.data).map((el, index) => {
                    return(
                        <div key={index}>
                            <h6>{el[0]}</h6>
                            {Object.entries(el[1]).map((e,i) => {
                                return(
                                    <div key={i}>
                                        {Object.entries(e[1]).sort().map((x,y) => {
                                            return (
                                                <Row key={y}>
                                                    <Col>
                                                        <p>{x[0]}</p>
                                                    </Col>
                                                    <Col>
                                                    <SettingsInput 
                                                        type="number"
                                                        setArea={props.setArea}
                                                        data={props.data}
                                                        area={"transportationRow"}
                                                        indices={[el[0].toString(), e[0].toString(), x[0].toString()]}
                                                        />
                                                    </Col>
                                                </Row>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </Collapse>
        </React.Fragment>
    )
}
 
export default SettingsTransportation;
