import React from "react";
import CostRow from "./exp-cost-row";
import { calculateCostAccommodation, exchangeRate } from "./cost-helper";

const AccommodationRow = ({guideData, expData}) => {
    return (
        <React.Fragment>
            <CostRow title="Accommodation" 
                    icon='bed'
                    single
                    currency={guideData.currency.value.slice(3)} 
                    costPer={(expData, Number(calculateCostAccommodation(guideData, expData)/guideData.tripLength.value))}
                    perLabel="per night"
                    num={guideData.tripLength.value}
                    type="nights"
                    subtotal={calculateCostAccommodation(guideData, expData)}
                    attendees={guideData.attendees.value}
                    //message={"Let's pop a message here."}
                    accommodation={expData}
                    exchangeRate={exchangeRate}
            />      
        </React.Fragment>
    )
}

export default AccommodationRow;