import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table } from 'reactstrap';
import axios from 'axios';
import styled from 'styled-components';
import InputDropdown from '../../form-components/input-dropdown';

const AssistantData = (props) => {

    const [csvData, setCsvData] = useState([]);
    const [headersData, setHeadersData] = useState([]);
    const url = "https://docs.google.com/spreadsheets/d/e/2PACX-1vQZpmCQWR1rlhBlHDbxy0U5EGqy83yS_KBMc2I_xcbK5BPj9Jb78mAcAluRjXQlad7cWBqCJ6ninV_S/pub?output=csv";
    const [regions, setRegions] = useState([]);
    const [countries, setCountries] = useState([]);
    const [stateCities, setStateCities] = useState([]);
    const [propertyNames, setPropertyNames] = useState([]);
    const [websites, setWebsites] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [meetingSpaces, setMeetingSpaces] = useState([]);
    const [distances, setDistances] = useState([]);

    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    
    const [selectedStateCity, setSelectedStateCity] = useState('');
    const [selectedPropertyName, setSelectedPropertyName] = useState('');
    

    const [dynamicCountries, setDynamicCountries] = useState([]);
    const [dynamicStateCities, setDynamicStateCities] = useState([]);
    const [dynamicPropertyNames, setDynamicPropertyNames] = useState([]);

    const handleRegionSelect = (region) => {
        setSelectedRegion(region);
        handleCountrySelect(region, '');
      
        const filteredCountries = csvData
            .filter(item => !region || item.Region === region)
            .map(item => item.Country);
        let x = [...new Set(filteredCountries)];
        setDynamicCountries(x); 
    };

    const handleCountrySelect = (region, country) => {
        setSelectedCountry(country);
        handleStateCitySelect(region, country, '');
      
        const filteredStateCities = csvData
            .filter(item => !region || item.Region === region)
            .filter(item => !country || item.Country === country)
            .map(item => item["State/ City"]);
        let x = [...new Set(filteredStateCities)];
        setDynamicStateCities(x);
    };
      
    const handleStateCitySelect = (region, country, stateCity) => {
        setSelectedStateCity(stateCity);
        handlePropertyNameSelect(region, country, stateCity, '');
        
        const filteredPropertyNames = csvData
            .filter(item => !region || item.Region === region)
            .filter(item => !country || item.Country === country)
            .filter(item => !stateCity || item["State/ City"] === stateCity)
            .map(item => item["Property Name"]);
        let x = [...new Set(filteredPropertyNames)];
        setDynamicPropertyNames(x);
    };

    const handlePropertyNameSelect = (region, country, stateCity, propertyName) => {
        setSelectedPropertyName(propertyName);
    };

    const getFilteredData = () => {
        return csvData.filter(item => {
            return (selectedRegion ? item.Region === selectedRegion : true) &&
                   (selectedCountry ? item.Country === selectedCountry : true) &&
                   (selectedStateCity ? item["State/ City"] === selectedStateCity : true) &&
                   (selectedPropertyName ? item["Property Name"] === selectedPropertyName : true);
        });
    }

    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        setFilteredData(getFilteredData());
    }, [csvData, selectedRegion, selectedCountry, selectedStateCity, selectedPropertyName]); 


    useEffect(() => {
        fetchCSVData();    // Fetch the CSV data when the component mounts
    }, []); // The empty array ensures that this effect runs only once, like componentDidMount

    const fetchCSVData = () => {
    const csvUrl = url; // Replace with your Google Sheets CSV file URL

        axios.get(csvUrl)    // Use Axios to fetch the CSV data
            .then((response) => {
                const parsedCsvData = parseCSV(response.data);        // Parse the CSV data into an array of objects
                setCsvData(parsedCsvData);        // Set the fetched data in the component's state
                console.log(parsedCsvData);        // Now you can work with 'csvData' in your component's state.
            })
            .catch((error) => {
                console.error('Error fetching CSV data:', error);
            });
    }

    function parseCSV(csvText) {
        const rows = csvText.split(/\r?\n/);        // Use a regular expression to split the CSV text into rows while handling '\r'
        const headers = rows[0].split(',');        // Extract headers (assumes the first row is the header row)
        setHeadersData(headers);
        const data = [];        // Initialize an array to store the parsed data
        let tempRegions = [];
        let tempCountries = [];
        let tempStateCities = [];
        let tempPropertyNames = [];
        let tempWebsites = [];
        let tempRooms = [];
        let tempMeetingSpaces = [];
        let tempDistances = [];
        for (let i = 1; i < rows.length; i++) {
            const rowData = rows[i].split(',');          // Use the regular expression to split the row while handling '\r'
            const rowObject = {};
            for (let j = 0; j < headers.length; j++) {
                rowObject[headers[j]] = rowData[j];
                if(headers[j] === "Region") {
                    if (!tempRegions.includes(rowData[j])) {
                        tempRegions.push(rowData[j]);
                    }
                }
                if(headers[j] === "Country") {
                    if (!tempCountries.includes(rowData[j])) {
                        tempCountries.push(rowData[j]);
                    }
                }
                if(headers[j] === "State/ City") {
                    if (!tempStateCities.includes(rowData[j])) {
                        tempStateCities.push(rowData[j]);
                    }
                }
                if(headers[j] === "Property Name") {
                    if (!tempPropertyNames.includes(rowData[j])) {
                        tempPropertyNames.push(rowData[j]);
                    }
                }
                if(headers[j] === "Website") {
                    if (!tempWebsites.includes(rowData[j])) {
                        tempWebsites.push(rowData[j]);
                    }
                }
                if(headers[j] === "# of Rooms") {
                    if (!tempRooms.includes(rowData[j])) {
                        tempRooms.push(rowData[j]);
                    }
                }
                if(headers[j] === "# Meeting Spaces") {
                    if (!tempMeetingSpaces.includes(rowData[j])) {
                        tempMeetingSpaces.push(rowData[j]);
                    }
                }
                if(headers[j] === "Distance to Airport") {
                    if (!tempDistances.includes(rowData[j])) {
                        tempDistances.push(rowData[j]);
                    }
                }
            }
            data.push(rowObject);
        }
        setRegions(tempRegions);
        
        setCountries(tempCountries);
        setDynamicCountries(tempCountries);

        setStateCities(tempStateCities);
        setDynamicStateCities(tempStateCities);

        setPropertyNames(tempPropertyNames);
        setDynamicPropertyNames(tempPropertyNames);

        setWebsites(tempWebsites);
        setRooms(tempRooms);
        setMeetingSpaces(tempMeetingSpaces);
        setDistances(tempDistances);
        return data;
    }

    return ( 
        <React.Fragment>
            <StyledContainer>
                <Row>
                    <Col>
                        <p>Region</p>
                    </Col>
                    <Col>
                        {regions.length > 0 && <InputDropdown options={regions} onSelect={(e) => handleRegionSelect(e)} />}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Country</p>
                    </Col>
                    <Col>
                        {countries.length > 0 && dynamicCountries.length > 0 && <InputDropdown key={dynamicCountries[0] + dynamicCountries.length} options={dynamicCountries} onSelect={(e) => handleCountrySelect(selectedRegion, e)} />}
                    </Col>
                </Row>
                
                <Row>
                    <Col>
                        <p>State / City</p>
                    </Col>
                    <Col>
                        {stateCities.length > 0 && dynamicStateCities.length > 0 && <InputDropdown key={dynamicStateCities[0] + dynamicStateCities.length} options={dynamicStateCities} onSelect={(e) => handleStateCitySelect(selectedRegion, selectedCountry, e)} />}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Property Names</p>
                    </Col>
                    <Col>
                        {propertyNames.length > 0 && dynamicPropertyNames.length > 0 && <InputDropdown key={dynamicPropertyNames[0] + dynamicPropertyNames.length} options={dynamicPropertyNames} onSelect={(e) => handlePropertyNameSelect(selectedRegion, selectedCountry, selectedStateCity, e)} />}
                    </Col>
                </Row>
                
                <Row>
                    <StyledTable>
                    <tbody>
                    
                    <tr>
                    {headersData !== undefined && headersData.length > 0 && headersData.map((el, index) => {
                        return (
                            <th key={index}>
                            {el}
                            </th>
                            )
                        })}
                        </tr>
                        {filteredData !== undefined && filteredData.length > 0 && filteredData.map((el, index) => {
                            return(
                                <tr key={index}>
                                <td>{el.Region}</td>
                                <td>{el.Country}</td>
                                <td>{el["State/ City"]}</td>
                                <td>{el["Property Name"]}</td>
                                <td><a href={el["Website"]}>Website</a></td>
                                <td>{el["# of Rooms"]}</td>
                                <td>{el["# Meeting Spaces"]}</td>
                                    <td>{el["Distance to Airport"]}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </StyledTable>
                </Row>
            </StyledContainer>
        </React.Fragment>
    )
}
 
export default AssistantData;

const StyledContainer = styled(Container)`
    margin-left: 0px;
;`

const StyledTable = styled(Table)`

`;
