import React from "react";
import styled from "styled-components";
import { Row, Col } from "reactstrap";
import { HeaderTitle, 
    Spacer,} from "../../../utils/budget-tool-specific";
import PageTemplate from "../page-template";
import ExperienceGuideList from "./experiences-list"

const ExperiencesComponent = (props) => {

    return(
        <React.Fragment>
            <Spacer height="60px" id="height-spacer"/>
            <Row>
                <Col>
                    <HeaderTitle>
                        Welcome
                    </HeaderTitle>
                </Col>
            </Row>
            <ExperienceGuideList client={props.client}/>
        </React.Fragment>
    )
}

const Experiences = (props) => {

    if (props.collapsed) { 
        return <ExperiencesComponent/>
    } else {
        return <PageTemplate component={<ExperiencesComponent/>} client={props.client}/> 
    }
} 

export default Experiences;