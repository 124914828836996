import React from 'react';
import { FormGroup, InputGroup, Input, Row, Col, Button } from 'reactstrap';
import { GreyLabel, UnderlineInput, SmallFormFeedback, StyledInputGroupText, Colors, HighlightedInputGroupText } from '../../../../utils/budget-tool-specific';
import styled from 'styled-components';

const FormInputPP = (props) => {

    const handleInputChange = (value, type) => {
        if (type === "value") {
            const newData = {...props.data};
            newData[props.id].value.value = value;
            props.setData(newData);
        } else if (type === "pp") {
            /*const newData = {...props.data};
            if (newData[props.id].value.pp === "per day") {
                newData[props.id].value.pp = "per person";
            } else if (newData[props.id].value.pp === "per person") {
                newData[props.id].value.pp = "per day";
            }
            props.setData(newData);*/
        }
    }

    return (
        <React.Fragment>       
            <FormGroup>
                <GreyLabel for={props.id}>{props.title}</GreyLabel>
                <InputGroup>
                    {props.currency && <HighlightedInputGroupText invalid={props.data[props.id].invalid}>{props.currency}</HighlightedInputGroupText> }
                    <HighlightedInput invalid={props.data[props.id].invalid} 
                                    type={props.type} 
                                    value={props.data[props.id].value.value} 
                                    onChange={e => handleInputChange(e.target.value, "value")}
                                    fontSize={props.fontSize ? props.fontSize : "16px"}> 
                        {props.options}
                    </HighlightedInput>
                    <HighlightedInputGroupText>
                        per meeting
                                {/*<CheckboxButton onClick={x => handleInputChange(props.data[props.id].value.pp, "pp")}>
                                    per meeting
                                </CheckboxButton>*/}
                            </HighlightedInputGroupText>
                    <SmallFormFeedback>Required</SmallFormFeedback>
                </InputGroup>
            </FormGroup>
        </React.Fragment>
       
        

    )
} 

export default FormInputPP;

const CheckboxButton = styled(Button)`
    height: 100%;
    margin: 0px;
    border: none;
    background-color: ${Colors.primaryAccent};
    color: black;
    &: focus{
        background-color: ${Colors.primaryAccent};
        color: black;
    }
    &: selected{
        background-color: ${Colors.primaryDark};
        color: black;
    }
`

export const HighlightedInput = styled(Input)`
    font-size: ${props => props.fontSize ? props.fontSize : "16px"};
    margin: ${props => props.fontSize === "16px" ? "" : "0px 0px"};
    border: none;
    border-bottom: 1px solid ${Colors.primaryLight};
    border-radius: 0px;
    background-color: ${Colors.primaryLight};
    color: ${Colors.primaryDark};
    padding: ${props => props.fontSize === "16px" ? "5px 5px" : ""};
    &:hover {
        border-bottom: 1px solid ${Colors.primaryAccent};
    }
`