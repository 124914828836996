import React from 'react';

export const TripLengthOptions = () => {

    return (
        <React.Fragment>
            <option style={{display: "none"}}/> 
            <option>3 days / 2 nights</option>
            <option>4 days / 3 nights</option>
            <option>5 days / 4 nights</option>
            <option>6 days / 5 nights</option>
            <option>7 days / 6 nights</option>
            <option>8 days / 7 nights</option>
        </React.Fragment>
    )
}

export const ExperienceTypeOptions = () => {

    return(
        <React.Fragment>
            <option style={{display: "none"}}/> 
            <option>3 stars</option>
            <option>4 stars</option>
            <option>5 stars</option>
        </React.Fragment>
    )
}

export const WorkBalanceOptions = () => {

    return(
        <React.Fragment>
            <option style={{display: "none"}}/> 
            <option>All work</option>
            <option>Mostly work</option>
            <option>Mix</option>
            <option>Mostly play</option>
            <option>All play</option>
        </React.Fragment>
    )
}

export const AlcoholOptions = () => {

    return (
        <React.Fragment>
            <option style={{display: "none"}}/> 
            <option>All nights</option>
            <option>Most nights</option>
            <option>Some nights</option>
            <option>No</option>
        </React.Fragment>
    )
}