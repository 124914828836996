import axios from 'axios';

export const getFlightDetails = async (data, i, successCallback, failureCallback) => {
    let search = data.flights.value[i];
    let numPeople = 8;
    if (search.numPeople < 8){
        numPeople = search.numPeople;
    }
   
    //TODO: Need confirmation on what's happening specifically with multiple people travelling.
    numPeople = 1;

    try {
        axios 
        .get (`https://api.flightapi.io/roundtrip/63c72d5bfd4bac12b478d7cc/${search.originAirport}/${data.destination.value}/${data.departureDate.value}/${data.returnDate.value}/${numPeople}/0/0/Economy/${data.currency.value.substring(0,3)}`)
        .then((resp) => {
            if (successCallback) {
                successCallback(resp);
            }
            axios 
            .get (`https://api.flightapi.io/roundtrip/63c72d5bfd4bac12b478d7cc/${search.originAirport}/${data.destination.value}/${data.departureDate.value}/${data.returnDate.value}/${numPeople}/0/0/Economy/${data.currency.value.substring(0,3)}`)
            .then((resp) => {
                if (successCallback) {
                    successCallback(resp);
                }
            })
            .catch((err) => {
                failureCallback(err);
            })
        })
    } catch (err) {
        if (failureCallback) {
            failureCallback(err);
        }
    }
}

export const selectBestFlights = (data, num = 5, numPeople) => {
    let fares = data.data.itineraries;
    let direct = [];
    let onestop = [];
    let twoplus = [];
    let returnFares = [];
    fares.map((fare) => {
        let legs = [];
        if (fare.cheapest_price.amount !== undefined)
        {
            let people = 8;
            if (numPeople < 8){
                people = numPeople;
            }
            //fare.cheapest_price.amount = fare.cheapest_price.amount/people;
            legs.push(data.data.legs.find((leg) => leg.id === fare.leg_ids[0]));
            legs.push(data.data.legs.find((leg) => leg.id === fare.leg_ids[1]));
            if (legs[0].stop_count === 0 && legs[1].stop_count === 0){
                direct.push({"fare": fare, "legs": legs});
            }
        }
    })
    direct.sort((a, b) => (a.fare.cheapest_price.amount > b.fare.cheapest_price.amount) ? 1 : -1);
    returnFares.push(...(direct.slice(0, num)));

    if (returnFares.length >= num){
        return returnFares;
    }

    fares.map((fare) => {
        let legs = [];
        if (fare.cheapest_price.amount !== undefined)
        {
            let people = 8;
            if (numPeople < 8){
                people = numPeople;
            }
            //fare.cheapest_price.amount = fare.cheapest_price.amount/people;
            legs.push(data.data.legs.find((leg) => leg.id === fare.leg_ids[0]));
            legs.push(data.data.legs.find((leg) => leg.id === fare.leg_ids[1]));
            if (legs[0].stop_count === 1 && legs[1].stop_count === 1){
                onestop.push({"fare": fare, "legs": legs});
            }
        }
    })

    onestop.sort((a, b) => (a.fare.cheapest_price.amount > b.fare.cheapest_price.amount) ? 1 : -1);
    returnFares.push(...(onestop.slice(0, num-returnFares.length)));

    if (returnFares.length >= num){
        return returnFares;
    }

    fares.map((fare) => {
        let legs = [];
        if (fare.cheapest_price.amount !== undefined)
        {
            let people = 8;
            if (numPeople < 8){
                people = numPeople;
            }
            //fare.cheapest_price.amount = fare.cheapest_price.amount/people;
            legs.push(data.data.legs.find((leg) => leg.id === fare.leg_ids[0]));
            legs.push(data.data.legs.find((leg) => leg.id === fare.leg_ids[1]));
            if (legs[0].stop_count > 1 && legs[1].stop_count > 1){
                twoplus.push({"fare": fare, "legs": legs});
            }
        }
    })

    twoplus.sort((a, b) => (a.fare.cheapest_price.amount > b.fare.cheapest_price.amount) ? 1 : -1);
    returnFares.push(...(twoplus.slice(0, num-returnFares.length)));

    return returnFares;

}

export const calculatePeople = (obj) => {
    let sum = 0;
    Object.entries(obj).map((el, index) => {
        sum += parseInt(el[1].numPeople);
    })
    if (!sum) {
        sum = "Invalid input";
    }
    return sum;
}

export const calculatePriceAverage = (fares) => {
    let priceAverage = 0;
    for (let i = 0; i < fares.length; i++){
        priceAverage += fares[i].fare.cheapest_price.amount;
    }
    priceAverage = Math.trunc(priceAverage / fares.length);
    return priceAverage;
}

export const calculatePriceMin = (flights) => {
    let priceMin = 0;
    priceMin = flights[0].fare.cheapest_price.amount;
    for (let i = 0; i < flights.length; i++){
        if (flights[i].fare.cheapest_price.amount < priceMin){
            priceMin = flights[i].fare.cheapest_price.amount;
        }
    }
    return Math.trunc(priceMin);
}

export const calculatePriceMax = (flights) => {
    let priceMax = 0;
    priceMax = flights[flights.length-1].fare.cheapest_price.amount;
    return Math.trunc(priceMax);
}

export const calculateDurationAverage = (flights, segments) => {
    let duration = 0;

    for (let i = 0; i < flights.length; i++){
        for (let j = 0; j < flights[i].legs[0].segment_ids.length; j++){
            duration += segments.find((seg) => seg.id === flights[i].legs[0].segment_ids[j]).duration;
        }
        for (let k = 0; k < flights[i].legs[1].segment_ids.length; k++){
            duration += segments.find((seg) => seg.id === flights[i].legs[1].segment_ids[k]).duration;
        }
    }

    duration =  duration / (flights.length * 2);
    duration = Math.trunc(duration);
    return Math.trunc(duration);
}

export const calculateDurationMax = (flights, segments) => {
    let duration = -1;
    for (let i = 0; i < flights.length; i++){
        let tempDuration = 0;
        for (let j = 0; j < flights[i].legs[0].segment_ids.length; j++){
            tempDuration += segments.find((seg) => seg.id === flights[i].legs[0].segment_ids[j]).duration;
        }
        for (let k = 0; k < flights[i].legs[1].segment_ids.length; k++){
            tempDuration += segments.find((seg) => seg.id === flights[i].legs[1].segment_ids[k]).duration;
        }
        if ((tempDuration) > duration || duration === -1){
            duration = tempDuration;
        }
    }
    return Math.trunc(duration / 2);
}

export const calculateDurationMin = (flights, segments) => {
    let duration = -1;
    for (let i = 0; i < flights.length; i++){
        let tempDuration = 0;
        for (let j = 0; j < flights[i].legs[0].segment_ids.length; j++){
            tempDuration += segments.find((seg) => seg.id === flights[i].legs[0].segment_ids[j]).duration;
        }
        for (let k = 0; k < flights[i].legs[1].segment_ids.length; k++){
            tempDuration += segments.find((seg) => seg.id === flights[i].legs[1].segment_ids[k]).duration;
        }
        if ((tempDuration) < duration || duration === -1){
            duration = tempDuration;
        }
    }
    return Math.trunc(duration / 2);
}

export const calculateStopsAverage = (flights) => {
    let stops = 0;

    for (let i = 0; i < flights.length; i++){
        let leg1 = (flights[i].legs[0]);
        let leg2 = (flights[i].legs[1]);
        stops += leg1.stop_count;
        stops += leg2.stop_count;
    }
    stops = stops / (flights.length * 2);
    stops =  Math.round(stops * 10) / 10;
    return stops;
}

export const calculateStopsMin = (flights) => {
    let stops = 3;

    for (let i = 0; i < flights.length; i++){
        let leg1 = (flights[i].legs[0]);
        let leg2 = (flights[i].legs[1]);
        if (leg1.stop_count >= 2 && stops >= 2){
            stops = 2;
        }
        else if (leg1.stop_count === 1 && stops >= 1){
            stops = 1;
        }
        else{
            stops = 0;
        }

        if (leg2.stop_count >= 2  && stops >= 2){
            stops = 2;
        }
        else if (leg2.stop_count === 1  && stops >= 1){
            stops = 1;
        }
        else{
            stops = 0;
        }
    }
    return stops;
}

export const calculateStopsMax = (flights) => {
    let stops = 0;

    for (let i = 0; i < flights.length; i++){
        let leg1 = (flights[i].legs[0]);
        let leg2 = (flights[i].legs[1]);
        if (leg1.stop_count >= 2 && stops <= 2){
            stops = 2;
        }
        else if (leg1.stop_count === 1 && stops <= 1){
            stops = 1;
        }

        if (leg2.stop_count >= 2  && stops <= 2){
            stops = 2;
        }
        else if (leg2.stop_count === 1  && stops <= 1){
            stops = 1;
        }
    }
    return stops;
}

export const calculateAvgAvgPrice = (obj) => {
    let avg = 0;
    Object.entries(obj).map((el, index) => {
        avg += el[1].priceAverage * el[1].numPeople;
    })
    avg = Math.trunc(avg);
    return avg;
}

export const calculateAvgFlightPrice = (obj) => {
    let avg = 0;
    Object.entries(obj).map((el, index) => {
        avg += el[1].priceAverage;
    })
    avg = Math.trunc(avg/(Object.entries(obj).length));
    
    return avg;
}

export const calculateAvgMinPrice = (obj) => {
    let avg = 0;
    Object.entries(obj).map((el, index) => {
        avg += el[1].priceMin * el[1].numPeople;
    })
    return Math.trunc(avg);
}

export const calculateAvgMaxPrice = (obj) => {
    let avg = 0;
    Object.entries(obj).map((el, index) => {
        avg += el[1].priceMax * el[1].numPeople;
    })

    return Math.trunc(avg);
}

export const calculateAvgDuration = (obj) => {
    let avg = 0;
    Object.entries(obj).map((el, index) => {
        avg += el[1].durationAverage * el[1].numPeople;
    })

    return Math.trunc(avg);
}


export const calculateAvgMinDuration = (obj) => {
    let avg = 0;
    Object.entries(obj).map((el, index) => {
        avg += el[1].durationMin * el[1].numPeople;
    })

    return Math.trunc(avg);
}

export const calculateAvgMaxDuration = (obj) => {
    let avg = 0;
    Object.entries(obj).map((el, index) => {
        avg += el[1].durationMax * el[1].numPeople;
    })

    return Math.trunc(avg);
}


export const calculateAvgMinStops = (obj) => {
    let avg = 0;
    Object.entries(obj).map((el, index) => {
        avg += el[1].stopsMin * el[1].numPeople;
    })

    return avg;
}

export const calculateAvgMaxStops = (obj) => {
    let avg = 0;
    Object.entries(obj).map((el, index) => {
        avg += el[1].stopsMax * el[1].numPeople;
    })

    return avg;
}
