import React, { useState } from 'react';
import { CollapseRow, Colors} from '../../../../utils/budget-tool-specific';
import { Col, Collapse, Card, CardBody } from 'reactstrap';
import styled from 'styled-components';
import BreakdownRow from './breakdown-row';
import CostRow from './cost-row';
import SubBreakdownRow from './subbreakdown-row';

const MealsRow = ({meals, activity, currency, total, data}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [mealsOpen, setMealssOpen] = useState(false);

    return (
        <React.Fragment>
            {console.log(data)}
            {data.inclusive.value !== "All-Inclusive" ? <CostRow title="F&B and Activities" 
                    icon='utensils' 
                    height 
                    setIsOpen={(x) => setIsOpen(x)} 
                    isOpen={isOpen}
                    currency={currency} 
                    percent={Math.round((meals.subtotal + activity.subtotal) / total * 100)}
                    subtotal={meals.subtotal + activity.subtotal}/> :
                <CostRow title="F&B and Activities" 
                    inclusive={true}
                    icon='utensils' 
                    height 
                    setIsOpen={(x) => setIsOpen(x)} 
                    isOpen={isOpen}
                    currency={currency} 
                    percent={Math.round((meals.subtotal + activity.subtotal) / total * 100)}
                    subtotal={meals.subtotal + activity.subtotal}/>}
            <Collapse isOpen={isOpen} style={{width: "100%"}}>
                <CollapseRow>
                    <Col>
                        <WhiteCard>
                        <PaddedCardBody>
                                {data.inclusive.value === "All-Inclusive" && <BreakdownRow currency={currency}
                                                inclusive={true}
                                                title="Meals"/>}
                                {data.inclusive.value !== "All-Inclusive" && <BreakdownRow currency={currency}
                                                title="Meals"
                                                nested
                                                setIsOpen={(x) => setMealssOpen(x)}
                                                isOpen={mealsOpen} 
                                                costPer={meals.subtotal}/>}
                                {data.inclusive.value !== "All-Inclusive" && <Collapse isOpen={mealsOpen}>
                                    <Col>
                                    <PinkCard> 
                                        <PaddedCardBody>
                                            <SubBreakdownRow currency={currency}
                                                        title="Breakfast" 
                                                        meals
                                                        num={meals.breakfast.numBreakfast}
                                                        subtotal={meals.breakfast.costPer}
                                                        costPer={meals.breakfast.subtotal}/>
                                            <SubBreakdownRow currency={currency}
                                                            title="Lunch"
                                                            meals 
                                                            num={meals.lunch.numLunch}
                                                            subtotal={meals.lunch.costPer}
                                                            costPer={meals.lunch.subtotal}/>
                                            <SubBreakdownRow currency={currency}
                                                            title="Dinner"
                                                            meals 
                                                            num={meals.dinner.numDinner}
                                                            subtotal={meals.dinner.costPer}
                                                            costPer={meals.dinner.subtotal}/>
                                            <SubBreakdownRow currency={currency}
                                                            title="Coffee" 
                                                            meals
                                                            num={meals.coffee.numCoffee}
                                                            subtotal={meals.coffee.costPer}
                                                            costPer={meals.coffee.subtotal}/>
                                            {meals.alcohol.numAlcohol > 0 && <SubBreakdownRow currency={currency}
                                                            title="Alcohol" 
                                                            meals
                                                            num={meals.alcohol.numAlcohol}
                                                            subtotal={meals.alcohol.costPer}
                                                            costPer={meals.alcohol.subtotal}/> }    
                                        </PaddedCardBody>
                                    </PinkCard>
                                    </Col>
                                </Collapse>}
                                {activity.numActivities !== 0 && <BreakdownRow currency={currency}
                                                            title="Activities"
                                                            meals 
                                                            num={activity.numActivities}
                                                            subtotal={activity.costPerActivity}
                                                            costPer={activity.subtotal}/> }
                        </PaddedCardBody>
                        </WhiteCard>
                    </Col>
                </CollapseRow>
            </Collapse>
        </React.Fragment>
    )
}

export default MealsRow;

const PinkCard = styled(Card)`
    background-color: ${Colors.primaryLight};
    border: none;
`
const WhiteCard = styled(Card)`
    background-color: lightgrey;
    border: none;
`

const PaddedCardBody = styled(CardBody)`
    padding: 15px 0px;
`