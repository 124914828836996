import React from 'react';
import { Row, Col } from "reactstrap";
import { OutputRow, TableIcon, BoldLabel } from "../../../../utils/budget-tool-specific";
import SubtotalColumns from "./subtotal-cols";
import { displayValue } from '../../helper';
import { calculateContingency } from './cost-helper';

const ContingencyRow = ({guideData, expData}) => {

    return(
        <React.Fragment>
            <OutputRow>
                <Col lg="1"/>
                <Col>
                    <Row>
                        <Col sm={{size: 'auto'}}>
                            <TableIcon icon={['fas', 'shield-alt']}/>
                        </Col>
                        <Col>
                        <BoldLabel>
                            Contingency
                        </BoldLabel>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    ({guideData.contingency.value}%)
                </Col>
                <Col lg="2">
                    {guideData.currency.value.slice(3)}{displayValue(calculateContingency(guideData, expData))}
                </Col> 
                <Col lg="1">
                    x {guideData.attendees.value}
                </Col>
                <Col lg="2">
                    <p>
                        {guideData.currency.value.slice(3)}{displayValue(calculateContingency(guideData, expData)* guideData.attendees.value)}
                    </p>
                </Col> 
            </OutputRow>
        </React.Fragment>
    )
}

export default ContingencyRow;