import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Collapse, Card, CardBody } from "reactstrap";
import { Colors, OutputRow, TableIcon, BoldLabel, ButtonContainer, CollapseButton, CollapseRow } from "../../../../utils/budget-tool-specific";
import TransportationBreakdownRow from "../../../budget/budget-tool/cost-output/transportation-breakdown-row";
import SubtotalCols from "./subtotal-cols";
import { displayValue } from "../../helper";
import { calculateCostMeetings, exchangeRate, calculateCostPP } from "./cost-helper";
import CostRow from "./exp-cost-row";
import MealsBreakdownRow from "../../../budget/budget-tool/cost-output/meals-breakdown-row";

const MeetingsRow = ({guideData, expData}) => {

    const [breakdownOpen, setBreakdownOpen] = useState(false);


    return (
        <React.Fragment>
           <CostRow title="Meetings" 
                icon='table'
                detail
                isOpen={breakdownOpen}
                setIsOpen={setBreakdownOpen}
                currency={guideData.currency.value.slice(3)} 
                subtotal={calculateCostMeetings(guideData,expData)}
                attendees={guideData.attendees.value}/>
            <Collapse isOpen={breakdownOpen}>
                <CollapseRow>
                        <Col>
                            <PinkCard>
                            <PaddedCardBody>
                                <TransportationBreakdownRow
                                                title="Room Rental" 
                                                costPer={exchangeRate(expData, calculateCostPP(guideData,expData, "roomRental"))}
                                                currency={guideData.currency.value.slice(3)}/>
                                <TransportationBreakdownRow
                                                title="AV" 
                                                costPer={exchangeRate(expData, calculateCostPP(guideData,expData, "av"))}
                                                currency={guideData.currency.value.slice(3)}/>
                                <TransportationBreakdownRow
                                                title="Setup Fees" 
                                                costPer={exchangeRate(expData, calculateCostPP(guideData,expData, "setupFee"))}
                                                currency={guideData.currency.value.slice(3)}/>
                                <MealsBreakdownRow
                                                title="Coffee Breaks"
                                                costPer={exchangeRate(expData, expData.costPerCoffee.value)}
                                                numNights={expData.numCoffee.value}
                                                cost={exchangeRate(expData, calculateCostPP(guideData, expData, "coffee"))}
                                                currency={guideData.currency.value.slice(3)}/>
                            
                            </PaddedCardBody>
                            </PinkCard>
                        </Col>
                </CollapseRow>
            </Collapse>
        </React.Fragment>
    )
}

export default MeetingsRow;

const PinkCard = styled(Card)`
    background-color: ${Colors.primaryAccent};
    border: none;
`

const PaddedCardBody = styled(CardBody)`
    padding: 15px 30px;
`