import React from 'react';
import PageTemplate from '../page-template';
import { Spacer, HeaderTitle } from '../../../utils/budget-tool-specific';
import AssistantData from './assistant-data';

const AssistantComponent = (props) => {

    return (
        <React.Fragment>
            <Spacer height="60px" id="height-spacer"/>
            <HeaderTitle>
                Assistant
            </HeaderTitle>
            <AssistantData />
        </React.Fragment>
    )
}
const Assistant = (props) => {

    if (props.collapsed) {
        return <AssistantComponent />;
    } else {
        return <PageTemplate component={<AssistantComponent/>} client={props.client}/>
    }
}
 
export default Assistant;