import React from 'react';
import { Row, Col } from "reactstrap";
import { OutputRow, TableIcon, BoldLabel, ButtonContainer, CollapseButton } from "../../../../utils/budget-tool-specific";
import { displayValue2 } from '../../helper';

const CostRow = (props) => {

    const normalStyle = {
        transition: '1s, transform 0.5s',
    };
      
      const transformedStyle = {
        transition: '1s, transform 0.5s',
        transform: 'rotate(180deg)',
    };

    return (
        <>
        {props.inclusive ? <OutputRow>
            <Col lg="1" style={{textAlign: "center", alignItems: "center", verticalAlign: "middle"}}>
                {!props.single && <ButtonContainer onClick={() => {props.setIsOpen(!props.isOpen)}}>
                    <CollapseButton icon={['fas', 'chevron-down']} style={props.isOpen ? transformedStyle : normalStyle}/> 
                </ButtonContainer>}
            </Col>
            <Col>
                <Row>
                    <Col sm={{size: 'auto'}}>
                        <TableIcon icon={['fas', props.icon]} style={{height: props.height ? "30px" : "auto"}}/>
                    </Col>
                    <Col>
                    <BoldLabel>
                        {props.title}
                    </BoldLabel>
                    </Col>
                </Row>
            </Col>
            <Col lg="1"></Col>
            <Col></Col>
           <Col lg="3">
                <p>
                    N/A - All Inclusive Plan
                </p>
            </Col>
        </OutputRow> :
        <OutputRow>
            <Col lg="1" style={{textAlign: "center", alignItems: "center", verticalAlign: "middle"}}>
                {!props.single && <ButtonContainer onClick={() => {props.setIsOpen(!props.isOpen)}}>
                    <CollapseButton icon={['fas', 'chevron-down']} style={props.isOpen ? transformedStyle : normalStyle}/> 
                </ButtonContainer>}
            </Col>
            <Col>
                <Row>
                    <Col sm={{size: 'auto'}}>
                        <TableIcon icon={['fas', props.icon]} style={{height: props.height ? "30px" : "auto"}}/>
                    </Col>
                    <Col>
                    <BoldLabel>
                        {props.title}
                    </BoldLabel>
                    </Col>
                </Row>
            </Col>
            {props.percent && <Col lg="1">
                {props.percent}%
            </Col>}
            {props.single && !props.accommodations && <Col>
                {props.type && <p>
                    {props.currency}{displayValue2(parseFloat(props.costPer))} {props.perLabel} x {props.num} {props.type}
                </p>}
                {!props.type && <p>
                    {props.currency}{displayValue2(parseFloat(props.costPer))} {props.perLabel}
                </p>}
            </Col>}
            {props.accommodations && <Col>
                {props.occupancy === "Single Occupancy" && <p>
                    {props.currency}{displayValue2(parseFloat(props.costPer))} {props.perLabel} x {props.num} {props.type} ({props.attendees * props.num} rooms total)
                </p>}
                {props.occupancy === "Double Occupancy" && <p>
                    {props.currency}{displayValue2(Math.ceil(parseFloat(props.costPer) / 2))} {props.perLabel} x {props.num} {props.type} ({(Math.ceil(props.attendees / 2) * props.num)} rooms total)
                </p>}
            </Col>}
            {!props.single && <Col></Col>}
        <Col lg="3">
                <p>
                    {props.currency}{displayValue2(parseFloat(props.subtotal))}
                </p>
            </Col>
        </OutputRow>}
        </>
    )
}

export default CostRow;