import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, Collapse, Card, CardBody } from "reactstrap";
import { Colors, OutputRow, TableIcon, BoldLabel, ButtonContainer, CollapseButton, CollapseRow } from "../../../../utils/budget-tool-specific";
import { useEffect } from "react";
import MealsBreakdownRow from "../../../budget/budget-tool/cost-output/meals-breakdown-row";
import SubtotalCols from "./subtotal-cols";
import { displayValue } from "../../helper";
import CostRow from "./exp-cost-row";
import { exchangeRate } from "./cost-helper";

const MealRow = ({guideData, expData}) => {

    const [breakdownOpen, setBreakdownOpen] = useState(false);

    let data = {
        breakfast: { 
            title: "Breakfast",
            costPer: exchangeRate(expData, expData.costPerBreakfast.value),
            numNights: expData.numBreakfast.value, 
            cost: exchangeRate(expData, expData.costPerBreakfast.value * expData.numBreakfast.value),
        }, 
        lunch: {
            title: "Lunch",
            costPer: exchangeRate(expData, expData.costPerLunch.value),
            numNights: expData.numLunch.value,
            cost: exchangeRate(expData, expData.costPerLunch.value * expData.numLunch.value)
        }, 
        dinner: {
            title: "Dinner",
            costPer: exchangeRate(expData, expData.costPerDinner.value), 
            numNights: expData.numDinner.value,
            cost: exchangeRate(expData, expData.costPerDinner.value * expData.numDinner.value)
        },
        alcohol: {
            title: "Alcohol",
            costPer: exchangeRate(expData, expData.costPerAlcohol.value),
            numNights: expData.numAlcohol.value,
            cost: exchangeRate(expData, expData.costPerAlcohol.value * expData.numAlcohol.value)
        }
    }

    const cost = data.alcohol.cost + data.dinner.cost + data.lunch.cost + data.breakfast.cost;

    const toggleCollapse = () => {

        if (breakdownOpen === true) {
            setBreakdownOpen(false);
        } else {
            setBreakdownOpen(true);
        }
    }
    

    const normalStyle = {
        transition: '1s, transform 0.5s',
    };
      
    const transformedStyle = {
        transition: '1s, transform 0.5s',
        transform: 'rotate(180deg)',
    };

    return (
        <React.Fragment>
            <CostRow title="Meals" 
                icon='utensils'
                height
                detail
                isOpen={breakdownOpen}
                setIsOpen={setBreakdownOpen}
                currency={guideData.currency.value.slice(3)} 
                subtotal={cost}
                attendees={guideData.attendees.value}/>
            <Collapse isOpen={breakdownOpen}>
                <CollapseRow>
                        <Col>
                            <PinkCard>
                            <PaddedCardBody>
                                
                                {Object.entries(data).map((element, i) => {
                                    
                                    if (element[1].cost !== 0){
                                        return (
                                                <MealsBreakdownRow key={i}
                                                                title={element[1].title} 
                                                                costPer={element[1].costPer}
                                                                numNights={element[1].numNights}
                                                                cost={element[1].cost}
                                                                currency={guideData.currency.value.slice(3)}/>
                                        )
                                    } else {
                                        return <React.Fragment key={i}/>
                                    }
                                    
                                })}
                                
                            </PaddedCardBody>
                            </PinkCard>
                        </Col>
                </CollapseRow>
            </Collapse>
        </React.Fragment>
    )
}

export default MealRow;

const PinkCard = styled(Card)`
    background-color: ${Colors.primaryAccent};
    border: none;
`

const PaddedCardBody = styled(CardBody)`
    padding: 15px 30px;
`