import React, { useState, useEffect } from 'react';
import { listenToDocument } from '../../../../utils/firestore-functions';
import { GetDB } from '../../../../utils/database/firebase-budget-tool';
import BudgetCostOutput from './budget-cost-output';

const CostStructureWrapper = ({data}) => {
    const [costStructure, setCostStructure] = useState();
    
    const budgetDB = GetDB();

    useEffect(() => {
        var unsubscribe = listenToDocument(budgetDB, "admin", "calculations", setCostStructure);
        return unsubscribe;
    }, []);

    return (
        <React.Fragment>
            {costStructure !== undefined && <BudgetCostOutput data={data} costStructure={costStructure}></BudgetCostOutput>}
        </React.Fragment>
    )
}

export default CostStructureWrapper;