import styled, { keyframes, css } from "styled-components";
import React from 'react';
import { Row, Col, Form, FormFeedback, Label, Button, Input, InputGroupText } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MonikerIcon from "../assets/images/moniker-logo-white.png";

// DATA VALUES
export const transportationOptions = [
    { value: "1", label: "Home to Airport" },
    { value: "2", label: "Airport to Hotel" },
    { value: "3", label: "Hotel to Airport" },
    { value: "4", label: "Airport to Home" },
];
export const addOnOptions = [
    { value: "2", label: "Swag" },
    { value: "3", label: "Videographer/Photographer" },
    { value: "6", label: "Travel insurance" },
    { value: "7", label: "Microsite" },
    { value: "8", label: "Enhanced Decor & Entertainment" } ,
    { value: "9", label: "AV" }
];

export const servicesOptions = [
    { value: "2", label: "Swag" },
    { value: "3", label: "Videographer/Photographer" },
    { value: "6", label: "Travel insurance" },
    { value: "7", label: "Microsite" },
    { value: "8", label: "Enhanced Decor & Entertainment" } ,
    { value: "9", label: "AV" }
];

export const InfoMessages = {
    priceAverage: {
        header: "Information: Price Average",
        body: "Price Average is calculated by taking the cheapest three flights available and calculating the average from those."
    },
    stopsMin: {
        header: "Information: Stops Minimum",
        body: "Stops average is calculated by using the stop codes \"DIRECT\", \"ONE_STOP, and \"MORE_THAN_ONE_STOP\". More than one stop is assumed to be a 2-stop trip, and the least number of stops is considered to be the minimum.",
    }, 
    stopsMax: {
        header: "Information: Stops Maximum",
        body: "Stops average is calculated by using the stop codes \"DIRECT\", \"ONE_STOP, and \"MORE_THAN_ONE_STOP\". More than one stop is assumed to be a 2 stop trip, and that would be considered the maximum.",
    },
}

export const currencyOptions = () => {
    return ( <React.Fragment>
        <option>CAD$</option>
        <option>USD$</option>
        <option>EUR€</option>
        <option>GBP£</option>
    </React.Fragment>
    )
}

export const Colors = {
    primary: "#343434",
    primaryAccent: "#fdb506",
    primaryDark: "black",
    primaryLight: "#ededed",
    primaryXLight: "white",
    primaryDanger: "#BE2C0D",
    primaryDangerDark: "#8D1E06",
}

// CONTAINERS 
export const HeightDiv = styled.div`
    min-height: 100vh;
    background-color: ${Colors.primaryLight};
    margin: 0px;
    padding: 0px;
`;

export const Spacer = styled.div`
    height: ${props => props.height ? props.height : "20px"};
`

export const NoMarginPaddedContainer = styled.div`
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 15px;
    padding-right: 15px;
`
export const WhiteTableContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    margin-right: 0px;
    margin-left: 0px;
    padding: 15px 15px 30px 15px;
    margin-bottom: 60px;
`

export const ContentContainer = styled.div`
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 15px;
    padding-right: 15px;
`

// ROWS AND COLS 
export const SpacerCol = styled.div`
    width: 190px;
    padding: 0px;
    margin: 0px;
    z-index: ${props => props.client ? "1001" : "-1"};
    background-color: ${Colors.primaryDark};
    height: 200px;

`

export const NoPaddingRow = styled(Row)`
    padding: 0px;
`

export const RightCol = styled(Col)`
    width: auto;
`

export const FlexCol = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: ${props => props.jc};
`;

export const CenterCol = styled.div`
    text-align: center;
    height: 100vh;
    padding: 0px 15px;
`
export const CenterRow = styled(Row)`
    text-align: center;
    height: 100vh;
    padding: 0px 15px;
    align-items: center;
`

export const OutputRow = styled(Row)`
    border-bottom: 1px solid ${Colors.primaryLight};
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
`

export const FormRow = styled(Row)`
    padding-right: 15px;
    padding-left: 15px;
`

export const WhiteForm = styled(Form)`
    border-radius: 10px;
    background-color: white;
    padding: 10px 15px;
    margin: 20px 0px;
`
//TEXT AND TITLES
export const HeaderTitle = styled.h2`
    color: ${Colors.primaryDark};
    margin-top: 30px;
    margin-bottom: 0px;
    padding-left: 15px;
`;

export const BoldLabel = styled.p`
    font-weight: bold;
`

export const LeftLabel = styled.p`
    text-align: left;
    display: inline;
`

export const HighlightedCost = styled.p`
    font-weight: bold;
    display: inline;
    color: ${Colors.primary};
`
export const TableTitle = styled.h5`
    font-weight: bold;
    color: ${Colors.primaryAccent};
`

export const SmallFormFeedback = styled(FormFeedback)`
    font-size: 12px;
`

export const GreyLabel = styled(Label)`
    margin: 0px;
    color: ${Colors.primaryDark};
    font-size: 12px;
`


// COMPONENTS

export const HeaderIcon = styled.div`
    background-image: url(${MonikerIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 41px;
`
export const TableIcon = styled(FontAwesomeIcon)`
    margin-right: 0px;
    margin-left: 0px;
    width: 30px !important;
    height: auto;
    color: ${Colors.primaryAccent};
`;

export const EditIcon = styled(FontAwesomeIcon)`
    margin-right: 0px;
    margin-left: 0px;
    width: 30px !important;
    height: auto;
    color: ${Colors.primaryAccent};
    &:hover {
        color: white;
    }
`;

const RotateAnimation = keyframes`
from {
    transform: rotate(0deg);
}

to {
    transform: rotate(360deg);
}
`;

export const RotatingIcon = styled(FontAwesomeIcon)`
    margin-right: 0px;
    margin-left: 0px;
    width: 30px !important;
    height: auto;
    color: ${Colors.primaryAccent};
    &:hover {
        color: white;
    }
    animation: ${props =>
        props.isOn
          ? css`${RotateAnimation} 1s infinite linear`
          : 'none'
      };
`;

export const PinkButtonContainer = styled.div`
    margin: 0px 5px ;
    width: auto;
    height: 50px;
    border-radius: 10px;
    padding: 15px 5px 5px 5px;
    display: inline;
    &:hover {
        background-color: ${Colors.primaryAccent};
    }
    &:hover ${EditIcon} {
        color: white;
    }
`

export const CollapseButton = styled(FontAwesomeIcon)`
    text-align: center;
    align-items: center;
    vertical-align: middle;
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    vertical-align: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin: 0px;
    padding: 0px;
    border-radius: 25px;
    &:hover {
        background-color: ${Colors.primaryLight};
    }
`

export const CollapseRow = styled(Row)`
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${Colors.primaryLight};
    margin-left: 15px;
    margin-right: 15px;
`

export const NavItems = {
    budget: {
        title: "Budget Demo",
        urlify: "budget-demo",
        icon: "money-bill",
    },
    experience: {
        title: "Experiences",
        urlify: "experiences",
        icon: "globe-americas",
    },
    flight: {
        title: "Flights",
        urlify: "flights",
        icon: "plane",
    },
    assistant: {
        title: "Assistant",
        urlify: "assistant",
        icon: "clipboard-list"
    },
    settings: {
        title: "Settings",
        urlify: "settings",
        icon: "cogs"
    }
}

export const UnderlineButton = styled(Button)`
    border: none;
    text-decoration: underline;
    background-color: transparent;
    display: inline;
    color: ${Colors.primaryDark};
    padding: 6px 15px;
    &:hover {
        background-color: transparent;
        color: ${Colors.primaryDark};
        text-decoration: underline;
    }
`

export const UnderlineInput = styled(Input)`
    border: none;
    border-bottom: 1px solid ${Colors.primaryLight};
    border-radius: 0px;
    padding: 5px 5px;
    &:hover {
        border-bottom: 1px solid black;
    }
`

export const HighlightedInput = styled(Input)`
    border: none;
    border-bottom: 1px solid ${Colors.primaryLight};
    border-radius: 0px;
    background-color: ${Colors.primaryLight};
    color: ${Colors.primaryDark};
    padding: 5px 5px;
    &:hover {
        border-bottom: 1px solid ${Colors.primaryAccent};
    }
`

export const StyledInputGroupText = styled(InputGroupText)`
    border: none;
    border-radius: 0px;
    background-color: white;
    border-bottom: 1px solid ${props => !props.invalid ? Colors.primaryLight : "#dc3545"};
    padding: 5px 0px;
    color: ${Colors.primaryDark};
    padding-right: 8px;
`
export const HighlightedInputGroupText = styled(InputGroupText)`
    border: none;
    border-radius: 0px;
    background-color: ${Colors.primaryAccent};
    border-bottom: 1px solid ${props => !props.invalid ? Colors.primaryAccent : "#dc3545"};
    padding: 5px 5px;
    color: ${Colors.primaryDark};
`
export const PrimaryButton = styled(Button)`
    background-color: ${Colors.primary};
    border: none;
    margin: 0px 15px;
    &:hover {
        background-color: ${Colors.primaryDark};
    }
    display: inline;
`

export const DangerButton = styled(Button)`
    background-color: ${Colors.primaryDanger};
    border: none;
    margin: 0px 15px;
    &:hover {
        background-color: ${Colors.primaryDangerDark};
    }
    display: inline;
`

export const SecondaryButton = styled(Button)`

    background-color: ${Colors.primaryAccent};
    border: 0px;
    border: none;
    margin: 0px 15px;
    &:hover {
        background-color: ${Colors.primaryDark};
    }
    display: inline;
    color: ${Colors.primaryDark};
`

export const TabButton = styled(Button)`
    background-color: ${Colors.primaryLight};
    border: 2px solid ${Colors.primaryLight};
    border-radius: 10px 10px 0px 0px;
    margin: 0px 1px;
    color: ${Colors.primaryDark};
    &:hover {
        background-color: ${Colors.primaryAccent};
        color: ${Colors.primaryDark};
        border: 2px solid ${Colors.primaryAccent};
    }
    display: inline;
`
export const ActiveTabButton = styled(Button)`
    background-color: ${Colors.primaryAccent};
    color: ${Colors.primaryDark};   
    border: 2px solid ${Colors.primaryAccent};
    border-radius: 10px 10px 0px 0px;
    margin: 0px 1px;
    &:hover {
        background-color: ${Colors.primaryAccent};
        color: ${Colors.primaryDark};
        border: 2px solid ${Colors.primaryAccent};
    }
    display: inline;
`

export const FormTitleHighlightedInput = styled(Input)`
    font-size: ${props => props.fontSize ? props.fontSize : "20px"};
    border: none;
    background-color: ${Colors.primaryLight};
    border-bottom: 1px solid ${Colors.primaryAccent};
    border-radius: 0px;
    margin: 0px 0px;
    &:hover {
        border-bottom: 1px solid black;
    }
`