
export const calculateMiscCost = () => {
    return 0;
}
export const exchangeRate = (expData, value) => {
    return (expData.convert.value / expData.base.value) * value;
} 
export const calculateSubCostPerAttendee = (guideData, expData) => {
    let sum = 0;

    sum += parseFloat(calculateCostAccommodation(guideData, expData));
    sum += parseFloat(exchangeRate(expData, expData.flightCost.value));
    sum += parseFloat(calculateCostActivity(guideData, expData));
    sum += parseFloat(calculateCostGroundTransport(guideData, expData));
    sum += parseFloat(calculateCostMeals(guideData, expData));
    sum += parseFloat(calculateCostMeetings(guideData, expData));
    sum += parseFloat(calculateCostMiscellaneous(guideData, expData));

    return sum;
}

export const calculateCostFlight = (guideData, expData) => {
    return exchangeRate(expData, expData.flightCost.value);
}
export const calculateCostActivity = (guideData, expData) => {
    let cost = exchangeRate(expData, expData.costPerActivity.value * expData.numActivities.value);
    return cost;
}

export const calculateCostGroundTransport = (guideData, expData) => {
    let cost = 0;
    if (expData.transportation.value.length === undefined) {
        cost = 0;
    } else {
        cost = exchangeRate(expData, expData.costPerTransportation.value * expData.transportation.value.length);
    }
    
    return cost;
}

export const calculateCostMeals = (guideData, expData) => {
    let cost = expData.costPerBreakfast.value * expData.numBreakfast.value;
    cost += expData.costPerLunch.value * expData.numLunch.value;
    cost += expData.costPerDinner.value * expData.numDinner.value;
    cost += expData.costPerAlcohol.value * expData.numAlcohol.value;
    cost = exchangeRate(expData, cost);
    return cost;
}

export const calculateCostPerAttendee = (guideData, expData) => {
    return calculateSubCostPerAttendee(guideData,expData) + calculateContingency(guideData, expData);
}
export const calculateSubCostNoFlightsPerAttendee = (guideData, expData) => {
    return calculateSubCostPerAttendee(guideData,expData) - exchangeRate(expData, expData.flightCost.value);
}
export const calculateTotalCostPerAttendee = (guideData, expData) => {
    return calculateCostPerAttendee(guideData, expData);
    
}
export const calculateSubCostTrip = (guideData, expData) => { //
    return (calculateTotalCostPerAttendee(guideData, expData) * guideData.attendees.value);
    
}

export const calculateCostTrip = (guideData, expData) => { //
    return (calculateTotalCostPerAttendee(guideData, expData) * guideData.attendees.value) + calculateInternalCostA(guideData,expData) + calculateInternalCostB(guideData, expData);
    
}

export const calculateCostAccommodation = (guideData, expData) => {
    let costPerSingleRoomPerNight = expData.singleRate.value;
    let costPerDoubleRoomPerNight = expData.doubleRate.value;
    let numSingleRooms = expData.singleRooms.value;
    let numDoubleRooms = expData.doubleRooms.value;
    let costTotalPerNight  = 0;
    costTotalPerNight = (numSingleRooms * costPerSingleRoomPerNight) + (numDoubleRooms * costPerDoubleRoomPerNight);
    //console.log("Returning: " + exchangeRate(expData, (guideData.tripLength.value * costTotalPerNight) / Number(guideData.attendees.value)));
    return exchangeRate(expData, (guideData.tripLength.value * costTotalPerNight) / Number(guideData.attendees.value));
    
    /*
    let costPerRoomPerNight =  expData.accommodationCost.value;
    if (guideData.occupancy.value === "Double Occupancy"){
        let numRooms = 0;
        numRooms = Number(guideData.attendees.value) + Number(guideData.attendees.value % 2);
        numRooms = numRooms / 2;
        let totalCost  = costPerRoomPerNight * numRooms;
        costPerRoomPerNight = totalCost / (guideData.attendees.value);
    }
    else{
        return exchangeRate(expData, (guideData.tripLength.value * expData.accommodationCost.value));
    }
    return exchangeRate(expData, (guideData.tripLength.value * costPerRoomPerNight));
    */
}

export const calculateContingency = (guideData, expData) => {
    return calculateSubCostPerAttendee(guideData, expData) * (guideData.contingency.value / 100);
}

//Overhead
export const calculateInternalCostA = (guideData, expData, clientCurrency = true, currencyConversionRatio = 0) => {
    if (guideData.overheadPercent === undefined || guideData.overheadPercent.value === true)
    {
        if (guideData.overhead !== undefined){
            return(
                calculateSubCostNoFlightsPerAttendee(guideData, expData) * guideData.attendees.value * (guideData.overhead.value / 100) + 
                (calculateContingency(guideData, expData) * guideData.attendees.value * (guideData.overhead.value / 100))
            );
        }
        else{
            console.log("Default value set for overhead.");
            return(
                calculateSubCostNoFlightsPerAttendee(guideData, expData) * guideData.attendees.value * 0.1 + 
                (calculateContingency(guideData, expData) * guideData.attendees.value * 0.1)
            );
        }
    }
    else
    {
        if (clientCurrency === false) {
            return(expData, guideData.overhead.value * currencyConversionRatio);
        }
        else{
            return(parseFloat(guideData.overhead.value));
        }
    }
}

//Margin
export const calculateInternalCostB = (guideData, expData, clientCurrency = true) => {
    if (guideData.marginPercent === undefined || guideData.marginPercent.value === true)
        {
            if (guideData.margin !== undefined){
                return(
                    calculateSubCostNoFlightsPerAttendee(guideData, expData) * guideData.attendees.value * (guideData.margin.value / 100) + 
                    (calculateContingency(guideData, expData) * guideData.attendees.value * (guideData.margin.value / 100))
                );
            }
            else{
                console.log("Default value set for overhead.");
                return(
                    calculateSubCostNoFlightsPerAttendee(guideData, expData) * guideData.attendees.value * 0.1 + 
                    (calculateContingency(guideData, expData) * guideData.attendees.value * 0.1)
                );
            }
        }
    else
    {
        return(parseFloat(guideData.margin.value));
    }
}
export const calculateCostMiscellaneous = (guideData, expData) => {

    let cost = 0;
    if(expData.miscellaneousCosts){
        if (expData.miscellaneousCosts.value !== undefined) {
            Object.values(expData.miscellaneousCosts.value).map((element, key) => {
                let subcost = 0;
                if (element.pp === "per person") {
                    subcost = element.cost;
                } else {
                    subcost = element.cost / guideData.attendees.value;
                }
                cost += subcost;
        })}
    }
    cost = exchangeRate(expData, cost);
    return cost;
}

export const calculateCostPP = (guideData, expData, value) => {
    let cost = 0;
    if (expData.numMeetings.value > 0){
        if (value === "coffee") {
            cost = expData.numCoffee.value * expData.costPerCoffee.value * expData.numMeetings.value;
        } else {
            cost = expData[value].value.value;
            if (expData[value].value.pp === "per day" && expData[value].value.value > 0 ) {
                cost = expData[value].value.value * expData.numMeetings.value / guideData.attendees.value;
            } 
        }
    }
    return cost;
}

export const calculateCostMeetings = (guideData, expData) => {
    let cost = 0; 
    if (expData.numMeetings.value > 0) {
        cost += parseFloat(calculateCostPP(guideData, expData , "setupFee")); 
        cost += parseFloat(calculateCostPP(guideData, expData , "roomRental"));
        cost += parseFloat(calculateCostPP(guideData, expData , "av"));
        cost += parseFloat(calculateCostPP(guideData, expData , "coffee"));
    }
    return exchangeRate(expData, cost);
}