import React, { useState } from 'react';
import { Col, Row, Collapse, Card, CardBody, Input } from 'reactstrap';
import styled from 'styled-components';
import { Colors, OutputRow, ButtonContainer, CollapseButton, CollapseRow, BoldLabel, HighlightedInput } from '../../../../utils/budget-tool-specific';
import { displayValue } from '../../helper';
import { calculateContingency, calculateCostTrip, calculateCostMeals, calculateCostAccommodation, calculateCostActivity, calculateCostGroundTransport, calculateCostMeetings, calculateCostMiscellaneous, calculateCostFlight, calculateInternalCostA, calculateInternalCostB } from '../cost-summary/cost-helper';


const SummaryExperienceRow = ({guideData, expData, setIndex, clientCurrency}) => {

    const normalStyle = {
        transition: '1s, transform 0.5s',
    };
      
    const transformedStyle = {
        transition: '1s, transform 0.5s',
        transform: 'rotate(180deg)',
    };

    const [isOpen, setIsOpen] = useState(false);

    return (
        <React.Fragment>
            <OutputRow>
                <Col lg="1" style={{textAlign: "center", alignItems: "center", verticalAlign: "middle"}}>
                    <ButtonContainer onClick={() => {setIsOpen(!isOpen)}}>
                        <CollapseButton icon={['fas', 'chevron-down']} style={isOpen ? transformedStyle : normalStyle}/> 
                    </ButtonContainer>
                </Col>
                <Col>
                    <p style={{marginBottom: "16px"}}>{expData.title.value}</p>
                </Col>
                <Col lg="2">
                    {guideData.currency.value.slice(3)}{displayValue(calculateCostTrip(guideData, expData) / guideData.attendees.value)}
                </Col>
                <Col lg="2">
                    {guideData.currency.value.slice(3)}{displayValue(calculateCostTrip(guideData, expData))}
                </Col>
            </OutputRow>
            <Collapse isOpen={isOpen}>
                <CollapseRow>
                    <Col>
                        <PinkCard>
                        <PaddedCardBody>
                            <Row style={{display: "flex", alignItems: "flex-end"}}>
                                <Col lg="1"></Col>
                                <Col>
                                    <BoldLabel>
                                        Categories
                                    </BoldLabel>
                                </Col>
                                <Col lg="2">
                                    <BoldLabel>
                                        Cost Per Attendee
                                    </BoldLabel>
                                </Col>
                                <Col lg="2">
                                    <BoldLabel>
                                        Cost for Trip
                                    </BoldLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="1"></Col>
                                <Col>
                                    Accommodations
                                </Col>
                                <Col>
                                    {expData && <React.Fragment>
                                        {guideData.currency.value.slice(3)}{displayValue(calculateCostAccommodation(guideData, expData)/guideData.tripLength.value)} AVG/pp per night x {guideData.tripLength.value} nights
                                        <br />
                                        {expData.singleRooms.value > 0 && 
                                            <React.Fragment>
                                                <span>&gt; {expData.singleRooms.value}x SGL Rooms @ </span>
                                                <span>{guideData.currency.value.slice(3)}{expData.singleRate.value}/rm per night</span>
                                            </React.Fragment>}
                                        {expData.singleRooms.value > 0 && expData.doubleRooms.value > 0 && <br />}
                                        {expData.doubleRooms.value > 0 && 
                                            <React.Fragment>
                                                <span>&gt; {expData.doubleRooms.value}x DBL Rooms @ </span>
                                                <span>{guideData.currency.value.slice(3)}{expData.doubleRate.value}/rm per night</span>
                                            </React.Fragment>}
                                    </React.Fragment>}
                                </Col>
                                <Col lg="2">
                                    {guideData.currency.value.slice(3)}{displayValue(calculateCostAccommodation(guideData, expData))}
                                </Col>
                                <Col lg="2">
                                    {guideData.currency.value.slice(3)}{displayValue(calculateCostAccommodation(guideData, expData) * guideData.attendees.value)}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="1"></Col>
                                <Col>
                                    Flights
                                </Col>
                                <Col lg="2">
                                    {guideData.currency.value.slice(3)}{displayValue(calculateCostFlight(guideData, expData))}
                                </Col>
                                <Col lg="2">
                                    {guideData.currency.value.slice(3)}{displayValue(calculateCostFlight(guideData, expData) * guideData.attendees.value)}
                                </Col>
                            </Row>
                            <Row>
                            <Col lg="1"></Col>
                                <Col>
                                    Activities
                                </Col>
                                <Col lg="2">
                                    {guideData.currency.value.slice(3)}{displayValue(calculateCostActivity(guideData, expData))}
                                </Col>
                                <Col lg="2">
                                    {guideData.currency.value.slice(3)}{displayValue(calculateCostActivity(guideData, expData) * guideData.attendees.value)}
                                </Col>
                            </Row>
                            {expData.transportation.value !== "" && <Row>
                                <Col lg="1"></Col>
                                <Col>
                                    Ground Transportation
                                </Col>
                                <Col lg="2">
                                    {guideData.currency.value.slice(3)}{displayValue(calculateCostGroundTransport(guideData, expData))}
                                </Col>
                                <Col lg="2">
                                    {guideData.currency.value.slice(3)}{displayValue(calculateCostGroundTransport(guideData,expData) * guideData.attendees.value)}
                                </Col>
                            </Row>}
                            <Row>
                            <Col lg="1"></Col>
                                <Col>
                                    Meals
                                </Col>
                                <Col lg="2">
                                    {guideData.currency.value.slice(3)}{displayValue(calculateCostMeals(guideData, expData))}
                                </Col>
                                <Col lg="2">
                                    {guideData.currency.value.slice(3)}{displayValue(calculateCostMeals(guideData, expData) * guideData.attendees.value)}
                                </Col>
                            </Row>
                            <Row>
                            <Col lg="1"></Col>
                                <Col>
                                    Meetings
                                </Col>
                                <Col lg="2">
                                    {guideData.currency.value.slice(3)}{displayValue(calculateCostMeetings(guideData, expData))}
                                </Col>
                                <Col lg="2">
                                    {guideData.currency.value.slice(3)}{displayValue(calculateCostMeetings(guideData, expData) * guideData.attendees.value)}
                                </Col>
                            </Row>
                            <Row>
                            <Col lg="1"></Col>
                                <Col>
                                    Miscellaneous Costs
                                </Col>
                                <Col lg="2">
                                    {guideData.currency.value.slice(3)}{displayValue(calculateCostMiscellaneous(guideData, expData))}
                                </Col>
                                <Col lg="2">
                                    {guideData.currency.value.slice(3)}{displayValue(calculateCostMiscellaneous(guideData, expData) * guideData.attendees.value)}
                                </Col>
                            </Row>
                            <Row>
                            <Col lg="1"></Col>
                                <Col>
                                    Contingency
                                </Col>
                                <Col lg="2">
                                    {guideData.currency.value.slice(3)}{displayValue(calculateContingency(guideData, expData))}
                                </Col>
                                <Col lg="2">
                                    {guideData.currency.value.slice(3)}{displayValue(calculateContingency(guideData, expData) * guideData.attendees.value)}
                                </Col>
                            </Row>
                            <Row>
                            <Col lg="1"></Col>
                                <Col>
                                    {(guideData.overheadPercent === undefined || guideData.margin.value !== "0") ? "Professional Planning Overhead" : "Moniker's Management Fee"}
                                </Col>
                                <Col lg="2">
                                </Col>
                                <Col lg="2">
                                {guideData.currency.value.slice(3)}{displayValue(calculateInternalCostA(guideData, expData))}
                                </Col>
                            </Row>
                            {guideData.margin.value !== "0" &&<Row>
                                <Col lg="1"></Col>
                                <Col>
                                    Profit Margin
                                </Col>
                                <Col lg="2">
                                
                                </Col>
                                <Col lg="2">
                                {guideData.currency.value.slice(3)}{displayValue(calculateInternalCostB(guideData, expData))}
                                </Col>
                            </Row>}
                        </PaddedCardBody>
                        </PinkCard>
                    </Col>
                </CollapseRow>
            </Collapse>
        </React.Fragment>
    )
}

export default SummaryExperienceRow;

const PinkCard = styled(Card)`
    background-color: ${Colors.primaryAccent};
    border: none;
`

const PaddedCardBody = styled(CardBody)`
    padding: 15px 0px;
`