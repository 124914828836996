import React, {useState} from "react";
import { Row, Col, FormGroup, InputGroup, Input, InputGroupText, Button,  } from "reactstrap";
import { GreyLabel, 
    HighlightedInput, 
    HighlightedInputGroupText,
    SmallFormFeedback, 
    Spacer, 
    PinkButtonContainer, 
    EditIcon,
    Colors} from "../../../../utils/budget-tool-specific";
import styled from "styled-components";

const MiscellaneousInputs = (props) => {

    return ( 
        <React.Fragment>
            <Row key={props.i}>
                <Col lg="6">
                    <FormGroup> 
                        <GreyLabel>Cost Title</GreyLabel>
                        <InputGroup>
                            <HighlightedInput placeholder="" type="text" value={props.miscCost[1].title} onChange={e => props.handleMiscellaneousChange(e.target.value, props.miscCost[1].id, "title")} />
                            <SmallFormFeedback>Required</SmallFormFeedback>
                        </InputGroup>
                    </FormGroup>
                    
                 </Col>
                <Col>
                    <FormGroup> 
                        <GreyLabel>Cost</GreyLabel>
                        <InputGroup>
                            <HighlightedInputGroupText invalid={false}>
                            {props.currency}
                            </HighlightedInputGroupText>
                            <HighlightedInput placeholder="" type="number" value={props.miscCost[1].cost} onChange={e => props.handleMiscellaneousChange(parseInt(e.target.value), props.miscCost[1].id, "cost")} />
                            <SmallFormFeedback>Required</SmallFormFeedback>
                            <HighlightedInputGroupText style={{padding: "0px"}}>
                                <CheckboxButton onClick={x => props.handleMiscellaneousChange(props.miscCost[1].pp, props.miscCost[1].id, "pp")}>
                                    {props.miscCost[1].pp}
                                </CheckboxButton>
                            </HighlightedInputGroupText>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col lg="1">
                    <Spacer height="30px"></Spacer>
                    <PinkButtonContainer onClick={(x) => props.deleteMiscCost(props.miscCost[1].id)}>
                        <EditIcon icon={['fas', 'trash']} style={{height: "25px"}}/>
                    </PinkButtonContainer>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default MiscellaneousInputs;

const CheckboxButton = styled(Button)`
    height: 100%;
    margin: 0px;
    border: none;
    border-radius: 0px;
    background-color: ${Colors.primaryAccent};
    color: black;
    &: hover {
        background-color: ${Colors.primaryDark};
        color: white;
    }
    &: focus{
        background-color: ${Colors.primaryAccent};
        color: black;
    }
    &: selected{
        background-color: ${Colors.primaryDark};
        color: black;
    }
`