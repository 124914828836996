import React, { useState } from "react";
import { Row, Col, Collapse, Card, CardBody } from "reactstrap";
import { OutputRow, TableIcon, BoldLabel, CollapseRow, Colors } from "../../../../utils/budget-tool-specific";
import CostRow from "./cost-row";
import styled from "styled-components";
import BreakdownRow from "./breakdown-row";
import SubBreakdownRow from "./subbreakdown-row";

const AirfareRow = ({flightCost, transportation, currency, total}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [transfersOpen, setTransfersOpen] = useState(false);

    return (
        <React.Fragment>
            <CostRow title="Airfare & Transfers" 
                    icon='plane' 
                    setIsOpen={(x) => setIsOpen(x)} 
                    isOpen={isOpen}
                    currency={currency} 
                    percent={Math.round(((parseFloat(transportation.subtotal) + parseFloat(flightCost.subtotal)) / total) * 100)}
                    subtotal={parseFloat(transportation.subtotal) + parseFloat(flightCost.subtotal)}/>

            <Collapse isOpen={isOpen} style={{width: "100%"}}>
                <CollapseRow>
                    <Col>
                        <WhiteCard>
                        <PaddedCardBody>
                                <BreakdownRow currency={currency}
                                                title="Flight Cost" 
                                                costPer={flightCost.subtotal}/>
                                <BreakdownRow currency={currency}
                                                title="Transfers" 
                                                nested
                                                setIsOpen={(x) => setTransfersOpen(x)}
                                                isOpen={transfersOpen}
                                                costPer={transportation.subtotal}/>
                                                
                                <Collapse isOpen={transfersOpen}>
                                    <Col>
                                        <PinkCard>
                                            <PaddedCardBody>
                                                {transportation.transportation !== undefined && Object.entries(transportation.transportation).map((element, i) => {
                                                    return (
                                                        <SubBreakdownRow key={i}
                                                                        currency={currency}
                                                                        title={element[1]} 
                                                                        costPer={transportation.costPerTransportation}/>
                                                    )
                                                })}
                                            </PaddedCardBody>
                                        </PinkCard>
                                    </Col>
                                </Collapse>
                        </PaddedCardBody>
                        </WhiteCard>
                    </Col>
                </CollapseRow>
            </Collapse>
        </React.Fragment>
    )
}

export default AirfareRow;

const PinkCard = styled(Card)`
    background-color: ${Colors.primaryLight};
    border: none;
`
const WhiteCard = styled(Card)`
    background-color: lightgrey;
    border: none;
`

const PaddedCardBody = styled(CardBody)`
    padding: 5px 0px;
`