import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "reactstrap";
import { HeightDiv,
    HeaderTitle, 
    Spacer,
    ContentContainer,
    NoPaddingRow,
    SpacerCol,
    RightCol,} from "../../../utils/budget-tool-specific";
import PageTemplate from "../page-template";

const BudgetHomeComponent = () => {

    return(
        <React.Fragment>
            <Spacer height="60px" id="height-spacer"/>
            <Row>
                <Col>
                    <HeaderTitle>
                        Welcome
                    </HeaderTitle>
                </Col>
            </Row>
            <div  style={{display: "flex", justifyContent: "center"}}>
                
            </div>
        </React.Fragment>
    )
}

const BudgetHome = (props) => {

    if (props.collapsed) { 
        return <BudgetHomeComponent/>
    } else {
        return <PageTemplate component={<BudgetHomeComponent/>} client={props.client}/> 
    }
} 

export default BudgetHome;