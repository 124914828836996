import React from 'react';
import { FormGroup, InputGroup, Input, Row, Col } from 'reactstrap';
import { GreyLabel, UnderlineInput, SmallFormFeedback, StyledInputGroupText, Colors, HighlightedInputGroupText } from '../../../../utils/budget-tool-specific';
import styled from 'styled-components';

const FormInputTripLength = (props) => {

    const handleTripLength = (value, type) => {
        if (type === "nights") {
            const newData = {...props.data};
            newData[props.id].value = value;
            props.setData(newData);
        }
        if (type === "days") {
            const newData = {...props.data};
            newData[props.id].value = value-1;
            props.setData(newData);
        }
    }


    return (
        <React.Fragment>        
            <FormGroup>  
                <GreyLabel>Trip Length</GreyLabel> 
                <Row>
                    <Col>
                        <InputGroup>
                            <HighlightedInput placeholder="" 
                                            invalid={props.data[props.id].invalid} 
                                            type={props.type} 
                                            value={props.data[props.id].value * 1 + 1} 
                                            onChange={e => handleTripLength(parseInt(e.target.value), "days")} />
                            <HighlightedInputGroupText>
                            days
                            </HighlightedInputGroupText>
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup>
                            <HighlightedInput placeholder="" 
                                            invalid={props.data[props.id].invalid} 
                                            type={props.type} 
                                            value={props.data[props.id].value * 1}  
                                            onChange={e => handleTripLength(parseInt(e.target.value), "nights")} />
                            <HighlightedInputGroupText>
                            nights
                            </HighlightedInputGroupText>
                        </InputGroup>
                    </Col>
                </Row>
                <SmallFormFeedback>Required</SmallFormFeedback>
            </FormGroup>
        </React.Fragment>
    )
} 

export default FormInputTripLength;

export const HighlightedInput = styled(Input)`
    font-size: ${props => props.fontSize ? props.fontSize : "16px"};
    margin: ${props => props.fontSize === "16px" ? "" : "0px 0px"};
    border: none;
    border-bottom: 1px solid ${Colors.primaryLight};
    border-radius: 0px;
    background-color: ${Colors.primaryLight};
    color: ${Colors.primaryDark};
    padding: ${props => props.fontSize === "16px" ? "5px 5px" : ""};
    &:hover {
        border-bottom: 1px solid ${Colors.primaryAccent};
    }
`