import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Modal, ModalBody, ModalHeader, ModalFooter, Button, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { WhiteForm, OutputRow, BoldLabel, PrimaryButton, EditIcon, PinkButtonContainer, Colors } from "../../../utils/budget-tool-specific";
import { GetDB, generateGUID } from "../../../utils/database/firebase-budget-tool";
import { listenToCollection, setDocument, updateDocumentProperty, deleteDocument } from "../../../utils/firestore-functions";
import firebase from "firebase/compat/app";

const ExperienceGuideList = () => {

    const [guideList, setGuideList] = useState({});
    const [sortedList, setSortedList] = useState({});
    const [deleteID, setDeleteID] = useState("");

    const budgetDB = GetDB();

    const [currentPage, setCurrentPage] = useState(0);
    const [recordsPerPage] = useState(15);

    const nPages = Math.ceil(Object.entries(sortedList).length / recordsPerPage);


    useEffect (() =>{
        var unsubscribe = listenToCollection(budgetDB, "experienceGuides", SetLists);
        return unsubscribe;
    }, [])

    const SetLists = (guides) => {
        
        let x = guides.reduce((accumulator, value) => { 
            return {...accumulator,  [value.id]: value}
        }, {});
        setGuideList(x); 
        setSortedList(Object.fromEntries(Object.entries(x).sort(function(a, b){
            return b[1].lastModification - a[1].lastModification;
        })));

        Object.entries(x).sort(function(a, b){
            return b[1].lastModification - a[1].lastModification;
        }).slice(1,9);

        
    }

    const openGuide = (id) => {
        const timeStamp = firebase.firestore.Timestamp.now().toDate();
        updateDocumentProperty(budgetDB, "experienceGuides", id, "lastModification", timeStamp);
        window.location = "/budget-app/experiences/edit/" + id;
    }

    const createNewGuide = () => {

        const newTitle = "Experience Guide " + (Object.keys(guideList).length +  1); 
        const timeStamp = firebase.firestore.Timestamp.now().toDate();

        let newExperience = {
            title: newTitle,
            location: "",
            lastModification: timeStamp,
            id: generateGUID(),
            experiences: {},
            attendees: 0,
            occupancy: "Single Occupancy",
            tripLength: 0,
            currency: "CAD$",
            contingency: "10",
            overhead: "10",
            margin: "10",
            version: "Version 1.0"
        }

        const openNewGuide = () => {
            openGuide(newExperience.id);
        }

        setDocument(budgetDB, "experienceGuides", newExperience.id, newExperience, openNewGuide); 
        
    }
    const deleteGuide = async (id) => {
        toggleDeleteModal();
        console.log("deleting " + deleteID );
        deleteDocument(budgetDB, "experienceGuides", deleteID);          
    }

    const confirmDelete = (id) => {
        toggleDeleteModal();
        setDeleteID(id);
    }

    const [modal, setModal] = useState(false);

    const toggleDeleteModal = () => setModal(!modal);

    const navigateToPage = (e, i) => {
        setCurrentPage(i);
    }

    return (

        <React.Fragment>
            <Modal isOpen={modal} toggle={toggleDeleteModal}>
                <ModalHeader toggle={toggleDeleteModal}>Are you sure you would like to delete this guide?</ModalHeader>
                <ModalBody>
                Once you've deleted this guide, you will not be able to access it again. All guide data will be lost.
                </ModalBody>
                <ModalFooter>
                <PrimaryButton onClick={x => deleteGuide(deleteID)} >
                    Delete Guide
                </PrimaryButton>{' '}
                <Button onClick={toggleDeleteModal}>
                    Cancel
                </Button>
                </ModalFooter>
            </Modal>
            <Row>
                <Col>
                    <WhiteForm style={{marginRight: "15px", marginLeft: "15px"}}>
                        <OutputRow>
                            <Col>
                                <BoldLabel>
                                    Title
                                </BoldLabel>
                            </Col>
                            <Col>
                                <BoldLabel>
                                    Date modified
                                </BoldLabel>
                            </Col>
                            <Col style={{textAlign: "right"}}>
                                <PrimaryButton style={{marginBottom: "16px", marginTop: "-6px"}} onClick={createNewGuide}>Create New Guide</PrimaryButton>
                            </Col>
                        </OutputRow>
                        {sortedList !== undefined && Object.entries(sortedList).slice(currentPage * recordsPerPage,
                        (currentPage + 1) * recordsPerPage).map((guide, i) => {
                            
                            return (
                                <React.Fragment>
                                    <OutputRow key={i}>
                                        <Col>
                                            <p>{guide[1].title}</p>
                                        </Col>
                                        <Col>
                                            <p>{(guide[1].lastModification !== undefined && guide[1].lastModification !== "") ? 
                                                            guide[1].lastModification.toDate().toLocaleString("en-US") : 
                                                            ""}</p>
                                        </Col>
                                        <Col style={{textAlign: "right", paddingRight: "30px"}}>
                                            <PinkButtonContainer onClick={(x) => openGuide(guide[1].id)}>
                                                <EditIcon icon={['fas', 'pen']} style={{height: "25px", paddingLeft: "2px"}}/>
                                            </PinkButtonContainer>
                                            <PinkButtonContainer onClick={(x) => confirmDelete(guide[1].id)}>
                                                <EditIcon icon={['fas', 'trash']} style={{height: "25px"}}/>
                                            </PinkButtonContainer>
                                        </Col>
                                    </OutputRow>
                                </React.Fragment>
                            ) })
                        }
                        <Row style={{marginTop: "30px"}}>
                            <Col style={{textAlign: "center"}}>
                                <SPagination style={{justifyContent: "center"}}>
                                    <SPaginationItem disabled={currentPage <= 0}>
                                        <SPaginationLink
                                        onClick={e => navigateToPage(e, 0)}
                                        first
                                        href="#"
                                        />
                                    </SPaginationItem>
                                    <SPaginationItem disabled={currentPage <= 0}>
                                        <SPaginationLink
                                        
                                        onClick={e => navigateToPage(e, currentPage-1)}
                                        href="#"
                                        previous
                                        />
                                    </SPaginationItem>
                                    {[...Array(nPages)].map((page, i) => 
                                    <SPaginationItem active={i === currentPage} key={i}>
                                        <SPaginationLink  active={i === currentPage} onClick={e => navigateToPage(e, i)} href="#">
                                        {i + 1}
                                        </SPaginationLink>
                                    </SPaginationItem>
                                    )}

                                    <SPaginationItem disabled={currentPage+1 >= nPages}>
                                        <SPaginationLink
                                        onClick={e => navigateToPage(e, currentPage+1)}
                                        href="#"
                                        next
                                        />
                                    </SPaginationItem>
                                    <SPaginationItem disabled={currentPage+1 >= nPages}>
                                        <SPaginationLink
                                        onClick={e => navigateToPage(e, nPages-1)}
                                        href="#"
                                        last
                                        />
                                    </SPaginationItem>
                                </SPagination>
                            </Col>
                        </Row>
                    </WhiteForm>  
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ExperienceGuideList;

const SPagination = styled(Pagination)`
    
`

const SPaginationItem = styled(PaginationItem)`

    ${props => props.active && `
            color: white;
            background-color: black;
    `}

`
const SPaginationLink = styled(PaginationLink)`
    color: ${Colors.primaryAccent};
    &:hover {
        color: ${Colors.primaryDark};
    }
    ${props => props.active && `
            color: white;
            background-color: ${Colors.primaryAccent} !important;
            border-color: ${Colors.primaryAccent} !important;
    `}
`;
