import React from "react";
import { Row, Col } from "reactstrap";
import { BoldLabel } from "../../../../utils/budget-tool-specific";
import { displayValue } from "../../../budget-app/helper";
import styled from "styled-components";

const TransportationBreakdownRow = (props) => {

    return (

        <MainRow>
            <Col lg="1"></Col>
            <Col>
                <BoldLabel style={{marginBottom: "0px"}}>{props.title}</BoldLabel>
            </Col>
            <Col lg="2">
                {props.currency}{displayValue(props.costPer)} per person
            </Col>
            <Col lg="1"/> 
            <Col lg="2"/>
        </MainRow>
    )
} 

export default TransportationBreakdownRow;


const MainRow = styled(Row)`
    margin: 0px -30px;
    display: flex;
    align-items: center;
    
`