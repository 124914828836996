import React from "react";
import { Row, Col } from "reactstrap";
import { HeaderTitle, 
    Spacer,
    PrimaryButton,} from "../../../../utils/budget-tool-specific";
import { useParams } from "react-router-dom";
import ExperienceGuideInput from "./experience-guide-input";
import PageTemplate from "../../page-template";

const ExperiencesEditComponent = (props) => {

    const backToGuideList = () => {
        window.location = "/budget-app/experiences";
    }

    return(
        <React.Fragment>
            <Spacer height="60px" id="height-spacer"/>
            <Row>
                <Col>
                    <HeaderTitle>
                        
                    </HeaderTitle>
                </Col>
                {!props.client &&
                <Col style={{textAlign: "right"}}>
                    <PrimaryButton style={{marginTop: "30px", marginRight: "30px"}}
                                onClick={(x) => backToGuideList()}> Back to Guide List</PrimaryButton>
                </Col>
                }
            </Row>
            <ExperienceGuideInput id={props.experience} client={props.client}/> 
        </React.Fragment>
    )
}

const ExperiencesEdit = (props) => {

    let { experience } = useParams();
    if (experience === undefined) {
        window.history.replaceState(null, "", "/budget-app/experiences");
    }

    if (props.collapsed) { 
        return <ExperiencesEditComponent experience={experience} client={props.client}/>
    } else {
        return <PageTemplate component={<ExperiencesEditComponent experience={experience} client={props.client}/>} client={props.client}/> 
    }
} 

export default ExperiencesEdit;
