import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Collapse, Card, CardBody } from "reactstrap";
import { Colors, OutputRow, TableIcon, BoldLabel, ButtonContainer, CollapseButton, CollapseRow } from "../../../../utils/budget-tool-specific";
import TransportationBreakdownRow from "../../../budget/budget-tool/cost-output/transportation-breakdown-row";
import SubtotalCols from "./subtotal-cols";
import { displayValue } from "../../helper";
import CostRow from "./exp-cost-row";
import { calculateCostGroundTransport, exchangeRate } from "./cost-helper";

const TransportationRow = ({guideData, expData}) => {

    let numTrans = 0;
    if (expData.transportation.value.length !== undefined) {
        numTrans = expData.transportation.value.length;
    }

    const [breakdownOpen, setBreakdownOpen] = useState(false);
      

    return (
        <React.Fragment>
            <CostRow title="Ground Transport" 
                icon='car'
                detail
                isOpen={breakdownOpen}
                setIsOpen={setBreakdownOpen}
                currency={guideData.currency.value.slice(3)} 
                subtotal={calculateCostGroundTransport(guideData, expData)}
                attendees={guideData.attendees.value}/>
            <Collapse isOpen={breakdownOpen} style={{width: "100%"}}>
                <CollapseRow>
                    <Col>
                        <PinkCard>
                        <PaddedCardBody>
                            {expData.transportation.value !== undefined && Object.entries(expData.transportation.value).map((element, i) => {
                                
                                return (
                                    <TransportationBreakdownRow key={i}
                                                    title={element[1]} 
                                                    costPer={exchangeRate(expData, expData.costPerTransportation.value)}
                                                    currency={guideData.currency.value.slice(3)}/>
                                )
                            })}
                        </PaddedCardBody>
                        </PinkCard>
                    </Col>
                </CollapseRow>
            </Collapse>
        </React.Fragment>
    )
}

export default TransportationRow;

const MainRow = styled(Row)`
    
`


const PinkCard = styled(Card)`
    background-color: ${Colors.primaryAccent};
    border: none;
    width: 100%;
`

const PaddedCardBody = styled(CardBody)`
    padding: 15px 30px;
`