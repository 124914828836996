import React, { useEffect, useState } from 'react';
import { Collapse, Row, Col, Modal, ModalBody, Input } from 'reactstrap';
import { UnderlineButton, FormRow, WhiteForm, transportationOptions, addOnOptions, PrimaryButton } from '../../../utils/budget-tool-specific';
import FormInputSingle from './input-components/form-input-single';
import { TripLengthOptions, ExperienceTypeOptions, WorkBalanceOptions, AlcoholOptions } from './input-components/input-options';
import FormInputMulti from './input-components/form-input-multi';
import { validateBudgetForm, lengthOptions, experienceOptions, goalOptions, alcoholOptions, transportOptions, addsOptions } from '../helper';
import CostStructureWrapper from './cost-output/cost-structure-wrapper';

const BudgetForm = (props) => {

    const [inputOpen, setInputOpen] = useState(true);
    const [outputOpen, setOutputOpen] = useState(false);
    const [copyAlert, setCopyAlert] = useState(false);

    const [data, setData] = useState(
        {   attendees: {value: 0, invalid: false, required: true},
            tripLength: {value: "", invalid: false, required: true}, 
            experienceType: {value: "", invalid: false, required: true},
            flightCost: {value: 0, invalid: false, required: true},
            workBalance: {value: "", invalid: false, required: true},
            alcohol: {value: "", invalid: false, required: true},
            transportation: {value: [], invalid: false, required: false},
            services: {value: [], invalid: false, required: false},
            occupancy: {value: "Single Occupancy", invalid: false, required: false},
            inclusive: {value: "Not Inclusive", invalid: false, required: false}
        }
    );

    const calculateRetreatCost = () => {
        if (validateBudgetForm (data, setData) ) {
            setOutputOpen(false);
            setOutputOpen(true);
        } else {
            console.log("Invalid form");
        }
    };

    const occupancyChecked = (value) => {
        if (value === "Double Occupancy"){
            return true;
        }
        return false;
    }

    const toggleOccupancy = () => {
        if (data.occupancy.value === "Single Occupancy"){
            setData({
                ...data, 
                occupancy:{
                    ...data.occupancy,
                    value: "Double Occupancy"
                }
            });
        }
        else{
            setData({
                ...data, 
                occupancy:{
                    ...data.occupancy,
                    value: "Single Occupancy"
                }
            });
        }
    }

    const inclusiveChecked = (value) => {
        if (value === "All-Inclusive"){
            return true;
        }
        return false;
    }

    const toggleInclusive = () => {
        if (data.inclusive.value === "Not Inclusive"){
            setData({
                ...data, 
                inclusive:{
                    ...data.inclusive,
                    value: "All-Inclusive"
                }
            });
        }
        else{
            setData({
                ...data, 
                inclusive:{
                    ...data.inclusive,
                    value: "Not Inclusive"
                }
            });
        }
    }

    const copyURL = () => {
        let url = "https://www.moniker-virtual.com/budget-view/";
        url += data.attendees.value.toString() + "/";
        let l = lengthOptions.indexOf(data.tripLength.value);
        console.log(l);
        url += l.toString() + "/";
        let e = experienceOptions.indexOf(data.experienceType.value);
        console.log(e);
        url += e.toString() + "/";
        url += data.flightCost.value + "/"; //TODO: Might need to round this?
        let g = goalOptions.indexOf(data.workBalance.value);
        url += g.toString() + "/";
        let a = alcoholOptions.indexOf(data.alcohol.value);
        url += a.toString() + "/";
        transportOptions.forEach((el) => {
            let x = data.transportation.value.indexOf(el);
            if (x === -1) 
                url += "0"; 
            else 
                url += "1";
        })
        url += "/";
        addsOptions.forEach((el) => {
            let x = data.services.value.indexOf(el);
            if (x === -1) 
                url += "0"; 
            else 
                url += "1";
        })
        url += "/";
        data.occupancy.value === "Single Occupancy" ? url += "0" : url += "1";
        url += "/";
        data.inclusive.value === "Not Inclusive" ? url += "0" : url += "1";
        navigator.clipboard.writeText(url);
        setCopyAlert(!copyAlert);
    }

    const getValues = (selectedDataArray, arrayToCheckAgainst) => {
        let x = [];
        arrayToCheckAgainst.forEach((el) => {
            let ix = selectedDataArray.findIndex((e) => e === el.label);
            if (ix !== -1){
                x.push(el);
            }
        })
        return x;
    }

    useEffect(() => {
        if (props.disabled !== undefined && props.newData !== undefined){
            setData(props.newData);
            setOutputOpen(true);
        }
    }, [props]);

    return ( 
        <React.Fragment>
            <Modal isOpen={copyAlert} toggle={() => setCopyAlert(!copyAlert)}>
                <ModalBody toggle={() => setCopyAlert(!copyAlert)}>
                    <Row style={{display: "flex", alignItems: "center"}}>
                        <Col>
                            <p style={{margin: "0px"}}>URL copied to clipboard!</p>
                        </Col>
                        <Col style={{display: "flex", justifyContent: "flex-end"}}>
                            <PrimaryButton style={{margin: "0px"}} onClick={() => setCopyAlert(!copyAlert)} >
                                Okay
                            </PrimaryButton>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Collapse isOpen={inputOpen}>
                <FormRow>
                    <Col>
                        <WhiteForm id="left-form">
                            <FormInputSingle title="Attendees" 
                                            toggle
                                            toggleValue={data.occupancy.value}
                                            toggleAction={() => {toggleOccupancy()}}
                                            toggleTitle="DBL Occupancy"
                                            toggleChecked={occupancyChecked(data.occupancy.value)}
                                            id="attendees" data={data} 
                                            setData={(x) => setData(x)} 
                                            type="number"
                                            disabled={props.disabled}/>
                            <FormInputSingle title="Trip Length" 
                                            id="tripLength" data={data} 
                                            setData={(x) => setData(x)} 
                                            options={<TripLengthOptions/>} 
                                            type="select"
                                            disabled={props.disabled}/> 
                            <FormInputSingle title="Experience Type" 
                                            toggle
                                            toggleValue={data.inclusive.value}
                                            toggleAction={() => {toggleInclusive()}}
                                            toggleTitle="All-Inclusive"
                                            toggleChecked={inclusiveChecked(data.inclusive.value)}
                                            id="experienceType" 
                                            data={data} 
                                            setData={(x) => setData(x)} 
                                            options={<ExperienceTypeOptions/>} 
                                            type="select"
                                            disabled={props.disabled}/>
                            <FormInputSingle title="Flight Cost" 
                                            id="flightCost" 
                                            data={data} 
                                            setData={(x) => setData(x)} 
                                            currency="$" 
                                            type="number"
                                            disabled={props.disabled}/>
                        </WhiteForm>
                    </Col>
                    <Col>
                        <WhiteForm id="right-form">
                            <FormInputSingle title="Retreat Goal"
                                            id="workBalance"
                                            data={data}
                                            setData={(x) => setData(x)}
                                            options={<WorkBalanceOptions/>}
                                            type="select"
                                            disabled={props.disabled}/>
                            {data.inclusive.value === "All-Inclusive" ? 
                                <FormInputSingle title="Will you provide alcohol?"
                                            id="alcohol"
                                            data={{"alcohol": {value: "", invalid: false, required: true}}}
                                            setData={(x) => setData(x)}
                                            options={<AlcoholOptions/>}
                                            type="select"
                                            disabled={true}/> :
                                <FormInputSingle title="Will you provide alcohol?"
                                            id="alcohol"
                                            data={data}
                                            setData={(x) => setData(x)}
                                            options={<AlcoholOptions/>}
                                            type="select"
                                            disabled={props.disabled}/>}
                            {props.disabled ? <FormInputMulti title="Select the ground transportation your team will cover" 
                                            value={getValues(data.transportation.value, transportationOptions)}
                                            id="transportation"
                                            data={data}
                                            setData={(x) => setData(x)}
                                            options={transportationOptions}
                                            disabled={props.disabled}/> :
                            <FormInputMulti title="Select the ground transportation your team will cover" 
                                            id="transportation"
                                            data={data}
                                            setData={(x) => setData(x)}
                                            options={transportationOptions}
                                            disabled={props.disabled}/>
                            }
                            {props.disabled ? <FormInputMulti title="Select add-on services" 
                                            value={getValues(data.services.value, addOnOptions)}
                                            id="services"
                                            data={data}
                                            setData={(x) => setData(x)}
                                            options={addOnOptions}
                                            disabled={props.disabled}/> :
                                            <FormInputMulti title="Select add-on services" 
                                            id="services"
                                            data={data}
                                            setData={(x) => setData(x)}
                                            options={addOnOptions}
                                            disabled={props.disabled}/>
                            }
                        </WhiteForm>
                    </Col>
                </FormRow>
                <PrimaryButton onClick={() => calculateRetreatCost()}>Calculate Retreat Cost</PrimaryButton>
                {props.disabled === undefined && <PrimaryButton onClick={() => copyURL()}>Copy Link for Client</PrimaryButton>}
                <UnderlineButton onClick={() => setInputOpen(false)}>Hide retreat cost inputs</UnderlineButton>
                {false && <PrimaryButton onClick={() => toggleOccupancy()}>{data.occupancy.value}</PrimaryButton>}
            </Collapse>
            {!inputOpen && <UnderlineButton onClick={() => setInputOpen(true)}>Show retreat cost inputs</UnderlineButton>}

            {outputOpen && 
            <CostStructureWrapper data={data}/>}
        </React.Fragment>
    )

}
 
export default BudgetForm;