import React from 'react';
import { Row, Col } from "reactstrap";
import { OutputRow, TableIcon, BoldLabel, ButtonContainer, CollapseButton } from "../../../../utils/budget-tool-specific";

const NoCostRow = (props) => {

    const normalStyle = {
        transition: '1s, transform 0.5s',
    };
      
      const transformedStyle = {
        transition: '1s, transform 0.5s',
        transform: 'rotate(180deg)',
    };

    return (
        <OutputRow>
            <Col lg="1" style={{textAlign: "center", alignItems: "center", verticalAlign: "middle"}}>
                {!props.single && <ButtonContainer onClick={() => {props.setIsOpen(!props.isOpen)}}>
                    <CollapseButton icon={['fas', 'chevron-down']} style={props.isOpen ? transformedStyle : normalStyle}/> 
                </ButtonContainer>}
            </Col>
            <Col>
                <Row>
                    <Col sm={{size: 'auto'}}>
                        <TableIcon icon={['fas', props.icon]} style={{height: props.height ? "30px" : "auto"}}/>
                    </Col>
                    <Col>
                    <BoldLabel>
                        {props.title}
                    </BoldLabel>
                    </Col>
                </Row>
            </Col>
            <Col lg="2">
            </Col>         
            </OutputRow>
            
    )
}

export default NoCostRow;