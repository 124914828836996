import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Collapse, Card, CardBody } from "reactstrap";
import { Colors, OutputRow, TableIcon, BoldLabel, ButtonContainer, CollapseButton, CollapseRow } from "../../../../utils/budget-tool-specific";
import TransportationBreakdownRow from "../../../budget/budget-tool/cost-output/transportation-breakdown-row";
import SubtotalCols from "./subtotal-cols";
import { displayValue } from "../../helper";
import { calculateCostMiscellaneous, exchangeRate } from "./cost-helper";
import CostRow from "./exp-cost-row";

const MiscellaneousRow = ({guideData, expData}) => {

    const [breakdownOpen, setBreakdownOpen] = useState(false);

    const toggleCollapse = () => {

        if (breakdownOpen === true) {
            setBreakdownOpen(false);
        } else {
            setBreakdownOpen(true);
        }
    }

    const normalStyle = {
        transition: '1s, transform 0.5s',
    };
      
      const transformedStyle = {
        transition: '1s, transform 0.5s',
        transform: 'rotate(180deg)',
    };

    return (
        <React.Fragment>
           <CostRow title="Miscellaneous" 
                icon='plus'
                height
                detail
                isOpen={breakdownOpen}
                setIsOpen={setBreakdownOpen}
                currency={guideData.currency.value.slice(3)} 
                subtotal={calculateCostMiscellaneous(guideData,expData)}
                attendees={guideData.attendees.value}/>
            <Collapse isOpen={breakdownOpen} >
                <CollapseRow>
                        <Col>
                            <PinkCard>
                            <PaddedCardBody>
                                {expData.miscellaneousCosts.value !== undefined && Object.entries(expData.miscellaneousCosts.value).map((element, i) => {
                                    
                                    if (element[1].title !== "") { 
                                        return (
                                        <TransportationBreakdownRow key={i}
                                                        title={element[1].title} 
                                                        costPer={element[1].pp === "per person" ? exchangeRate(expData, element[1].cost) : exchangeRate(expData, (element[1].cost / guideData.attendees.value))}
                                                        currency={guideData.currency.value.slice(3)}/>
                                    )}
                                })}
                            </PaddedCardBody>
                            </PinkCard>
                        </Col>
                </CollapseRow>
            </Collapse>
        </React.Fragment>
    )
}

export default MiscellaneousRow;

const PinkCard = styled(Card)`
    background-color: ${Colors.primaryAccent};
    border: none;
`

const PaddedCardBody = styled(CardBody)`
    padding: 15px 30px;
`