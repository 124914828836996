import React from "react";
import { Row, Col } from "reactstrap";
import { displayValue2 } from "../../helper";
import styled from "styled-components";

const SubBreakdownRow = (props) => {

    return (

        <MainRow>
            <Col lg="1">
            </Col>
            <Col>
                <p style={{marginBottom: "0px"}}>{props.title}</p>
            </Col>
            <Col lg="1"/>
            {props.meals && <Col>
                {props.currency}{displayValue2(props.subtotal)} x {props.num}
            </Col>}
            <Col lg="3">
                {props.currency}{displayValue2(props.costPer)} per person {props.perday && <p style={{display: "inline"}}>/ day</p>}
            </Col>
        </MainRow>
    )
} 

export default SubBreakdownRow;

const MainRow = styled(Row)`
    margin: 5px -30px;
    display: flex;
    align-items: center;
    
`