import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { PrimaryButton } from '../../../utils/budget-tool-specific';

const InfoModal = (props) => {

    useEffect(() =>
    {
        console.log(props.isOpen);
    }, [props.isOpen])

    return (<>

    {props.message && <Modal isOpen={props.isOpen} toggle={() => {props.setInfoModalOpen(false)}}>
        <ModalHeader toggle={() => {props.setInfoModalOpen(false)}}>
            {props.message.header}
        </ModalHeader>
        <ModalBody>
            {props.message.body}
        </ModalBody>
        <ModalFooter>
            <PrimaryButton onClick={() => {props.setInfoModalOpen(false)}}>
                Okay
            </PrimaryButton>
        </ModalFooter>
    </Modal>}
    </>)
}

export default InfoModal;