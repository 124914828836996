import React from 'react';
import { Input } from 'reactstrap';
import styled from 'styled-components';

const SettingsInput = (props) => {
    
    const handleInputChange = (value) => {
        let newData = {...props.data};
        SetValueFromIndices(newData, parseInt(value, 10))
        props.setArea(props.area, newData);
    }

    const SetValueFromIndices = (newData, value) => {
        switch (props.indices.length) {
            case 1:
                newData[props.indices[0]] = value;
                break;
            case 2:
                newData[props.indices[0]][props.indices[1]] = value;
                break;
            case 3:
                newData[props.indices[0]][props.indices[1]][props.indices[2]] = value;
                break;
            case 4:
                newData[props.indices[0]][props.indices[1]][props.indices[2]][props.indices[3]] = value;
                break;
            default:
                break;
        }
    }

    const GetValueFromIndices = () => {
        switch (props.indices.length) {
            case 1:
                return props.data[props.indices[0]];
            case 2:
                return props.data[props.indices[0]][props.indices[1]];
            case 3:
                return props.data[props.indices[0]][props.indices[1]][props.indices[2]];
            case 4:
                return props.data[props.indices[0]][props.indices[1]][props.indices[2]][props.indices[3]];
            default:
                break;
        }
    }

    return ( 
        <div>
        <StyledInput 
            type="number"
            value={GetValueFromIndices()}
            onChange={event => handleInputChange(event.target.value)}/>
        </div>
    )
}
 
export default SettingsInput;

const StyledInput = styled(Input)`

`;