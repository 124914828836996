import React from "react";
import { Row, Col } from "reactstrap";
import { BoldLabel, ButtonContainer, CollapseButton } from "../../../../utils/budget-tool-specific";
import { displayValue2 } from "../../helper";
import styled from "styled-components";

const BreakdownRow = (props) => {

    const normalStyle = {
        transition: '1s, transform 0.5s',
    };
      
      const transformedStyle = {
        transition: '1s, transform 0.5s',
        transform: 'rotate(180deg)',
    };

    return (

        <MainRow>
            <Col lg="1">
                {props.nested && <ButtonContainer style={{marginLeft: "30px"}} onClick={() => {props.setIsOpen(!props.isOpen)}}>
                    <CollapseButton icon={['fas', 'chevron-down']} style={props.isOpen ? transformedStyle : normalStyle}/> 
                </ButtonContainer>}
            </Col>
            <Col>
                <BoldLabel style={{marginBottom: "0px"}}>{props.title}</BoldLabel>
            </Col>
            <Col lg="1"/>
            {props.meals && !props.inclusive && <Col>
                {props.currency}{displayValue2(props.subtotal)} x {props.num}
            </Col>}
            {props.coordinators && <Col>
                {props.currency}{displayValue2(props.subtotal)} per day x {props.num} coordinator(s) x {props.days} days
            </Col>}
            {props.percent && <Col>
                x {props.num}% {props.noflights && <p style={{display: "inline"}}>(not charged on flights)</p>}
            </Col>}
            {(props.fixedCost && (props.title !== "Onsite Coordinator"))  && <Col>
                {props.currency}{displayValue2(props.fixedCost)} {props.perday && <p style={{display: "inline"}}>per day</p>}
            </Col>}
            {props.inclusive ? <Col lg="3">
                N/A - All Inclusive Plan
            </Col> :
            <Col lg="3">
                {props.currency}{displayValue2(props.costPer)} {!props.notperperson && "per person"} {props.perday && <p style={{display: "inline"}} >/ day</p>} {props.total && <p style={{display: "inline"}} > total</p>}
            </Col>}
        </MainRow>
    )
} 

export default BreakdownRow;

const MainRow = styled(Row)`
    margin: 5px -15px;
    height: 40px;
    display: flex;
    align-items: center;
    
`