import React from "react";
import { HeightDiv,
    ContentContainer,
    NoPaddingRow,
    SpacerCol,
    RightCol,} from "../../utils/budget-tool-specific";

const PageTemplate = (props) => {

    return (
        <React.Fragment>
            <HeightDiv id="height-div">
                <ContentContainer>
                    <NoPaddingRow>
                        <SpacerCol style={{zIndex: -1}}id="spacer-col" client={props.client}/>
                        <RightCol id="right-content-col">
                            {props.component}
                        </RightCol>
                    </NoPaddingRow>
                </ContentContainer>
            </HeightDiv>
        </React.Fragment>
    )
} 

export default PageTemplate;