import React, {useState} from 'react';
import { Row, Col, Collapse } from 'reactstrap';
import SettingsInput from '../budget-tool/input-components/settings-input';
import { CollapseButton, ButtonContainer } from '../../../utils/budget-tool-specific';

const SettingsMisc = (props) => {

    const [opened, setOpened] = useState(false);
    const [avOpened, setAVOpened] = useState(false);
    const [enhancedDecorOpened, setEnhancedDecorOpened] = useState(false);
    const [photographerOpened, setPhotographerOpened] = useState(false);
    const [swagOpened, setSwagOpened] = useState(false);
    const [inclusiveOpened, setInclusiveOpened] = useState(false);
    
    const normalStyle = {
        transition: '1s, transform 0.5s',
    };
      
    const transformedStyle = {
        transition: '1s, transform 0.5s',
        transform: 'rotate(180deg)',
    };

    return ( 
        <React.Fragment>
            <div style={{display: "flex", textAlign: "center", alignItems: "center", verticalAlign: "middle"}}>
                <ButtonContainer onClick={() => {setOpened(!opened)}}>
                    <CollapseButton icon={['fas', 'chevron-down']} style={opened ? transformedStyle : normalStyle}/> 
                </ButtonContainer>
                <h4>Misc. Settings</h4>
            </div>
            <Collapse isOpen={opened}>
            {props.data !== undefined && Object.entries(props.data).map((el, index) => {
                return(
                    <div key={index}>
                        {Object.entries(el[1]).sort().map((e,i) => {
                            return(
                                <div key={i}>
                                    {(e[0] === "Covid test") &&
                                        <Row key={e[0]}>
                                        <Col>
                                            <p>{e[0]}</p>
                                        </Col>
                                        <Col>
                                        <SettingsInput 
                                            type="number"
                                            setArea={props.setArea}
                                            data={props.data}
                                            area={"miscRow"}
                                            indices={[el[0].toString(), e[0].toString()]}
                                            />
                                        </Col>
                                    </Row>}
                                    {(e[0] === "Travel insurance") &&
                                        <Row key={e[0]}>
                                        <Col>
                                            <p>{e[0]}</p>
                                        </Col>
                                        <Col>
                                        <SettingsInput 
                                            type="number"
                                            setArea={props.setArea}
                                            data={props.data}
                                            area={"miscRow"}
                                            indices={[el[0].toString(), e[0].toString()]}
                                            />
                                        </Col>
                                    </Row>}
                                    {(e[0] === "Microsite") &&
                                        <Row key={e[0]}>
                                        <Col>
                                            <p>{e[0]}</p>
                                        </Col>
                                        <Col>
                                        <SettingsInput 
                                            type="number"
                                            setArea={props.setArea}
                                            data={props.data}
                                            area={"miscRow"}
                                            indices={[el[0].toString(), e[0].toString()]}
                                            />
                                        </Col>
                                    </Row>}
                                    {(e[0] === "Management fee") &&
                                        <Row key={e[0]}>
                                        <Col>
                                            <p>{e[0]}</p>
                                        </Col>
                                        <Col>
                                        <SettingsInput 
                                            type="number"
                                            setArea={props.setArea}
                                            data={props.data}
                                            area={"miscRow"}
                                            indices={[el[0].toString(), e[0].toString()]}
                                            />
                                        </Col>
                                    </Row>}
                                    {(e[0] === "Facilitator") &&
                                        <Row key={e[0]}>
                                        <Col>
                                            <p>{e[0]}</p>
                                        </Col>
                                        <Col>
                                        <SettingsInput 
                                            type="number"
                                            setArea={props.setArea}
                                            data={props.data}
                                            area={"miscRow"}
                                            indices={[el[0].toString(), e[0].toString()]}
                                            />
                                        </Col>
                                    </Row>}
                                    {(e[0] === "Onsite Coordinator") &&
                                        <Row key={e[0]}>
                                            <Col>
                                                <p>{e[0]}</p>
                                            </Col>
                                            <Col>
                                            <SettingsInput 
                                                type="number"
                                                setArea={props.setArea}
                                                data={props.data}
                                                area={"miscRow"}
                                                indices={[el[0].toString(), e[0].toString()]}
                                                />
                                            </Col>
                                        </Row>}
                                    {(e[0] === "Swag") &&
                                        <div>
                                            <div style={{display: "flex", textAlign: "center", alignItems: "center", verticalAlign: "middle"}}>
                                                <ButtonContainer onClick={() => {setSwagOpened(!swagOpened)}}>
                                                    <CollapseButton icon={['fas', 'chevron-down']} style={swagOpened ? transformedStyle : normalStyle}/> 
                                                </ButtonContainer>
                                                <h6>{e[0]}</h6>
                                            </div>
                                            <Collapse isOpen={swagOpened}>
                                                {Object.entries(e[1]).map((a,b) => {
                                                    return(
                                                        Object.entries(a[1]).sort().map((c,d) => {
                                                            return (
                                                                <Row key={d}>
                                                                <Col>
                                                                    <p>{c[0]}</p>
                                                                </Col>
                                                                <Col>
                                                                <SettingsInput 
                                                                    type="number"
                                                                    setArea={props.setArea}
                                                                    data={props.data}
                                                                    area={"miscRow"}
                                                                    indices={[el[0].toString(), e[0].toString(), a[0].toString(), c[0].toString()]}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            )
                                                        })
                                                        )
                                                    })}
                                            </Collapse>
                                        </div>}
                                    {(e[0] === "AV") &&
                                        <div>
                                            <div style={{display: "flex", textAlign: "center", alignItems: "center", verticalAlign: "middle"}}>
                                                <ButtonContainer onClick={() => {setAVOpened(!avOpened)}}>
                                                    <CollapseButton icon={['fas', 'chevron-down']} style={avOpened ? transformedStyle : normalStyle}/> 
                                                </ButtonContainer>
                                                <h6>{e[0]}</h6>
                                            </div>
                                            <Collapse isOpen={avOpened}>
                                                {Object.entries(e[1]).map((a,b) => {
                                                    return (
                                                        <Row key={b}>
                                                        <Col>
                                                            <p>{a[0]}</p>
                                                        </Col>
                                                        <Col>
                                                        <SettingsInput 
                                                            type="number"
                                                            setArea={props.setArea}
                                                            data={props.data}
                                                            area={"miscRow"}
                                                            indices={[el[0].toString(), e[0].toString(), a[0].toString()]}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    )
                                                })}
                                            </Collapse>
                                        </div>}
                                    {(e[0] === "Enhanced Decor & Entertainment") &&
                                        <div>
                                            <div style={{display: "flex", textAlign: "center", alignItems: "center", verticalAlign: "middle"}}>
                                                <ButtonContainer onClick={() => {setEnhancedDecorOpened(!enhancedDecorOpened)}}>
                                                    <CollapseButton icon={['fas', 'chevron-down']} style={enhancedDecorOpened ? transformedStyle : normalStyle}/> 
                                                </ButtonContainer>
                                                <h6>{e[0]}</h6>
                                            </div>
                                            <Collapse isOpen={enhancedDecorOpened}>
                                                {Object.entries(e[1]).map((a,b) => {
                                                    return (
                                                        <Row key={b}>
                                                        <Col>
                                                            <p>{a[0]}</p>
                                                        </Col>
                                                        <Col>
                                                        <SettingsInput 
                                                            type="number"
                                                            setArea={props.setArea}
                                                            data={props.data}
                                                            area={"miscRow"}
                                                            indices={[el[0].toString(), e[0].toString(), a[0].toString()]}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    )
                                                })}
                                            </Collapse>
                                        </div>}
                                    {(e[0] === "Photographer") &&
                                        <div>
                                            <div style={{display: "flex", textAlign: "center", alignItems: "center", verticalAlign: "middle"}}>
                                                <ButtonContainer onClick={() => {setPhotographerOpened(!photographerOpened)}}>
                                                    <CollapseButton icon={['fas', 'chevron-down']} style={photographerOpened ? transformedStyle : normalStyle}/> 
                                                </ButtonContainer>
                                                <h6>{e[0]}</h6>
                                            </div>
                                            <Collapse isOpen={photographerOpened}>
                                                {Object.entries(e[1]).map((a,b) => {
                                                    return(
                                                        Object.entries(a[1]).sort().map((c,d) => {
                                                            return (
                                                                <Row key={d}>
                                                                    <Col>
                                                                        <p>{c[0]}</p>
                                                                    </Col>
                                                                    <Col>
                                                                    <SettingsInput 
                                                                        type="number"
                                                                        setArea={props.setArea}
                                                                        data={props.data}
                                                                        area={"miscRow"}
                                                                        indices={[el[0].toString(), e[0].toString(), a[0].toString(), c[0].toString()]}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })
                                                    )
                                                })}
                                            </Collapse>
                                    </div>}
                                    {(e[0] === "Inclusive") &&
                                        <div>
                                            <div style={{display: "flex", textAlign: "center", alignItems: "center", verticalAlign: "middle"}}>
                                                <ButtonContainer onClick={() => {setInclusiveOpened(!inclusiveOpened)}}>
                                                    <CollapseButton icon={['fas', 'chevron-down']} style={inclusiveOpened ? transformedStyle : normalStyle}/> 
                                                </ButtonContainer>
                                                <h6>{e[0]}</h6>
                                            </div>
                                            <Collapse isOpen={inclusiveOpened}>
                                                {Object.entries(e[1]).map((a,b) => {
                                                    return(
                                                        Object.entries(a[1]).sort().map((c,d) => {
                                                            return (
                                                                <Row key={d}>
                                                                <Col>
                                                                    <p>{c[0]}</p>
                                                                </Col>
                                                                <Col>
                                                                <SettingsInput 
                                                                    type="number"
                                                                    setArea={props.setArea}
                                                                    data={props.data}
                                                                    area={"miscRow"}
                                                                    indices={[el[0].toString(), e[0].toString(), a[0].toString(), c[0].toString()]}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            )
                                                        })
                                                        )
                                                    })}
                                            </Collapse>
                                        </div>}
                                </div>
                            )
                        })}
                    </div>
                )
            })}
            </Collapse>
        </React.Fragment>
    )
}
 
export default SettingsMisc;