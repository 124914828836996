import React from 'react';
import PageTemplate from '../page-template';
import { HeaderTitle, Spacer } from '../../../utils/budget-tool-specific';
import FlightsList from './flights-list';



const FlightComponent = (props) => {

    return (
        <React.Fragment>
            <Spacer height="60px" id="height-spacer"/>
            <HeaderTitle>
                Flights
            </HeaderTitle>
            <FlightsList/>
        </React.Fragment>
    )
}
const Flights = (props) => {

    if (props.collapsed) {
        return <FlightComponent/>;
    } else {
        return <PageTemplate component={<FlightComponent/>} client={props.client}/>
    }
}

export default Flights;