import React, { useState } from 'react';
import { FormGroup, InputGroup, Input, InputGroupText } from 'reactstrap';
import { GreyLabel, UnderlineInput, SmallFormFeedback, StyledInputGroupText } from '../../../../utils/budget-tool-specific';
import { Colors } from '../../../../utils/budget-tool-specific';

const FormInputSingle = (props) => {

    const handleInputChange = (value) => {
        const newData = {...props.data};
        newData[props.id].value = value;
        props.setData(newData);
    }

    const toggle = () => {
        props.toggleAction(!props.toggleValue);
    }

    return (
        <React.Fragment>
            <FormGroup switch>
                <GreyLabel for={props.id}>{props.title}</GreyLabel>
                <InputGroup>
                    {props.currency && <StyledInputGroupText invalid={props.data[props.id].invalid}>{props.currency}</StyledInputGroupText> }
                    <UnderlineInput 
                                    disabled={props.disabled !== undefined}
                                    invalid={props.data[props.id].invalid} 
                                    type={props.type} 
                                    value={props.data[props.id].value} 
                                    onChange={e => handleInputChange(e.target.value)} > 
                        {props.options}
                    </UnderlineInput>
                    {props.toggle && <InputGroupText style={{marginRight:"1px", backgroundColor:"white", border:"none", fontSize:"12px", color:"black"}}>
                        {props.toggleTitle}
                    </InputGroupText>}
                    {props.toggle && <Input
                        addon
                        aria-label="Checkbox for following text input"
                        type="checkbox"
                        checked={props.toggleChecked}
                        value = {props.toggleValue}
                        onChange={() => toggle()}
                    />}
                    <SmallFormFeedback>Required</SmallFormFeedback>
                </InputGroup>
            </FormGroup>

        </React.Fragment>
    )
} 

export default FormInputSingle;