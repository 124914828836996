import React from 'react';
import { Row, Col } from 'reactstrap';
import { BoldLabel, Colors } from '../../../../utils/budget-tool-specific';

const ClientOutput = ({data}) => {

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <h3>{data.title.value}</h3>
                </Col> 
            </Row>
            <Row>
                <Col lg="auto">
                    <div style={{padding: "6px 12px 6px 12px", backgroundColor: Colors.primaryLight, margin: "12px 0px"}}>
                        <BoldLabel style={{margin: "0px"}}>Attendees: {data.attendees.value}</BoldLabel>
                    </div>
                </Col> 
                <Col lg="auto">
                    <div style={{padding: "6px 12px 6px 12px", backgroundColor: Colors.primaryLight, margin: "12px 0px"}}>
                        <BoldLabel style={{margin: "0px"}}>Number of days: {data.tripLength.value*1 +1}</BoldLabel>
                    </div>
                </Col>
                <Col lg="auto">
                    <div style={{padding: "6px 12px 6px 12px", backgroundColor: Colors.primaryLight, margin: "12px 0px"}}>
                        <BoldLabel style={{margin: "0px"}}>Number of nights: {data.tripLength.value}</BoldLabel>
                    </div>
                </Col>
                <Col lg="auto">
                    <div style={{padding: "6px 12px 6px 12px", backgroundColor: Colors.primaryLight, margin: "12px 0px"}}>
                        <BoldLabel style={{margin: "0px"}}>Currency: {data.currency.value}</BoldLabel>
                    </div>
                </Col>
                
            </Row>
            <Row>
                <Col>
                    <div style={{padding: "6px 12px 6px 12px", backgroundColor: Colors.primaryLight, margin: "12px 0px"}}>
                        <BoldLabel style={{margin: "0px"}}>Contingency: {data.contingency.value}%</BoldLabel>
                    </div>
                </Col>
                <Col/>
                <Col/>
                <Col/>
            </Row>
        </React.Fragment>
    )

}

export default ClientOutput;