import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { PrimaryButton } from '../../../../utils/budget-tool-specific';

const FormModals = ({savedModal, setSavedModal, failedModal, setFailedModal, deleteModal, setDeleteModal, deleteExperience, deleteID}) => {

    return ( 
        <React.Fragment>
            <Modal isOpen={savedModal} toggle={() => {setSavedModal(!savedModal)}}>
                <ModalHeader toggle={() => {setSavedModal(!savedModal)}}>Saved</ModalHeader>
                <ModalBody>
                Successfully saved to the experience guide database.
                </ModalBody>
                <ModalFooter>
                <PrimaryButton onClick={() => {setSavedModal(!savedModal)}}>
                    Okay
                </PrimaryButton>
                </ModalFooter>
            </Modal>
            <Modal isOpen={failedModal} toggle={() => {setFailedModal(!failedModal)}}>
                <ModalHeader toggle={() => {setFailedModal(!failedModal)}}>Error</ModalHeader>
                <ModalBody>
                We encountered an error while trying to save your data. Please make sure all input fields are filled. 
                </ModalBody>
                <ModalFooter>
                <PrimaryButton onClick={() => {setFailedModal(!failedModal)}}>
                    Okay
                </PrimaryButton>
                </ModalFooter>
            </Modal>
            <Modal isOpen={deleteModal} toggle={() => setDeleteModal(!deleteModal)}>
                <ModalHeader toggle={x => setDeleteModal(!deleteModal)}>Are you sure you would like to delete this experience?</ModalHeader>
                <ModalBody>
                Once you've deleted this experience, you will not be able to access it again. All experience data will be lost.
                </ModalBody>
                <ModalFooter>
                <PrimaryButton onClick={x => deleteExperience(deleteID)} >
                    Delete Experience
                </PrimaryButton>{' '}
                <Button onClick={x => setDeleteModal(!deleteModal)}>
                    Cancel
                </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default FormModals;
