import React from 'react';
import { FormGroup, InputGroup, Input, Row, Col, Button } from 'reactstrap';
import { GreyLabel, UnderlineInput, SmallFormFeedback, StyledInputGroupText, Colors, HighlightedInputGroupText, InfoMessages } from '../../../utils/budget-tool-specific';
import styled from 'styled-components';

const FormInputFlight= (props) => {

    const handleInputChange = (value) => {
        const newData = {...props.data};
        newData.flights.value[props.i][props.id] = value;
        props.setData(newData);
    }

    const showInfo = () => {
        props.setInfoMessage(InfoMessages[props.id]);
        props.setInfoModalOpen(true);
    }

    return (
        <React.Fragment>       
            {!props.unchanged && <FormGroup>
                <GreyLabel for={props.id}>{props.title} 
                    
                </GreyLabel>
                <InputGroup>
                    {props.currency && <HighlightedInputGroupText>{props.currency}</HighlightedInputGroupText> }
                    <HighlightedInput
                                    type={props.type} 
                                    value={props.data.flights.value[props.i][props.id]} 
                                    onChange={e => handleInputChange(e.target.value)}
                                    fontSize={props.fontSize ? props.fontSize : "16px"}
                                    readOnly={props.client}> 
                        {props.options}
                    </HighlightedInput>
                    {props.contingency && <HighlightedInputGroupText>
                                            %
                    </HighlightedInputGroupText> }
                    <SmallFormFeedback>Required</SmallFormFeedback>
                </InputGroup>
            </FormGroup>}
            {props.unchanged && 
            <FormGroup>
                <GreyLabel for={props.id}>{props.title} {props.info && <InfoButton onClick={() => showInfo()}>i</InfoButton>}</GreyLabel>
                <InputGroup>
                    {props.currency && <HighlightedInputGroupText style={{backgroundColor: "#f7e7bc", border: "none"}}>{props.currency}</HighlightedInputGroupText> }
                    <UnHighlightedInput style={{backgroundColor: "#f7e7bc"}}
                                    type={props.type} 
                                    value={props.value} 
                                    readonly="readOnly"
                                    fontSize={props.fontSize ? props.fontSize : "16px"}> 
                        {props.options}
                    </UnHighlightedInput>
                    {props.contingency && <HighlightedInputGroupText>
                                            %
                    </HighlightedInputGroupText> }
                    <SmallFormFeedback>Required</SmallFormFeedback>
                </InputGroup>
            </FormGroup>
            }
        </React.Fragment>
       
        

    )
} 

export default FormInputFlight;

const InfoButton = styled(Button)`
    padding: 0px 5px;
    background-color: transparent;
    color: grey;
    border-radius: 50%;
    font-size: 8px;
    margin-bottom: 5px;

`

export const HighlightedInput = styled(Input)`
    font-size: ${props => props.fontSize ? props.fontSize : "16px"};
    margin: ${props => props.fontSize === "16px" ? "" : "0px 0px"};
    border: none;
    border-bottom: 1px solid ${Colors.primaryLight};
    border-radius: 0px;
    background-color: ${Colors.primaryLight};
    color: ${Colors.primaryDark};
    padding: ${props => props.fontSize === "16px" ? "5px 5px" : ""};
    &:hover {
        border-bottom: 1px solid ${Colors.primaryAccent};
    }
`

export const UnHighlightedInput = styled(Input)`
    font-size: ${props => props.fontSize ? props.fontSize : "16px"};
    margin: ${props => props.fontSize === "16px" ? "" : "0px 0px"};
    border: none;
    border-bottom: none;
    border-radius: 0px;
    background-color: ${Colors.primaryLight};
    color: ${Colors.primaryDark};
    padding: ${props => props.fontSize === "16px" ? "5px 5px" : ""};
    &: focus {
        box-shadow: none;
        border: none;
    }

`