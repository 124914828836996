import styled from 'styled-components';
import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { OutputRow, TableIcon, BoldLabel, Colors } from "../../../../utils/budget-tool-specific";
import SubtotalColumns from "./subtotal-cols";
import { displayValue } from "../../helper";
import { calculateSubCostNoFlightsPerAttendee, calculateCostPerAttendee, calculateInternalCostA, calculateInternalCostB } from "./cost-helper";

const AccommodationRow = ({guideData, expData, clientCurrency, currencyConversionRatio}) => {

    return (
        <React.Fragment>
            <SeparatorDiv/> 
            <OutputRow>
            <Col style={{display: "flex", justifyContent: "flex-end"}}>
                    <p style={{marginBottom: "16px"}}><b>Subtotal ({guideData.currency.value})</b></p>
                </Col> 
                <Col lg="1"/>
                <Col lg="2">
                    <p style={{marginBottom: "16px"}}>{guideData.currency.value.slice(3)}{displayValue(calculateCostPerAttendee(guideData,expData) * guideData.attendees.value)}</p>
                </Col> 
            </OutputRow>
            <OutputRow>
                <Col style={{display: "flex", justifyContent: "flex-end"}}>
                    {(guideData.overheadPercent === undefined || guideData.overheadPercent.value === true) ? 
                        <p style={{marginBottom: "16px"}}>Moniker Professional Planning Overhead ({guideData.overhead.value}%)</p> :
                        <p style={{marginBottom: "16px"}}>{(guideData.overheadPercent === undefined || guideData.margin.value === "0") ? "Moniker's Management Fee" : "Moniker Professional Planning Overhead"}</p>
                    }
                </Col> 
                <Col lg="1"/>
                <Col lg="2">
                    <p style={{marginBottom: "16px"}}>{guideData.currency.value.slice(3)}{displayValue(calculateInternalCostA(guideData, expData, clientCurrency, currencyConversionRatio))}</p>
                </Col> 
            </OutputRow>
            {guideData.margin.value !== "0" && <OutputRow>
                <Col style={{display: "flex", justifyContent: "flex-end"}}>
                    {(guideData.marginPercent === undefined || guideData.marginPercent.value === true) ? 
                        <p style={{marginBottom: "16px"}}>Moniker Profit Margin ({guideData.margin.value}%) </p> :
                        <p style={{marginBottom: "16px"}}>Moniker Profit Margin</p> 
                    }
                </Col> 
                <Col lg="1"/>
                <Col lg="2">
                    <p style={{marginBottom: "16px"}}>{guideData.currency.value.slice(3)}{displayValue(calculateInternalCostB(guideData, expData, clientCurrency, currencyConversionRatio))}</p>
                </Col> 
            </OutputRow>}
            <OutputRow>
                <Col style={{display: "flex", justifyContent: "flex-end"}}>
                    <BoldLabel>Total Program Cost ({guideData.currency.value})</BoldLabel>
                </Col> 
                <Col lg="1"/>
                <Col lg="2">
                    <BoldLabel>{guideData.currency.value.slice(3)}{displayValue(calculateInternalCostA(guideData, expData, clientCurrency, currencyConversionRatio) + calculateInternalCostB(guideData, expData, clientCurrency, currencyConversionRatio)  + calculateCostPerAttendee(guideData,expData) * guideData.attendees.value)}</BoldLabel>
                </Col> 
            </OutputRow>
        </React.Fragment>
    )
}

export default AccommodationRow;

const SeparatorDiv = styled.div`
    background-color: ${Colors.primaryAccent};
    width: 100%;
    height: 5px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
`;